import Select from 'react-select'
import React from 'react'

const Dropdown = props => {
  const handleChange = e => {
    props.onChange && props.onChange(e.value)
  }
  return <Select onChange={handleChange} defaultValue={props.defaultValue} options={props.options} />
}

export default Dropdown
