
export const possibleGrades = [
  { label: 'A', value: 5.0 },
  { label: 'B', value: 4.0 },
  { label: 'C', value: 3.0 },
  { label: 'D', value: 2.0 },
  { label: 'E', value: 1.0 },
  { label: 'F', value: 0 },
  { label: 'S', value: 0.5 },
]
export const possibleAGrades2014_2015 = [
  { label: 'A', value: 5.0 },
  { label: 'B+', value: 4.0 },
  { label: 'B', value: 3.0 },
  { label: 'C', value: 2.0 },
  { label: 'D', value: 1.0 },
  { label: 'E', value: 0.5 },
  // { label: 'F', value: 0 },
  // { label: 'S', value: 0.5 },
]
export const possibleO_LevelGrades = [
  { label: 'A', value: 5.0 },
  { label: 'B', value: 4.0 },
  { label: 'C', value: 3.0 },
  { label: 'D', value: 2.0 },
  { label: 'E', value: 1.0 },
  { label: 'F', value: 0 },
  // { label: 'F', value: 0 },
  // { label: 'S', value: 0.5 },
]

export const gradeEquivalent = {
  A: 5.0,
  B: 4.0,
  C: 3.0,
  D: 2.0,
  E: 1.0,
  F: 0,
  S: 0.5,
}
export const combinationDetails = [
  {
    id: 1,
    Name: "PCM",
    Category: "Science",
    Subjects: [
      {
        code: 131,
        Name: "Physics",
        ShortName: "aphy",
        grade: "",
        description: "core"
      },
      {
        code: 132,
        Name: "Chemistry",
        ShortName: "achem",
        grade: "",
        description: "core"
      },
      {
        code: 142,
        Name: "Advance Mathematics",
        ShortName: "advmath",
        grade: "",
        description: "core"
      },
      {
        code: 111,
        Name: "General Studies",
        ShortName: "gs",
        grade: "",
        description: "core"
      }
    ]
  },
  {
    id: 2,
    Name: "PCB",
    Category: "Science",
    Subjects: [
      {
        code: 131,
        Name: "Physics",
        ShortName: "aphy",
        grade: "",
        description: "core"
      },
      {
        code: 132,
        Name: "Chemistry",
        ShortName: "achem",
        grade: "",
        description: "core"
      },
      {
        code: 133,
        Name: "Biology",
        ShortName: "abio",
        grade: "",
        description: "core"
      },
      {
        code: 111,
        Name: "General Studies",
        ShortName: "gs",
        grade: "",
        description: "core"
      }
    ]
  },
  {
    id: 3,
    Name: "PGM",
    Category: "Science",
    Subjects: [
      {
        code: 131,
        Name: "Physics",
        ShortName: "aphy",
        grade: "",
        description: "core"
      },
      {
        code: 113,
        Name: "Geography",
        ShortName: "ageo",
        grade: "",
        description: "core"
      },
      {
        code: 142,
        Name: "Advance Mathematics",
        ShortName: "advmath",
        grade: "",
        description: "core"
      },
      {
        code: 111,
        Name: "General Studies",
        ShortName: "gs",
        grade: "",
        description: "core"
      }
    ]
  },
  {
    id: 4,
    Name: "EGM",
    Category: "Science",
    Subjects: [
      {
        code: 151,
        Name: "Economics",
        ShortName: "aeco",
        grade: "",
        description: "core"
      },
      {
        code: 113,
        Name: "Geography",
        ShortName: "ageo",
        grade: "",
        description: "core"
      },
      {
        code: 142,
        Name: "Advance Mathematics",
        ShortName: "advmath",
        grade: "",
        description: "core"
      },
      {
        code: 111,
        Name: "General Studies",
        ShortName: "gs",
        grade: "",
        description: "core"
      }
    ]
  },
  {
    id: 5,
    Name: "CBG",
    Category: "Science",
    Subjects: [
      {
        code: 132,
        Name: "Chemistry",
        ShortName: "achem",
        grade: "",
        description: "core"
      },
      {
        code: 133,
        Name: "Biology",
        ShortName: "abio",
        grade: "",
        description: "core"
      },
      {
        code: 113,
        Name: "Geography",
        ShortName: "ageo",
        grade: "",
        description: "core"
      },
      {
        code: 111,
        Name: "General Studies",
        ShortName: "gs",
        grade: "",
        description: "core"
      }
    ]
  },
  {
    id: 6,
    Name: "CBA",
    Category: "Science",
    Subjects: [
      {
        code: 132,
        Name: "Chemistry",
        ShortName: "achem",
        grade: "",
        description: "core"
      },
      {
        code: 133,
        Name: "Biology",
        ShortName: "abio",
        grade: "",
        description: "core"
      },
      {
        code: 134,
        Name: "Agriculture",
        ShortName: "aagri",
        grade: "",
        description: "core"
      },
      {
        code: 111,
        Name: "General Studies",
        ShortName: "gs",
        grade: "",
        description: "core"
      }
    ]
  },
  {
    id: 7,
    Name: "CBN",
    Category: "Science",
    Subjects: [
      {
        code: 132,
        Name: "Chemistry",
        ShortName: "achem",
        grade: "",
        description: "core"
      },
      {
        code: 133,
        Name: "Biology",
        ShortName: "abio",
        grade: "",
        description: "core"
      },
      {
        code: 155,
        Name: "Nutrition",
        ShortName: "anutri",
        grade: "",
        description: "core"
      },
      {
        code: 111,
        Name: "General Studies",
        ShortName: "gs",
        grade: "",
        description: "core"
      }
    ]
  },
  {
    id: 8,
    Name: "HGL",
    Category: "Arts",
    Subjects: [
      {
        code: 112,
        Name: "History",
        ShortName: "ahist",
        grade: "",
        description: "core"
      },
      {
        code: 113,
        Name: "Geography",
        ShortName: "ageo",
        grade: "",
        description: "core"
      },
      {
        code: 122,
        Name: "English Language",
        ShortName: "aengl",
        grade: "",
        description: "core"
      },
      {
        code: 111,
        Name: "General Studies",
        ShortName: "gs",
        grade: "",
        description: "core"
      }
    ]
  },
  {
    id: 9,
    Name: "HGK",
    Category: "Arts",
    Subjects: [
      {
        code: 112,
        Name: "History",
        ShortName: "ahist",
        grade: "",
        description: "core"
      },
      {
        code: 113,
        Name: "Geography",
        ShortName: "ageo",
        grade: "",
        description: "core"
      },
      {
        code: 121,
        Name: "Kiswahili",
        ShortName: "akisw",
        grade: "",
        description: "core"
      },
      {
        code: 111,
        Name: "General Studies",
        ShortName: "gs",
        grade: "",
        description: "core"
      }
    ]
  },
  {
    id: 10,
    Name: "HKL",
    Category: "Arts",
    Subjects: [
      {
        code: 112,
        Name: "History",
        ShortName: "ahist",
        grade: "",
        description: "core"
      },
      {
        code: 121,
        Name: "Kiswahili",
        ShortName: "akisw",
        grade: "",
        description: "core"
      },
      {
        code: 122,
        Name: "English Language",
        ShortName: "aengl",
        grade: "",
        description: "core"
      },
      {
        code: 111,
        Name: "General Studies",
        ShortName: "gs",
        grade: "",
        description: "core"
      }
    ]
  },
  {
    id: 11,
    Name: "KLF",
    Category: "Arts",
    Subjects: [
      {
        code: 121,
        Name: "Kiswahili",
        ShortName: "akisw",
        grade: "",
        description: "core"
      },
      {
        code: 122,
        Name: "English Language",
        ShortName: "aengl",
        grade: "",
        description: "core"
      },
      {
        code: 123,
        Name: "French",
        ShortName: "afre",
        grade: "",
        description: "core"
      },
      {
        code: 111,
        Name: "General Studies",
        ShortName: "gs",
        grade: "",
        description: "core"
      }
    ]
  },
  {
    id: 12,
    Name: "ECA",
    Category: "Arts",
    Subjects: [
      {
        code: 151,
        Name: "Economics",
        ShortName: "aeco",
        grade: "",
        description: "core"
      },
      {
        code: 152,
        Name: "Commerce",
        ShortName: "acomm",
        grade: "",
        description: "core"
      },
      {
        code: 153,
        Name: "Accountancy",
        ShortName: "aacc",
        grade: "",
        description: "core"
      },
      {
        code: 111,
        Name: "General Studies",
        ShortName: "gs",
        grade: "",
        description: "core"
      }
    ]
  },
  {
    id: 13,
    Name: "HGE",
    Category: "Arts",
    Subjects: [
      {
        code: 112,
        Name: "History",
        ShortName: "ahist",
        grade: "",
        description: "core"
      },
      {
        code: 113,
        Name: "Geography",
        ShortName: "ageo",
        grade: "",
        description: "core"
      },
      {
        code: 151,
        Name: "Economics",
        ShortName: "aeco",
        grade: "",
        description: "core"
      },
      {
        code: 111,
        Name: "General Studies",
        ShortName: "gs",
        grade: "",
        description: "core"
      }
    ]
  }
]

export const subjects = [
  { code: 125, Name: 'Arabic Language', ShortName: 'aarab', type: 'acsee' },
  { code: 133, Name: 'Biology', ShortName: 'abio', type: 'acsee' },
  { code: 153, Name: 'Accountancy', ShortName: 'aacc', type: 'acsee' },
  { code: 132, Name: 'Chemistry', ShortName: 'achem', type: 'acsee' },
  { code: 152, Name: 'Commerce', ShortName: 'acomm', type: 'acsee' },
  { code: 142, Name: 'Advance Mathematics', ShortName: 'advmath', type: 'acsee' },
  { code: 122, Name: 'English Language', ShortName: 'aengl', type: 'acsee' },
  { code: 123, Name: 'French', ShortName: 'afre', type: 'acsee' },
  { code: 113, Name: 'Geography', ShortName: 'ageo', type: 'acsee' },
  { code: 134, Name: 'Agriculture', ShortName: 'aagri', type: 'acsee' },
  { code: 112, Name: 'History', ShortName: 'ahist', type: 'acsee' },
  { code: 121, Name: 'Kiswahili', ShortName: 'akisw', type: 'acsee' },
  { code: 131, Name: 'Physics', ShortName: 'aphy', type: 'acsee' },
  { code: 141, Name: 'Basic Applied Mathematics', ShortName: 'bam', type: 'acsee' },
  { code: 114, Name: 'Divinity', ShortName: 'div', type: 'acsee' },
  { code: 151, Name: 'Economics', ShortName: 'aeco', type: 'acsee' },
  { code: 155, Name: 'Nutrition', ShortName: 'anutr', type: 'acsee' },
  { code: 111, Name: 'General Studies', ShortName: 'ags', type: 'acsee' },
  { code: 136, Name: 'Computer Science', ShortName: 'acomp', type: 'acsee' },
  { code: 1002, Name: 'Fine art', ShortName: 'afart', type: 'acsee' },
  { code: 99099, Name: 'Chinese Language', ShortName: 'achinla', type: 'acsee' },
  { code: 42, Name: 'Additional Mathematics', ShortName: 'addmath', type: 'csee' },
  { code: 25, Name: 'Arabic Language', ShortName: 'arabic', type: 'csee' },
  { code: 34, Name: 'Agricultural Science', ShortName: 'agri', type: 'csee' },
  { code: 62, Name: 'Bookkeeping', ShortName: 'bkeep', type: 'csee' },
  { code: 14, Name: 'Bible Knowledge', ShortName: 'bible', type: 'csee' },
  { code: 41, Name: 'Basic mathematics', ShortName: 'bmath', type: 'csee' },
  { code: 33, Name: 'Biology', ShortName: 'bio', type: 'csee' },
  { code: 32, Name: 'Chemistry', ShortName: 'chem', type: 'csee' },
  { code: 11, Name: 'Civics', ShortName: 'civ', type: 'csee' },
  { code: 61, Name: 'Commerce', ShortName: 'comm', type: 'csee' },
  { code: 36, Name: 'Computer Science', ShortName: 'comp', type: 'csee' },
  { code: 36, Name: 'Computer studies', ShortName: 'infocomp	', type: 'csee' },
  { code: 15, Name: 'Islamic Knowledge', ShortName: 'islam', type: 'csee' },
  { code: 22, Name: 'English Language', ShortName: 'engl', type: 'csee' },
  { code: 16, Name: 'Fine Art', ShortName: 'fart', type: 'csee' },
  { code: 51, Name: 'Food and nutrition', ShortName: 'food', type: 'csee' },
  { code: 23, Name: 'French', ShortName: 'french', type: 'csee' },
  { code: 13, Name: 'Geography', ShortName: 'geo', type: 'csee' },
  { code: 12, Name: 'History', ShortName: 'hist', type: 'csee' },
  { code: 21, Name: 'kiswahili', ShortName: 'kisw', type: 'csee' },
  { code: 24, Name: 'Literature in English', ShortName: 'englit', type: 'csee' },
  { code: 17, Name: 'Music', ShortName: 'music', type: 'csee' },
  { code: 31, Name: 'Physics', ShortName: 'phy', type: 'csee' },
  { code: 18, Name: 'Physical Education', ShortName: 'phyed', type: 'csee' },
]
export const acseeSubject = subjects.filter(s=> s.type==='acsee')

export const cseeSubjects = [
  { code: 62, Name: 'Book keeping', ShortName: 'bkeep', Track: 'business', level: 'CSEE', grade: '' },
  { code: 61, Name: 'Commerce', ShortName: 'comm', Track: 'business', level: 'CSEE', grade: '' },
  { code: 41, Name: 'Basic mathematics', ShortName: 'bmath', Track: 'core', level: 'CSEE', grade: '' },
  { code: 33, Name: 'Biology', ShortName: 'bio', Track: 'core', level: 'CSEE', grade: '' },
  { code: 11, Name: 'Civics', ShortName: 'civ', Track: 'core', level: 'CSEE', grade: '' },
  { code: 22, Name: 'English Language', ShortName: 'engl', Track: 'core', level: 'CSEE', grade: '' },
  { code: 13, Name: 'Geography', ShortName: 'geo', Track: 'core', level: 'CSEE', grade: '' },
  { code: 12, Name: 'History', ShortName: 'hist', Track: 'core', level: 'CSEE', grade: '' },
  { code: 21, Name: 'Kiswahili', ShortName: 'kisw', Track: 'core', level: 'CSEE', grade: '' },
  { code: 32, Name: 'Chemistry', ShortName: 'chem', Track: 'Natural Science', level: 'CSEE', grade: '' },
  { code: 31, Name: 'Physics', ShortName: 'phy', Track: 'Natural Science', level: 'CSEE', grade: '' },
  { code: 42, Name: 'Additional Mathematics', ShortName: 'addmath', Track: 'Optional', level: 'CSEE', grade: '' },
  { code: 34, Name: 'Agricultural Science', ShortName: '	agri', Track: 'Optional', level: 'CSEE', grade: '' },
  { code: 25, Name: 'Arabic Language', ShortName: 'arabla', Track: 'Optional', level: 'CSEE', grade: '' },
  { code: 14, Name: 'Bible Knowledge', ShortName: 'bible', Track: 'Optional', level: 'CSEE', grade: '' },
  { code: 26, Name: 'Chinese Language', ShortName: 'chinl', Track: 'Optional', level: 'CSEE', grade: '' },
  { code: 15, Name: 'Elimu ya Dini ya Kiislam', ShortName: 'islam', Track: 'Optional', level: 'CSEE', grade: '' },
  { code: 16, Name: 'Fine Art', ShortName: 'fart', Track: 'Optional', level: 'CSEE', grade: '' },
  { code: 23, Name: 'French Language', ShortName: 'frel', Track: 'Optional', level: 'CSEE', grade: '' },
  { code: 36, Name: 'Information and Computer Studies', ShortName: 'infocomp', Track: 'Optional', level: 'CSEE', grade: '' },
  { code: 24, Name: 'Literature in English', ShortName: 'englit', Track: 'Optional', level: 'CSEE', grade: '' },
  { code: 17, Name: 'Music', ShortName: 'music', Track: 'Optional', level: 'CSEE', grade: '' },
  { code: 18, Name: 'Physical Education', ShortName: 'phyed', Track: 'Optional', level: 'CSEE', grade: '' },
]

// for combination dropdown
export const subjectCombinations = combinationDetails.map(combination => {
  return { label: combination.Subjects.map(subject => subject.Name).join(', ') + ' (' + combination.Name + ')', value: combination.Name }
})
// for acsee optional subject dropdown
export const acseeOptionalSubs = subjects
  .filter(s => s.type === 'acsee')
  .map(subject => {
    return { label: subject.Name, value: subject.code }
  })
// for csee optional subject dropdown
export const cseeOptionalSubs = cseeSubjects
  .filter(s => s.Track === 'Optional')
  .map(subject => {
    return { label: subject.Name, value: subject.code }
  })

export const programmes = [
  {
    Institution_code: "AE",
    Institution_id: "145",
    ProgrammeCode: "AE001",
    Programme: "Bachelor Degree in Adult and Continuing Education",
    capacity: "50"
  },
  {
    Institution_code: "AE",
    Institution_id: "145",
    ProgrammeCode: "AE002",
    Programme: "Bachelor Degree in Adult Education and Community Development",
    capacity: "50"
  },
  {
    Institution_code: "AE",
    Institution_id: "145",
    ProgrammeCode: "AE003",
    Programme: "Bachelor Degree in Adult Education and Community Development (Open and Distance Learning)",
    capacity: "50"
  },
  {
    Institution_code: "AE",
    Institution_id: "145",
    ProgrammeCode: "AE004",
    Programme: "Bachelor Degree in Adult and Continuing Education (Open and Distance Learning)",
    capacity: "50"
  },
  {
    Institution_code: "AM",
    Institution_id: "128",
    ProgrammeCode: "AM001",
    Programme: "Bachelor of Arts with Education",
    capacity: "3"
  },
  {
    Institution_code: "AM",
    Institution_id: "128",
    ProgrammeCode: "AM003",
    Programme: "Bachelor of Business Administration",
    capacity: "3"
  },
  {
    Institution_code: "AM",
    Institution_id: "128",
    ProgrammeCode: "AM006",
    Programme: "Bachelor of Education (Special Needs)",
    capacity: "3"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR001",
    Programme: "Bachelor of Architecture",
    capacity: "60"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR002",
    Programme: "Bachelor of Science in Interior Design",
    capacity: "45"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR003",
    Programme: "Bachelor of Science in Landscape Architecture",
    capacity: "45"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR005",
    Programme: "Bachelor of Science in Geomatics",
    capacity: "60"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR009",
    Programme: "Bachelor of Science in Environmental Engineering",
    capacity: "80"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR011",
    Programme: "Bachelor of Science in Environmental Science and Management",
    capacity: "85"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR012",
    Programme: "Bachelor of Science in Housing and Infrastructure Planning",
    capacity: "45"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR013",
    Programme: "Bachelor of Science in Land Management and Valuation",
    capacity: "146"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR014",
    Programme: "Bachelor of Science in Municipal and Industrial Services Engineering",
    capacity: "45"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR015",
    Programme: "Bachelor of Science in Property and Facility Management",
    capacity: "45"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR016",
    Programme: "Bachelor of Science in Regional Development Planning",
    capacity: "45"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR017",
    Programme: "Bachelor of Science in Real Estate, Finance and Investment",
    capacity: "65"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR018",
    Programme: "Bachelor of Science in Urban and Regional Planning",
    capacity: "55"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR019",
    Programme: "Bachelor of Arts in Economics",
    capacity: "146"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR020",
    Programme: "Bachelor of Science in Information Systems Management",
    capacity: "83"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR021",
    Programme: "Bachelor of Science in Civil Engineering",
    capacity: "85"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR022",
    Programme: "Bachelor of Science in Accounting and Finance",
    capacity: "146"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR023",
    Programme: "Bachelor of Arts in Community Development",
    capacity: "146"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR024",
    Programme: "Bachelor of Science in Geographical Information Systems and Remote Sensing",
    capacity: "80"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR025",
    Programme: "Bachelor of Science in Quantity Surveying and Construction Economics",
    capacity: "125"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR026",
    Programme: "Bachelor of Science in Environmental Laboratory Science and Technology",
    capacity: "40"
  },
  {
    Institution_code: "AR",
    Institution_id: "129",
    ProgrammeCode: "AR027",
    Programme: "Bachelor of Science in Computer Systems and Networks",
    capacity: "60"
  },
  {
    Institution_code: "AT",
    Institution_id: "130",
    ProgrammeCode: "AT003",
    Programme: "Bachelor Degree in Computer Science",
    capacity: "30"
  },
  {
    Institution_code: "AT",
    Institution_id: "130",
    ProgrammeCode: "AT004",
    Programme: "Bachelor Degree in Information Technology",
    capacity: "20"
  },
  {
    Institution_code: "CAW",
    Institution_id: "134",
    ProgrammeCode: "WM001",
    Programme: "Bachelor Degree in Wildlife Management",
    capacity: "100"
  },
  {
    Institution_code: "CAW",
    Institution_id: "134",
    ProgrammeCode: "WM002",
    Programme: "Bachelor Degree in Wildlife Tourism",
    capacity: "100"
  },
  {
    Institution_code: "CB",
    Institution_id: "135",
    ProgrammeCode: "CBD01",
    Programme: "Bachelor Degree in Accountancy",
    capacity: "150"
  },
  {
    Institution_code: "CB",
    Institution_id: "135",
    ProgrammeCode: "CBD02",
    Programme: "Bachelor Degree in Business Administration",
    capacity: "150"
  },
  {
    Institution_code: "CB",
    Institution_id: "135",
    ProgrammeCode: "CBD03",
    Programme: "Bachelor Degree in Marketing",
    capacity: "150"
  },
  {
    Institution_code: "CB",
    Institution_id: "135",
    ProgrammeCode: "CBD04",
    Programme: "Bachelor Degree in Procurement and Supplies Chain Management",
    capacity: "150"
  },
  {
    Institution_code: "CB",
    Institution_id: "135",
    ProgrammeCode: "CBD05",
    Programme: "Bachelor Degree in Metrology and Standardization",
    capacity: "50"
  },
  {
    Institution_code: "CB",
    Institution_id: "135",
    ProgrammeCode: "CBD06",
    Programme: "Bachelor Degree in Information Technology",
    capacity: "150"
  },
  {
    Institution_code: "CB",
    Institution_id: "135",
    ProgrammeCode: "CBD07",
    Programme: "Bachelor Degree in Business Studies with Education",
    capacity: "150"
  },
  {
    Institution_code: "CBD",
    Institution_id: "136",
    ProgrammeCode: "CBM01",
    Programme: "Bachelor Degree in Accountancy",
    capacity: "100"
  },
  {
    Institution_code: "CBD",
    Institution_id: "136",
    ProgrammeCode: "CBM02",
    Programme: "Bachelor Degree in Business Administration",
    capacity: "100"
  },
  {
    Institution_code: "CBD",
    Institution_id: "136",
    ProgrammeCode: "CBM03",
    Programme: "Bachelor Degree in Marketing",
    capacity: "150"
  },
  {
    Institution_code: "CBD",
    Institution_id: "136",
    ProgrammeCode: "CBM04",
    Programme: "Bachelor Degree in Procurement and Supplies Management",
    capacity: "50"
  },
  {
    Institution_code: "CBD",
    Institution_id: "136",
    ProgrammeCode: "CBM05",
    Programme: "Bachelor Degree in Business Studies with Education",
    capacity: "150"
  },
  {
    Institution_code: "CBD",
    Institution_id: "136",
    ProgrammeCode: "CBM06",
    Programme: "Bachelor Degree in Information Technology",
    capacity: "50"
  },
  {
    Institution_code: "CBD",
    Institution_id: "136",
    ProgrammeCode: "CBM07",
    Programme: "Bachelor Degree of Business Administration in Records and Archives Management",
    capacity: "100"
  },
  {
    Institution_code: "CBD",
    Institution_id: "136",
    ProgrammeCode: "CBM08",
    Programme: "Bachelor Degree of Business Administration in Human Resources Management",
    capacity: "100"
  },
  {
    Institution_code: "CBMZ",
    Institution_id: "137",
    ProgrammeCode: "CBMZ1",
    Programme: "Bachelor Degree in Accountancy",
    capacity: "40"
  },
  {
    Institution_code: "CBMZ",
    Institution_id: "137",
    ProgrammeCode: "CBMZ2",
    Programme: "Bachelor Degree in Business Administration",
    capacity: "40"
  },
  {
    Institution_code: "CBMZ",
    Institution_id: "137",
    ProgrammeCode: "CBMZ4",
    Programme: "Bachelor Degree in Procurement and Supplies Management",
    capacity: "40"
  },
  {
    Institution_code: "CBMZ",
    Institution_id: "137",
    ProgrammeCode: "CBMZ5",
    Programme: "Bachelor Degree in Business Studies with Education",
    capacity: "50"
  },
  {
    Institution_code: "CBMZ",
    Institution_id: "137",
    ProgrammeCode: "CBMZ6",
    Programme: "Bachelor Degree in Marketing",
    capacity: "60"
  },
  {
    Institution_code: "CD",
    Institution_id: "188",
    ProgrammeCode: "CD001",
    Programme: "Bachelor Degree in Community Development",
    capacity: "120"
  },
  {
    Institution_code: "CD",
    Institution_id: "188",
    ProgrammeCode: "CD002",
    Programme: "Bachelor of Gender and Community Development",
    capacity: "120"
  },
  {
    Institution_code: "CD",
    Institution_id: "188",
    ProgrammeCode: "CD003",
    Programme: "Bachelor Degree in Project Planning and Management",
    capacity: "120"
  },
  {
    Institution_code: "CFR",
    Institution_id: "133",
    ProgrammeCode: "CFR01",
    Programme: "Bachelor Degree in International Relations and Diplomacy",
    capacity: "100"
  },
  {
    Institution_code: "CU",
    Institution_id: "132",
    ProgrammeCode: "CU001",
    Programme: "Bachelor of Medical Laboratory Sciences",
    capacity: "40"
  },
  {
    Institution_code: "CU",
    Institution_id: "132",
    ProgrammeCode: "CU003",
    Programme: "Bachelor of Pharmacy",
    capacity: "50"
  },
  {
    Institution_code: "CU",
    Institution_id: "132",
    ProgrammeCode: "CU004",
    Programme: "Doctor of Medicine",
    capacity: "170"
  },
  {
    Institution_code: "CU",
    Institution_id: "132",
    ProgrammeCode: "CU005",
    Programme: "Bachelor of Science in Nursing",
    capacity: "50"
  },
  {
    Institution_code: "CU",
    Institution_id: "132",
    ProgrammeCode: "CU006",
    Programme: "Bachelor of Science in Medical Imaging and Radiotherapy",
    capacity: "15"
  },
  {
    Institution_code: "CM",
    Institution_id: "131",
    ProgrammeCode: "CM001",
    Programme: "Bachelor of Arts with Education",
    capacity: "300"
  },
  {
    Institution_code: "CM",
    Institution_id: "131",
    ProgrammeCode: "CM002",
    Programme: "Bachelor of Business Administration",
    capacity: "360"
  },
  {
    Institution_code: "CM",
    Institution_id: "131",
    ProgrammeCode: "CM003",
    Programme: "Bachelor of laws",
    capacity: "360"
  },
  {
    Institution_code: "CM",
    Institution_id: "131",
    ProgrammeCode: "CM004",
    Programme: "Bachelor of Accounting and Finance",
    capacity: "180"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM001",
    Programme: "Doctor of Medicine",
    capacity: "170"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM002",
    Programme: "Bachelor of Science in Nursing",
    capacity: "200"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM003",
    Programme: "Bachelor of Science in Computer Science",
    capacity: "70"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM004",
    Programme: "Bachelor of Science in Information Systems",
    capacity: "80"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM005",
    Programme: "Bachelor of Science in Software Engineering",
    capacity: "80"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM006",
    Programme: "Bachelor of Science in Business Information Systems",
    capacity: "80"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM007",
    Programme: "Bachelor of Science in Computer Engineering",
    capacity: "80"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM008",
    Programme: "Bachelor of Science in Telecommunications Engineering",
    capacity: "100"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM009",
    Programme: "Bachelor of Science in Physics",
    capacity: "100"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM010",
    Programme: "Bachelor of Science in Chemistry",
    capacity: "150"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM011",
    Programme: "Bachelor of Science in Biology",
    capacity: "210"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM012",
    Programme: "Bachelor of Science in Mathematics",
    capacity: "160"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM013",
    Programme: "Bachelor of Science in Statistics",
    capacity: "130"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM014",
    Programme: "Bachelor of Science in Applied Geology",
    capacity: "40"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM015",
    Programme: "Bachelor of Science in Mining Engineering",
    capacity: "40"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM016",
    Programme: "Bachelor of Science in Metallurgy and Mineral Processing Engineering",
    capacity: "40"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM018",
    Programme: "Bachelor of Arts in History",
    capacity: "180"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM020",
    Programme: "Bachelor of Arts in English",
    capacity: "80"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM021",
    Programme: "Bachelor of Arts in French",
    capacity: "40"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM023",
    Programme: "Bachelor of Arts in Tourism and Cultural Heritage",
    capacity: "250"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM024",
    Programme: "Bachelor of Arts in Theatre and Film",
    capacity: "100"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM025",
    Programme: "Bachelor of Arts in Fine Arts and Design",
    capacity: "100"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM027",
    Programme: "Bachelor of Arts in Economics",
    capacity: "60"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM028",
    Programme: "Bachelor of Arts in Economics and Sociology",
    capacity: "60"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM030",
    Programme: "Bachelor of Arts in Political Science and Public Administration",
    capacity: "250"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM031",
    Programme: "Bachelor of Geography and Environmental Studies",
    capacity: "300"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM032",
    Programme: "Bachelor of Arts in Sociology",
    capacity: "200"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM033",
    Programme: "Bachelor of Business Administration",
    capacity: "110"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM034",
    Programme: "Bachelor of Commerce in Accounting",
    capacity: "110"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM035",
    Programme: "Bachelor of Commerce in Finance",
    capacity: "110"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM036",
    Programme: "Bachelor of Commerce in Human Resource Management",
    capacity: "60"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM037",
    Programme: "Bachelor of Commerce in Entrepreneurship",
    capacity: "60"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM038",
    Programme: "Bachelor of Commerce in International Business",
    capacity: "60"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM039",
    Programme: "Bachelor of Commerce in Tourism and Hospitality Management",
    capacity: "60"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM040",
    Programme: "Bachelor of Commerce in Marketing",
    capacity: "60"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM041",
    Programme: "Bachelor of Arts in Environmental Economics and Policy",
    capacity: "60"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM042",
    Programme: "Bachelor of Arts in Project Planning, Management and Community Development",
    capacity: "250"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM043",
    Programme: "Bachelor of Environmental Disaster Management",
    capacity: "100"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM044",
    Programme: "Bachelor of Arts in Development Studies",
    capacity: "500"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM045",
    Programme: "Bachelor of Arts in Economics and Statistics",
    capacity: "60"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM046",
    Programme: "Bachelor of Arts in International Relations",
    capacity: "100"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM047",
    Programme: "Bachelor of Laws",
    capacity: "226"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM048",
    Programme: "Bachelor of Education in Arts",
    capacity: "425"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM049",
    Programme: "Bachelor of Education in Commerce",
    capacity: "250"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM050",
    Programme: "Bachelor of Education in Psychology",
    capacity: "200"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM051",
    Programme: "Bachelor of Education in Special Needs",
    capacity: "100"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM052",
    Programme: "Bachelor of Education in Adult Education and Community Development",
    capacity: "350"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM053",
    Programme: "Bachelor of Education in Early Childhood Education",
    capacity: "200"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM054",
    Programme: "Bachelor of Education in Guidance and Counselling",
    capacity: "200"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM055",
    Programme: "Bachelor of Education in Management and Administration",
    capacity: "300"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM056",
    Programme: "Bachelor of Education in Policy, Planning and Management",
    capacity: "250"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM057",
    Programme: "Bachelor of Education in Science",
    capacity: "200"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM058",
    Programme: "Bachelor of Arts with Education",
    capacity: "300"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM059",
    Programme: "Bachelor of Science with Education",
    capacity: "250"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM060",
    Programme: "Bachelor of Education in Science with ICT",
    capacity: "150"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM062",
    Programme: "Bachelor of Science in Health Information Science",
    capacity: "100"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM063",
    Programme: "Bachelor of Science in Computer Networks and Information Security Engineering",
    capacity: "85"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM064",
    Programme: "Bachelor of Science in Petroleum Engineering",
    capacity: "25"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM065",
    Programme: "Bachelor of Arts in Philosophy and Political Science",
    capacity: "70"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM066",
    Programme: "Bachelor of Commerce in Procurement and Logistics Management",
    capacity: "120"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM067",
    Programme: "Bachelor of Commerce in Information Systems Management",
    capacity: "60"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM068",
    Programme: "Bachelor of Arts in Oriental Languages (BA in Arabic)",
    capacity: "90"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM069",
    Programme: "Bachelor of Arts in Oriental Languages (BA in Chinese)",
    capacity: "90"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM073",
    Programme: "Bachelor of Business Administration (Evening)",
    capacity: "60"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM074",
    Programme: "Bachelor of Science in Instructional Design and Information Technology",
    capacity: "80"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM075",
    Programme: "Bachelor of Science in Multimedia Technology & Animation",
    capacity: "80"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM076",
    Programme: "Bachelor of Arts in Translation and Interpretation",
    capacity: "40"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM077",
    Programme: "Bachelor of Science in Geo-informatics",
    capacity: "40"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM078",
    Programme: "Bachelor of Science in Environmental Engineering",
    capacity: "40"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM079",
    Programme: "Bachelor of Science in Environmental Sciences",
    capacity: "40"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM080",
    Programme: "Bachelor of Science in Renewable Energy Engineering",
    capacity: "40"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM081",
    Programme: "Bachelor of Science in Aquaculture and Aquatic Sciences",
    capacity: "65"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM082",
    Programme: "Bachelor of Science in Clinical Nutrition and Dietetics",
    capacity: "30"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM083",
    Programme: "Bachelor of Science in Mathematics and Statistics",
    capacity: "100"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM084",
    Programme: "Bachelor of Science in Actuarial Statistics",
    capacity: "100"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM085",
    Programme: "Bachelor of Science in Digital Content and Broadcasting Engineering",
    capacity: "80"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM086",
    Programme: "Bachelor of Science in Cyber Security and Digital Forensics Engineering",
    capacity: "80"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM087",
    Programme: "Bachelor of Arts in Archaeology and Cultural Anthropology",
    capacity: "40"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM088",
    Programme: "Bachelor of Arts in Journalism and Public Relations",
    capacity: "100"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM089",
    Programme: "Bachelor of Science in Biotechnology and Bioinformatics",
    capacity: "40"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM090",
    Programme: "Bachelor of Science in Chemical and Process Engineering",
    capacity: "25"
  },
  {
    Institution_code: "DM",
    Institution_id: "197",
    ProgrammeCode: "DM091",
    Programme: "Shahada ya Awali ya Sanaa katika Kiswahili",
    capacity: "150"
  },
  {
    Institution_code: "DMI",
    Institution_id: "139",
    ProgrammeCode: "DMI01",
    Programme: "Bachelor Degree in Maritime Transport and Nautical Science",
    capacity: "20"
  },
  {
    Institution_code: "DMI",
    Institution_id: "139",
    ProgrammeCode: "DMI02",
    Programme: "Bachelor Degree in Marine Engineering Technology",
    capacity: "20"
  },
  {
    Institution_code: "DMI",
    Institution_id: "139",
    ProgrammeCode: "DMI03",
    Programme: "Bachelor Degree in Shipping and Logistics Management",
    capacity: "100"
  },
  {
    Institution_code: "DMI",
    Institution_id: "139",
    ProgrammeCode: "DMI04",
    Programme: "Bachelor Degree in Naval Architecture and Offshore Engineering",
    capacity: "30"
  },
  {
    Institution_code: "DMI",
    Institution_id: "139",
    ProgrammeCode: "DMI05",
    Programme: "Bachelor Degree in Purchasing and Supply Chain Management",
    capacity: "100"
  },
  {
    Institution_code: "DMI",
    Institution_id: "139",
    ProgrammeCode: "DMI06",
    Programme: "Bachelor Degree in Transport and Supply Chain Management",
    capacity: "100"
  },
  {
    Institution_code: "DMI",
    Institution_id: "139",
    ProgrammeCode: "DMI07",
    Programme: "Bachelor Degree in Mechanical and Marine Engineering",
    capacity: "40"
  },
  {
    Institution_code: "DMI",
    Institution_id: "139",
    ProgrammeCode: "DMI08",
    Programme: "Bachelor Degree in Oil and Gas Engineering",
    capacity: "20"
  },
  {
    Institution_code: "DMI",
    Institution_id: "139",
    ProgrammeCode: "DMI09",
    Programme: "Bachelor Degree in Mechatronics Engineering",
    capacity: "20"
  },
  {
    Institution_code: "DT",
    Institution_id: "138",
    ProgrammeCode: "DT001",
    Programme: "Bachelor of Engineering in Civil Engineering",
    capacity: "75"
  },
  {
    Institution_code: "DT",
    Institution_id: "138",
    ProgrammeCode: "DT002",
    Programme: "Bachelor of Engineering in Computer Engineering",
    capacity: "60"
  },
  {
    Institution_code: "DT",
    Institution_id: "138",
    ProgrammeCode: "DT003",
    Programme: "Bachelor of Engineering in Electrical Engineering",
    capacity: "75"
  },
  {
    Institution_code: "DT",
    Institution_id: "138",
    ProgrammeCode: "DT004",
    Programme: "Bachelor of Engineering in Electronics and Telecommunication Engineering",
    capacity: "60"
  },
  {
    Institution_code: "DT",
    Institution_id: "138",
    ProgrammeCode: "DT005",
    Programme: "Bachelor of Engineering in Mechanical Engineering",
    capacity: "65"
  },
  {
    Institution_code: "EA",
    Institution_id: "141",
    ProgrammeCode: "EA001",
    Programme: "Bachelor Degree in Official Statistics",
    capacity: "150"
  },
  {
    Institution_code: "EA",
    Institution_id: "141",
    ProgrammeCode: "EA002",
    Programme: "Bachelor Degree in Data Science",
    capacity: "50"
  },
  {
    Institution_code: "EA",
    Institution_id: "141",
    ProgrammeCode: "EA003",
    Programme: "Bachelor Degree in Business Statistics and Economics",
    capacity: "50"
  },
  {
    Institution_code: "ESA",
    Institution_id: "142",
    ProgrammeCode: "ESA01",
    Programme: "Bachelor Degree in Business Administration",
    capacity: "30"
  },
  {
    Institution_code: "ESA",
    Institution_id: "142",
    ProgrammeCode: "ESA02",
    Programme: "Bachelor Degree in Public Administration",
    capacity: "30"
  },
  {
    Institution_code: "HK",
    Institution_id: "143",
    ProgrammeCode: "HK001",
    Programme: "Doctor of Medicine",
    capacity: "123"
  },
  {
    Institution_code: "HK",
    Institution_id: "143",
    ProgrammeCode: "HK002",
    Programme: "Bachelor of Science in Nursing",
    capacity: "60"
  },
  {
    Institution_code: "HK",
    Institution_id: "143",
    ProgrammeCode: "HK003",
    Programme: "Bachelor of Social Work",
    capacity: "70"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA001",
    Programme: "Bachelor of Finance and Banking",
    capacity: "150"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA002",
    Programme: "Bachelor of Business Management",
    capacity: "240"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA003",
    Programme: "Bachelor of Computer Science",
    capacity: "100"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA004",
    Programme: "Bachelor of Information Technology",
    capacity: "100"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA005",
    Programme: "Bachelor of Procurement and Logistics Management",
    capacity: "200"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA006",
    Programme: "Bachelor of Economics and Finance",
    capacity: "200"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA007",
    Programme: "Bachelor of Accountancy",
    capacity: "300"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA009",
    Programme: "Bachelor of Economics and Taxation",
    capacity: "100"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA010",
    Programme: "Bachelor of Accounting with Information Technology",
    capacity: "219"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA011",
    Programme: "Bachelor of Banking with Apprenticeship",
    capacity: "100"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA012",
    Programme: "Bachelor of Insurance and Risk Management",
    capacity: "200"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA013",
    Programme: "Bachelor of Education with Computer Science",
    capacity: "235"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA014",
    Programme: "Bachelor of Insurance and Risk Management with Apprenticeship",
    capacity: "136"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA015",
    Programme: "Bachelor of Library Studies and Information Science",
    capacity: "300"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA016",
    Programme: "Bachelor of Credit Management",
    capacity: "250"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA017",
    Programme: "Bachelor of Security and Strategic Studies",
    capacity: "100"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA018",
    Programme: "Bachelor of Tourism and Hospitality with Apprenticeship",
    capacity: "450"
  },
  {
    Institution_code: "IA",
    Institution_id: "144",
    ProgrammeCode: "IA019",
    Programme: "Bachelor of Cyber Security",
    capacity: "170"
  },
  {
    Institution_code: "IAD",
    Institution_id: "201",
    ProgrammeCode: "IAD01",
    Programme: "Bachelor of Accountancy",
    capacity: "120"
  },
  {
    Institution_code: "IF",
    Institution_id: "146",
    ProgrammeCode: "IF001",
    Programme: "Bachelor of Accounting",
    capacity: "600"
  },
  {
    Institution_code: "IF",
    Institution_id: "146",
    ProgrammeCode: "IF002",
    Programme: "Bachelor of Banking and Finance",
    capacity: "500"
  },
  {
    Institution_code: "IF",
    Institution_id: "146",
    ProgrammeCode: "IF003",
    Programme: "Bachelor of Science in Information Technology",
    capacity: "400"
  },
  {
    Institution_code: "IF",
    Institution_id: "146",
    ProgrammeCode: "IF004",
    Programme: "Bachelor of Science in Insurance and Risk Management",
    capacity: "350"
  },
  {
    Institution_code: "IF",
    Institution_id: "146",
    ProgrammeCode: "IF005",
    Programme: "Bachelor of Science in Social Protection",
    capacity: "350"
  },
  {
    Institution_code: "IF",
    Institution_id: "146",
    ProgrammeCode: "IF006",
    Programme: "Bachelor of Science in Tax Management",
    capacity: "400"
  },
  {
    Institution_code: "IF",
    Institution_id: "146",
    ProgrammeCode: "IF007",
    Programme: "Bachelor of Science in Computer Science",
    capacity: "300"
  },
  {
    Institution_code: "IF",
    Institution_id: "146",
    ProgrammeCode: "IF008",
    Programme: "Bachelor of Science in Actuarial Sciences",
    capacity: "75"
  },
  {
    Institution_code: "IF",
    Institution_id: "146",
    ProgrammeCode: "IF009",
    Programme: "Bachelor of Science in Economics and Finance",
    capacity: "500"
  },
  {
    Institution_code: "IF",
    Institution_id: "146",
    ProgrammeCode: "IF010",
    Programme: "Bachelor of Accounting with Information Technology",
    capacity: "400"
  },
  {
    Institution_code: "IFM",
    Institution_id: "147",
    ProgrammeCode: "IFM01",
    Programme: "Bachelor of Accounting",
    capacity: "80"
  },
  {
    Institution_code: "IFM",
    Institution_id: "147",
    ProgrammeCode: "IFM02",
    Programme: "Bachelor of Banking and Finance",
    capacity: "80"
  },
  {
    Institution_code: "IFM",
    Institution_id: "147",
    ProgrammeCode: "IFM03",
    Programme: "Bachelor of Science in Insurance and Risk Management",
    capacity: "100"
  },
  {
    Institution_code: "IFM",
    Institution_id: "147",
    ProgrammeCode: "IFM04",
    Programme: "Bachelor of Science in Economics and Finance",
    capacity: "100"
  },
  {
    Institution_code: "IPA",
    Institution_id: "202",
    ProgrammeCode: "IPA01",
    Programme: "Bachelor Degree in International Relations and Diplomacy",
    capacity: "40"
  },
  {
    Institution_code: "IPA",
    Institution_id: "202",
    ProgrammeCode: "IPA02",
    Programme: "Bachelor Degree in Human Resource Management",
    capacity: "50"
  },
  {
    Institution_code: "IPA",
    Institution_id: "202",
    ProgrammeCode: "IPA03",
    Programme: "Bachelor Degree in Records and Achieves Management",
    capacity: "50"
  },
  {
    Institution_code: "IT",
    Institution_id: "151",
    ProgrammeCode: "IT001",
    Programme: "Bachelor Degree in Customs and Tax Management",
    capacity: "100"
  },
  {
    Institution_code: "JC",
    Institution_id: "152",
    ProgrammeCode: "JC001",
    Programme: "Bachelor of Arts with Education",
    capacity: "500"
  },
  {
    Institution_code: "JC",
    Institution_id: "152",
    ProgrammeCode: "JC002",
    Programme: "Bachelor of Arts in Sociology",
    capacity: "100"
  },
  {
    Institution_code: "JC",
    Institution_id: "152",
    ProgrammeCode: "JC003",
    Programme: "Bachelor of Arts in Philosophy",
    capacity: "130"
  },
  {
    Institution_code: "JC",
    Institution_id: "152",
    ProgrammeCode: "JC006",
    Programme: "Bachelor of Arts in Education with Religious Studies",
    capacity: "50"
  },
  {
    Institution_code: "JC",
    Institution_id: "152",
    ProgrammeCode: "JC007",
    Programme: "Bachelor of Business Administration",
    capacity: "100"
  },
  {
    Institution_code: "JC",
    Institution_id: "152",
    ProgrammeCode: "JC008",
    Programme: "Bachelor of Arts in Economics",
    capacity: "100"
  },
  {
    Institution_code: "JC",
    Institution_id: "152",
    ProgrammeCode: "JC009",
    Programme: "Bachelor of Laws",
    capacity: "150"
  },
  {
    Institution_code: "JC",
    Institution_id: "152",
    ProgrammeCode: "JC010",
    Programme: "Bachelor of Science in Psychology and Counselling",
    capacity: "80"
  },
  {
    Institution_code: "JC",
    Institution_id: "152",
    ProgrammeCode: "JC011",
    Programme: "Bachelor of Accounting and Finance",
    capacity: "100"
  },
  {
    Institution_code: "JC",
    Institution_id: "152",
    ProgrammeCode: "JC012",
    Programme: "Bachelor of Arts in Library, Records and Information Management",
    capacity: "100"
  },
  {
    Institution_code: "JC",
    Institution_id: "152",
    ProgrammeCode: "JC013",
    Programme: "Bachelor of science in Computer Science",
    capacity: "100"
  },
  {
    Institution_code: "JDH",
    Institution_id: "203",
    ProgrammeCode: "JDH01",
    Programme: "Doctor of Medicine",
    capacity: "170"
  },
  {
    Institution_code: "JD",
    Institution_id: "179",
    ProgrammeCode: "JD001",
    Programme: "Bachelor of Engineering in Civil Engineering",
    capacity: "140"
  },
  {
    Institution_code: "JD",
    Institution_id: "179",
    ProgrammeCode: "JD002",
    Programme: "Bachelor of Engineering in Computer Science Engineering",
    capacity: "140"
  },
  {
    Institution_code: "JD",
    Institution_id: "179",
    ProgrammeCode: "JD003",
    Programme: "Bachelor of Electrical and Electronics Engineering",
    capacity: "140"
  },
  {
    Institution_code: "JD",
    Institution_id: "179",
    ProgrammeCode: "JD004",
    Programme: "Bachelor of Electronics and Communication Engineering",
    capacity: "140"
  },
  {
    Institution_code: "JD",
    Institution_id: "179",
    ProgrammeCode: "JD005",
    Programme: "Bachelor of Engineering in Information Systems and Network Engineering",
    capacity: "140"
  },
  {
    Institution_code: "JD",
    Institution_id: "179",
    ProgrammeCode: "JD006",
    Programme: "Bachelor of Mechanical Engineering",
    capacity: "100"
  },
  {
    Institution_code: "JD",
    Institution_id: "179",
    ProgrammeCode: "JD008",
    Programme: "Bachelor of Science with Education",
    capacity: "200"
  },
  {
    Institution_code: "JD",
    Institution_id: "179",
    ProgrammeCode: "JD009",
    Programme: "Bachelor of Science in Computer Science",
    capacity: "100"
  },
  {
    Institution_code: "KC",
    Institution_id: "155",
    ProgrammeCode: "KC001",
    Programme: "Bachelor of Sciences in Health Laboratory",
    capacity: "96"
  },
  {
    Institution_code: "KC",
    Institution_id: "155",
    ProgrammeCode: "KC002",
    Programme: "Bachelor of Science in Physiotherapy",
    capacity: "35"
  },
  {
    Institution_code: "KC",
    Institution_id: "155",
    ProgrammeCode: "KC004",
    Programme: "Doctor of Medicine",
    capacity: "170"
  },
  {
    Institution_code: "KC",
    Institution_id: "155",
    ProgrammeCode: "KC005",
    Programme: "Bachelor of Sciences in Nursing",
    capacity: "43"
  },
  {
    Institution_code: "KIS",
    Institution_id: "154",
    ProgrammeCode: "KIS01",
    Programme: "Bachelor of Engineering in Aircraft Maintenance",
    capacity: "10"
  },
  {
    Institution_code: "KIS",
    Institution_id: "154",
    ProgrammeCode: "KIS02",
    Programme: "Bachelor of Pilot Engineering",
    capacity: "10"
  },
  {
    Institution_code: "KIS",
    Institution_id: "154",
    ProgrammeCode: "KIS03",
    Programme: "Bachelor of Civil Engineering",
    capacity: "25"
  },
  {
    Institution_code: "KIS",
    Institution_id: "154",
    ProgrammeCode: "KIS04",
    Programme: "Bachelor of Electrical and Electronics Engineering",
    capacity: "25"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC01",
    Programme: "Bachelor of Computer Science",
    capacity: "125"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC02",
    Programme: "Bachelor of Information Technology",
    capacity: "150"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC03",
    Programme: "Bachelor of Business Administration (Accounting)",
    capacity: "125"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC05",
    Programme: "Bachelor of Laws",
    capacity: "150"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC06",
    Programme: "Bachelor of Arts with Education",
    capacity: "150"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC07",
    Programme: "Bachelor of Public Administration",
    capacity: "200"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC09",
    Programme: "Bachelor of Social Work and Social Administration",
    capacity: "100"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC10",
    Programme: "Bachelor of Business Administration (Finance and Banking)",
    capacity: "100"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC11",
    Programme: "Bachelor of Business Administration (Human Resources Management)",
    capacity: "150"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC12",
    Programme: "Bachelor of Business Administration (Marketing)",
    capacity: "150"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC13",
    Programme: "Bachelor of Business Administration (Procurement)",
    capacity: "180"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC14",
    Programme: "Bachelor of Business Administration (Commerce)",
    capacity: "100"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC15",
    Programme: "Bachelor of Medicine and Bachelor of Surgery (Chirugie)",
    capacity: "100"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC16",
    Programme: "Bachelor of Pharmacy",
    capacity: "80"
  },
  {
    Institution_code: "KUC",
    Institution_id: "153",
    ProgrammeCode: "KUC17",
    Programme: "Bachelor of Medical Laboratory Science",
    capacity: "80"
  },
  {
    Institution_code: "LGTI",
    Institution_id: "156",
    ProgrammeCode: "LGT01",
    Programme: "Bachelor Degree in Local Government Administration and Management",
    capacity: "100"
  },
  {
    Institution_code: "LGTI",
    Institution_id: "156",
    ProgrammeCode: "LGT02",
    Programme: "Bachelor of Community Development",
    capacity: "50"
  },
  {
    Institution_code: "MAR",
    Institution_id: "157",
    ProgrammeCode: "MAR001",
    Programme: "Bachelor of Science in Mathematics and Statistics",
    capacity: "30"
  },
  {
    Institution_code: "MAR",
    Institution_id: "157",
    ProgrammeCode: "MAR009",
    Programme: "Bachelor of Science with Education",
    capacity: "80"
  },
  {
    Institution_code: "MAR",
    Institution_id: "157",
    ProgrammeCode: "MAR010",
    Programme: "Bachelor of Arts with Education",
    capacity: "80"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB001",
    Programme: "Bachelor of Business Administration",
    capacity: "160"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB002",
    Programme: "Bachelor of Civil Engineering",
    capacity: "150"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB003",
    Programme: "Bachelor of Electrical Engineering",
    capacity: "150"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB004",
    Programme: "Bachelor of Technology in Architecture",
    capacity: "80"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB005",
    Programme: "Bachelor of Mechanical Engineering",
    capacity: "100"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB006",
    Programme: "Bachelor of Computer Engineering",
    capacity: "50"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB007",
    Programme: "Bachelor of Science with Education",
    capacity: "160"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB008",
    Programme: "Bachelor of Laboratory Sciences and Technology",
    capacity: "130"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB009",
    Programme: "Bachelor of Engineering in Telecommunication Systems",
    capacity: "65"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB010",
    Programme: "Bachelor of Computer Science",
    capacity: "65"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB011",
    Programme: "Bachelor of Science in Information and Communication Technology",
    capacity: "65"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB012",
    Programme: "Bachelor of Technical Education in Electrical and Electronics Engineering",
    capacity: "120"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB013",
    Programme: "Bachelor of Technical Education in Mechanical Engineering",
    capacity: "120"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB014",
    Programme: "Bachelor of Technical Education in Civil Engineering",
    capacity: "120"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB015",
    Programme: "Bachelor of Technical Education in Architectural Engineering",
    capacity: "120"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB016",
    Programme: "Bachelor of Technology in Landscape Architecture",
    capacity: "40"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB017",
    Programme: "Bachelor of Engineering in Data Science",
    capacity: "50"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB018",
    Programme: "Bachelor of Science in Food Science and Technology",
    capacity: "60"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB019",
    Programme: "Bachelor of Science in Natural Resources Conservation",
    capacity: "90"
  },
  {
    Institution_code: "MB",
    Institution_id: "159",
    ProgrammeCode: "MB020",
    Programme: "Bachelor of Agribusiness Management and Technology",
    capacity: "200"
  },
  {
    Institution_code: "MBR",
    Institution_id: "160",
    ProgrammeCode: "MBR01",
    Programme: "Bachelor of Business Administration",
    capacity: "60"
  },
  {
    Institution_code: "MBR",
    Institution_id: "160",
    ProgrammeCode: "MBR02",
    Programme: "Bachelor of Mechanical Engineering",
    capacity: "40"
  },
  {
    Institution_code: "MC",
    Institution_id: "162",
    ProgrammeCode: "MC001",
    Programme: "Bachelor of Arts in Community Economic Development",
    capacity: "200"
  },
  {
    Institution_code: "MC",
    Institution_id: "162",
    ProgrammeCode: "MC002",
    Programme: "Bachelor of Arts in Cooperative Management and Accounting",
    capacity: "396"
  },
  {
    Institution_code: "MC",
    Institution_id: "162",
    ProgrammeCode: "MC003",
    Programme: "Bachelor of Arts in Microfinance and Enterprise Development",
    capacity: "300"
  },
  {
    Institution_code: "MC",
    Institution_id: "162",
    ProgrammeCode: "MC004",
    Programme: "Bachelor of Arts in Procurement and Supply Management",
    capacity: "200"
  },
  {
    Institution_code: "MC",
    Institution_id: "162",
    ProgrammeCode: "MC005",
    Programme: "Bachelor of Accounting and Finance",
    capacity: "430"
  },
  {
    Institution_code: "MC",
    Institution_id: "162",
    ProgrammeCode: "MC006",
    Programme: "Bachelor of Arts in Human Resources Management",
    capacity: "440"
  },
  {
    Institution_code: "MC",
    Institution_id: "162",
    ProgrammeCode: "MC007",
    Programme: "Bachelor of Arts in Marketing and Entrepreneurship",
    capacity: "300"
  },
  {
    Institution_code: "MC",
    Institution_id: "162",
    ProgrammeCode: "MC008",
    Programme: "Bachelor of Arts in Business Economics",
    capacity: "262"
  },
  {
    Institution_code: "MC",
    Institution_id: "162",
    ProgrammeCode: "MC009",
    Programme: "Bachelor Science in Business Information and Communication Technology",
    capacity: "200"
  },
  {
    Institution_code: "MC",
    Institution_id: "162",
    ProgrammeCode: "MC010",
    Programme: "Bachelor of Laws",
    capacity: "200"
  },
  {
    Institution_code: "MC",
    Institution_id: "162",
    ProgrammeCode: "MC011",
    Programme: "Bachelor of Accounting and Taxation",
    capacity: "200"
  },
  {
    Institution_code: "MDA",
    Institution_id: "168",
    ProgrammeCode: "MDA01",
    Programme: "Bachelor of Accounting and Finance in Business Sector",
    capacity: "126"
  },
  {
    Institution_code: "MDA",
    Institution_id: "168",
    ProgrammeCode: "MDA02",
    Programme: "Bachelor of Public Administration",
    capacity: "126"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH001",
    Programme: "Bachelor of Pharmacy",
    capacity: "65"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH002",
    Programme: "Bachelor of Medical Laboratory Sciences in Clinical Chemistry",
    capacity: "15"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH003",
    Programme: "Bachelor of Medical Laboratory Sciences in Hematology and Blood Transfusion",
    capacity: "15"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH004",
    Programme: "Bachelor of Medical Laboratory Sciences in Histotechnology",
    capacity: "15"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH005",
    Programme: "Bachelor of Medical Laboratory Science in Microbiology and Immunology",
    capacity: "20"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH006",
    Programme: "Bachelor of Medical Laboratory Sciences in Parasitology and Medical Entomology",
    capacity: "20"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH007",
    Programme: "Bachelor of Science in Environmental Health Sciences",
    capacity: "85"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH008",
    Programme: "Bachelor of Science in Diagnostic and Therapeutic Radiography",
    capacity: "25"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH009",
    Programme: "Bachelor of Science in Nursing",
    capacity: "60"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH010",
    Programme: "Doctor of Dental Surgery",
    capacity: "55"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH011",
    Programme: "Doctor of Medicine",
    capacity: "210"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH013",
    Programme: "Bachelor of Medical Laboratory Sciences General",
    capacity: "35"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH014",
    Programme: "Bachelor of Biomedical Engineering",
    capacity: "20"
  },
  {
    Institution_code: "MH",
    Institution_id: "164",
    ProgrammeCode: "MH016",
    Programme: "Bachelor of Science in Physiotherapy",
    capacity: "65"
  },
  {
    Institution_code: "MMB",
    Institution_id: "169",
    ProgrammeCode: "MMB01",
    Programme: "Bachelor of Laws",
    capacity: "210"
  },
  {
    Institution_code: "MMB",
    Institution_id: "169",
    ProgrammeCode: "MMB02",
    Programme: "Bachelor of Business Administration in Marketing Management",
    capacity: "168"
  },
  {
    Institution_code: "MMB",
    Institution_id: "169",
    ProgrammeCode: "MMB03",
    Programme: "Bachelor of Accounting and Finance in Business Sector",
    capacity: "168"
  },
  {
    Institution_code: "MMB",
    Institution_id: "169",
    ProgrammeCode: "MMB04",
    Programme: "Bachelor of Human Resource Management",
    capacity: "210"
  },
  {
    Institution_code: "MN",
    Institution_id: "190",
    ProgrammeCode: "MN001",
    Programme: "Bachelor Degree in Economics of Development",
    capacity: "220"
  },
  {
    Institution_code: "MN",
    Institution_id: "190",
    ProgrammeCode: "MN002",
    Programme: "Bachelor Degree in Gender and Development",
    capacity: "220"
  },
  {
    Institution_code: "MN",
    Institution_id: "190",
    ProgrammeCode: "MN003",
    Programme: "Bachelor Degree in Management of Social Development",
    capacity: "220"
  },
  {
    Institution_code: "MN",
    Institution_id: "190",
    ProgrammeCode: "MN004",
    Programme: "Bachelor Degree in Human Resource Management",
    capacity: "220"
  },
  {
    Institution_code: "MN",
    Institution_id: "190",
    ProgrammeCode: "MN005",
    Programme: "Bachelor Degree of Education in Kiswahili and English Languages",
    capacity: "220"
  },
  {
    Institution_code: "MN",
    Institution_id: "190",
    ProgrammeCode: "MN006",
    Programme: "Bachelor Degree of Education in Geography and History",
    capacity: "220"
  },
  {
    Institution_code: "MN",
    Institution_id: "190",
    ProgrammeCode: "MN007",
    Programme: "Bachelor Degree of Education in Geography and Kiswahili",
    capacity: "220"
  },
  {
    Institution_code: "MN",
    Institution_id: "190",
    ProgrammeCode: "MN008",
    Programme: "Bachelor Degree of Education in Geography and English",
    capacity: "220"
  },
  {
    Institution_code: "MN",
    Institution_id: "190",
    ProgrammeCode: "MN009",
    Programme: "Bachelor Degree of Education in History and English",
    capacity: "220"
  },
  {
    Institution_code: "MN",
    Institution_id: "190",
    ProgrammeCode: "MN010",
    Programme: "Bachelor Degree of Education in History and Kiswahili",
    capacity: "255"
  },
  {
    Institution_code: "MNZ",
    Institution_id: "191",
    ProgrammeCode: "MNZ001",
    Programme: "Bachelor Degree in Economics of Development",
    capacity: "100"
  },
  {
    Institution_code: "MNZ",
    Institution_id: "191",
    ProgrammeCode: "MNZ002",
    Programme: "Bachelor Degree in Gender and Development",
    capacity: "100"
  },
  {
    Institution_code: "MNZ",
    Institution_id: "191",
    ProgrammeCode: "MNZ003",
    Programme: "Bachelor Degree in Management of Social Development",
    capacity: "100"
  },
  {
    Institution_code: "MNZ",
    Institution_id: "191",
    ProgrammeCode: "MNZ004",
    Programme: "Bachelor Degree in Human Resource Management",
    capacity: "100"
  },
  {
    Institution_code: "MNZ",
    Institution_id: "191",
    ProgrammeCode: "MNZ005",
    Programme: "Bachelor Degree of Education in Kiswahili and English Languages",
    capacity: "100"
  },
  {
    Institution_code: "MNZ",
    Institution_id: "191",
    ProgrammeCode: "MNZ006",
    Programme: "Bachelor Degree of Education in Geography and History",
    capacity: "100"
  },
  {
    Institution_code: "MNZ",
    Institution_id: "191",
    ProgrammeCode: "MNZ007",
    Programme: "Bachelor Degree of Education in Geography and Kiswahili",
    capacity: "100"
  },
  {
    Institution_code: "MNZ",
    Institution_id: "191",
    ProgrammeCode: "MNZ008",
    Programme: "Bachelor Degree of Education in Geography and English",
    capacity: "100"
  },
  {
    Institution_code: "MNZ",
    Institution_id: "191",
    ProgrammeCode: "MNZ009",
    Programme: "Bachelor Degree of Education in History and English",
    capacity: "100"
  },
  {
    Institution_code: "MNZ",
    Institution_id: "191",
    ProgrammeCode: "MNZ010",
    Programme: "Bachelor Degree of Education in History and Kiswahili",
    capacity: "100"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU001",
    Programme: "Bachelor of Accounting and Finance in Public Sector",
    capacity: "140"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU002",
    Programme: "Bachelor of Accounting and Finance in Business Sector",
    capacity: "140"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU006",
    Programme: "Bachelor of Laws",
    capacity: "175"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU008",
    Programme: "Bachelor of Public Administration",
    capacity: "140"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU009",
    Programme: "Bachelor of Business Administration in Innovation and Entrepreneurship Management",
    capacity: "105"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU010",
    Programme: "Bachelor of Procurement and Supply Chain Management",
    capacity: "105"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU011",
    Programme: "Bachelor of Business Administration in Marketing Management",
    capacity: "105"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU012",
    Programme: "Bachelor of Health Systems Management",
    capacity: "140"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU013",
    Programme: "Bachelor Human Resource Management",
    capacity: "126"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU014",
    Programme: "Bachelor of Public Administration in Local Government Management",
    capacity: "105"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU015",
    Programme: "Bachelor of Science in Production and Operations Management",
    capacity: "36"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU016",
    Programme: "Bachelor of Science in Applied Statistics",
    capacity: "36"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU017",
    Programme: "Bachelor of Science in Economics- Population and Development",
    capacity: "56"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU018",
    Programme: "Bachelor of Science in Economics- Project Planning and Management",
    capacity: "56"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU019",
    Programme: "Bachelor of Science in Economics \ufffd Economic Policy and Planning",
    capacity: "56"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU020",
    Programme: "Bachelor of Science in Information and Communication Technology with Management",
    capacity: "56"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU021",
    Programme: "Bachelor of Public Administration in Records and Archives Management",
    capacity: "126"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU022",
    Programme: "Bachelor of Science in Information Technology and Systems",
    capacity: "56"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU023",
    Programme: "Bachelor of Science in Information and Communication Technology with Business",
    capacity: "56"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU024",
    Programme: "Bachelor of Science in Library and Information Management",
    capacity: "56"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU025",
    Programme: "Bachelor of Science with Education- Mathematics and ICT",
    capacity: "70"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU026",
    Programme: "Bachelor of Science in Industrial Engineering Management",
    capacity: "70"
  },
  {
    Institution_code: "MU",
    Institution_id: "167",
    ProgrammeCode: "MU027",
    Programme: "Bachelor of Arts with Education",
    capacity: "240"
  },
  {
    Institution_code: "MUM",
    Institution_id: "165",
    ProgrammeCode: "MUM01",
    Programme: "Bachelor of Arts with Education",
    capacity: "910"
  },
  {
    Institution_code: "MUM",
    Institution_id: "165",
    ProgrammeCode: "MUM02",
    Programme: "Bachelor of Science with Education",
    capacity: "130"
  },
  {
    Institution_code: "MUM",
    Institution_id: "165",
    ProgrammeCode: "MUM04",
    Programme: "Bachelor of Arts in Mass Communication",
    capacity: "100"
  },
  {
    Institution_code: "MUM",
    Institution_id: "165",
    ProgrammeCode: "MUM05",
    Programme: "Bachelor of Islamic Studies with Education",
    capacity: "100"
  },
  {
    Institution_code: "MUM",
    Institution_id: "165",
    ProgrammeCode: "MUM06",
    Programme: "Bachelor of Law with Shariah",
    capacity: "150"
  },
  {
    Institution_code: "MUM",
    Institution_id: "165",
    ProgrammeCode: "MUM07",
    Programme: "Bachelor of Business Studies",
    capacity: "100"
  },
  {
    Institution_code: "MUM",
    Institution_id: "165",
    ProgrammeCode: "MUM08",
    Programme: "Bachelor of Arts in Geography and Population Studies",
    capacity: "200"
  },
  {
    Institution_code: "MUM",
    Institution_id: "165",
    ProgrammeCode: "MUM09",
    Programme: "Bachelor of Arts in Kiswahili",
    capacity: "400"
  },
  {
    Institution_code: "MW",
    Institution_id: "166",
    ProgrammeCode: "MW001",
    Programme: "Bachelor of Arts with Education",
    capacity: "350"
  },
  {
    Institution_code: "MW",
    Institution_id: "166",
    ProgrammeCode: "MW002",
    Programme: "Bachelor of Education Science",
    capacity: "200"
  },
  {
    Institution_code: "MW",
    Institution_id: "166",
    ProgrammeCode: "MW003",
    Programme: "Bachelor of Business Administration and Management",
    capacity: "150"
  },
  {
    Institution_code: "MW",
    Institution_id: "166",
    ProgrammeCode: "MW004",
    Programme: "Bachelor of Arts in Geography and Environmental Studies",
    capacity: "150"
  },
  {
    Institution_code: "MW",
    Institution_id: "166",
    ProgrammeCode: "MW005",
    Programme: "Bachelor of Arts in Sociology and Social Work",
    capacity: "150"
  },
  {
    Institution_code: "MW",
    Institution_id: "166",
    ProgrammeCode: "MW006",
    Programme: "Bachelor of Science in Mathematics and Statistics",
    capacity: "50"
  },
  {
    Institution_code: "MW",
    Institution_id: "166",
    ProgrammeCode: "MW007",
    Programme: "Bachelor of Arts in Philosophy with Ethics",
    capacity: "75"
  },
  {
    Institution_code: "MW",
    Institution_id: "166",
    ProgrammeCode: "MW008",
    Programme: "Bachelor of Arts in Project Planning and Management",
    capacity: "300"
  },
  {
    Institution_code: "MW",
    Institution_id: "166",
    ProgrammeCode: "MW009",
    Programme: "Bachelor of Science in Computer Science",
    capacity: "100"
  },
  {
    Institution_code: "MW",
    Institution_id: "166",
    ProgrammeCode: "MW010",
    Programme: "Bachelor of Science in Chemistry",
    capacity: "100"
  },
  {
    Institution_code: "MW",
    Institution_id: "166",
    ProgrammeCode: "MW011",
    Programme: "Bachelor of Science in Applied Biology",
    capacity: "100"
  },
  {
    Institution_code: "MW",
    Institution_id: "166",
    ProgrammeCode: "MW012",
    Programme: "Bachelor of Laws",
    capacity: "50"
  },
  {
    Institution_code: "MW",
    Institution_id: "166",
    ProgrammeCode: "MW013",
    Programme: "Bachelor of Procurement and Supply Chain Management",
    capacity: "50"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT001",
    Programme: "Bachelor Degree in Logistics and Transport Management",
    capacity: "400"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT003",
    Programme: "Bachelor Degree in Automobile Engineering",
    capacity: "100"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT004",
    Programme: "Bachelor Degree in Procurement and Logistics Management",
    capacity: "250"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT005",
    Programme: "Bachelor Degree in Human Resource Management",
    capacity: "250"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT006",
    Programme: "Bachelor Degree in Information Technology",
    capacity: "100"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT007",
    Programme: "Bachelor Degree in Mechanical Engineering",
    capacity: "100"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT008",
    Programme: "Bachelor Degree in Business Administration",
    capacity: "200"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT009",
    Programme: "Bachelor Degree in Marketing and Public Relations",
    capacity: "200"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT010",
    Programme: "Bachelor Degree in Accounting and Transport Finance",
    capacity: "200"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT011",
    Programme: "Bachelor Degree in Education with Mathematics and Information Technology",
    capacity: "100"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT012",
    Programme: "Bachelor Degree in Computer Science",
    capacity: "100"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT013",
    Programme: "Bachelor Degree in Aircraft Maintenance Engineering.",
    capacity: "10"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT014",
    Programme: "Bachelor Degree in Freight Clearing and Forwarding",
    capacity: "200"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT016",
    Programme: "Bachelor Degree in Road and Railway Transport Logistics Management",
    capacity: "200"
  },
  {
    Institution_code: "NT",
    Institution_id: "170",
    ProgrammeCode: "NT017",
    Programme: "Bachelor Degree in Shipping and Port Logistics Management",
    capacity: "200"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU001",
    Programme: "Bachelor of Arts with Education",
    capacity: "300"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU003",
    Programme: "Bachelor of Arts in Journalism",
    capacity: "100"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU004",
    Programme: "Bachelor of Arts in Mass Communication",
    capacity: "150"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU005",
    Programme: "Bachelor of Arts in Sociology",
    capacity: "350"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU006",
    Programme: "Bachelor of Arts in Social Work",
    capacity: "200"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU007",
    Programme: "Bachelor of Arts in Tourism",
    capacity: "100"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU008",
    Programme: "Bachelor of Business Administration with Education",
    capacity: "60"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU010",
    Programme: "Bachelor of Education in Special Education",
    capacity: "250"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU011",
    Programme: "Bachelor of Science in Environmental Studies",
    capacity: "200"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU012",
    Programme: "Bachelor of Laws",
    capacity: "300"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU014",
    Programme: "Bachelor of Science General",
    capacity: "250"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU015",
    Programme: "Bachelor of Science with Education",
    capacity: "250"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU016",
    Programme: "Bachelor of Science in ICT",
    capacity: "250"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU017",
    Programme: "Bachelor of Business Administration (Accounting)",
    capacity: "400"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU018",
    Programme: "Bachelor of Business Administration (Finance)",
    capacity: "250"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU019",
    Programme: "Bachelor of Business Administration (Marketing)",
    capacity: "400"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU020",
    Programme: "Bachelor of Business Administration (Human Resource Management)",
    capacity: "400"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU021",
    Programme: "Bachelor of Business Administration (International Business)",
    capacity: "250"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU022",
    Programme: "Bachelor of Human Resource Management",
    capacity: "450"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU023",
    Programme: "Bachelor of Education Teacher Educator",
    capacity: "250"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU024",
    Programme: "Bachelor of Education in Adult and Distance Learning",
    capacity: "200"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU025",
    Programme: "Bachelor of Education in Educational Policy and Management",
    capacity: "400"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU026",
    Programme: "Bachelor of Arts in Economics",
    capacity: "200"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU028",
    Programme: "Bachelor of Arts in Kiswahili and Creative Studies",
    capacity: "100"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU030",
    Programme: "Bachelor of Arts in Natural Resources Management",
    capacity: "250"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU031",
    Programme: "Bachelor of Arts in Population and Development",
    capacity: "200"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU033",
    Programme: "Bachelor of Arts in Public Administration",
    capacity: "300"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU034",
    Programme: "Bachelor of Arts in International Relations",
    capacity: "300"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU035",
    Programme: "Bachelor of Community Economic Development",
    capacity: "250"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU036",
    Programme: "Bachelor of Library & Information Management",
    capacity: "100"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU037",
    Programme: "Bachelor of Science in Energy Resources",
    capacity: "100"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU038",
    Programme: "Bachelor of Science in Food, Nutrition and Dietetics",
    capacity: "150"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU039",
    Programme: "Bachelor of Science in Data Management",
    capacity: "250"
  },
  {
    Institution_code: "OU",
    Institution_id: "171",
    ProgrammeCode: "OU040",
    Programme: "Bachelor of Procurement and Supply Chain Management",
    capacity: "600"
  },
  {
    Institution_code: "RD",
    Institution_id: "148",
    ProgrammeCode: "RD001",
    Programme: "Bachelor Degree in Development Finance and Investment Planning",
    capacity: "150"
  },
  {
    Institution_code: "RD",
    Institution_id: "148",
    ProgrammeCode: "RD002",
    Programme: "Bachelor Degree in Environmental Planning and Management",
    capacity: "150"
  },
  {
    Institution_code: "RD",
    Institution_id: "148",
    ProgrammeCode: "RD003",
    Programme: "Bachelor Degree in Population and Development Planning",
    capacity: "150"
  },
  {
    Institution_code: "RD",
    Institution_id: "148",
    ProgrammeCode: "RD004",
    Programme: "Bachelor Degree in Regional Development Planning",
    capacity: "150"
  },
  {
    Institution_code: "RD",
    Institution_id: "148",
    ProgrammeCode: "RD005",
    Programme: "Bachelor Degree in Human Resource Management",
    capacity: "250"
  },
  {
    Institution_code: "RD",
    Institution_id: "148",
    ProgrammeCode: "RD006",
    Programme: "Bachelor Degree in Urban Development and Environmental Management",
    capacity: "150"
  },
  {
    Institution_code: "RD",
    Institution_id: "148",
    ProgrammeCode: "RD007",
    Programme: "Bachelor Degree in Economics",
    capacity: "150"
  },
  {
    Institution_code: "RD",
    Institution_id: "148",
    ProgrammeCode: "RD008",
    Programme: "Bachelor Degree in Planning and Community Development",
    capacity: "200"
  },
  {
    Institution_code: "RD",
    Institution_id: "148",
    ProgrammeCode: "RD009",
    Programme: "Bachelor Degree in Business Planning and Management",
    capacity: "150"
  },
  {
    Institution_code: "RD",
    Institution_id: "148",
    ProgrammeCode: "RD010",
    Programme: "Bachelor Degree in Project Planning and Management",
    capacity: "300"
  },
  {
    Institution_code: "RD",
    Institution_id: "148",
    ProgrammeCode: "RD011",
    Programme: "Bachelor Degree in Local Government Administration and Management",
    capacity: "100"
  },
  {
    Institution_code: "RDM",
    Institution_id: "149",
    ProgrammeCode: "RDM01",
    Programme: "Bachelor Degree in Regional Development Planning",
    capacity: "150"
  },
  {
    Institution_code: "RU",
    Institution_id: "172",
    ProgrammeCode: "RU001",
    Programme: "Bachelor of Arts with Education",
    capacity: "600"
  },
  {
    Institution_code: "RU",
    Institution_id: "172",
    ProgrammeCode: "RU002",
    Programme: "Bachelor of Laws",
    capacity: "280"
  },
  {
    Institution_code: "RU",
    Institution_id: "172",
    ProgrammeCode: "RU003",
    Programme: "Bachelor of Computer Science",
    capacity: "80"
  },
  {
    Institution_code: "RU",
    Institution_id: "172",
    ProgrammeCode: "RU004",
    Programme: "Bachelor of Business Administration",
    capacity: "80"
  },
  {
    Institution_code: "RU",
    Institution_id: "172",
    ProgrammeCode: "RU005",
    Programme: "Bachelor of Accounting and Finance with Information Technology",
    capacity: "80"
  },
  {
    Institution_code: "RU",
    Institution_id: "172",
    ProgrammeCode: "RU006",
    Programme: "Bachelor of Science in Computer Science in Software Engineering",
    capacity: "80"
  },
  {
    Institution_code: "RU",
    Institution_id: "172",
    ProgrammeCode: "RU007",
    Programme: "Bachelor of Environment Health Sciences with Information Technology",
    capacity: "80"
  },
  {
    Institution_code: "RU",
    Institution_id: "172",
    ProgrammeCode: "RU008",
    Programme: "Bachelor of Science with Education (IT & Mathematics)",
    capacity: "80"
  },
  {
    Institution_code: "RU",
    Institution_id: "172",
    ProgrammeCode: "RU009",
    Programme: "Bachelor of Banking and Microfinance",
    capacity: "180"
  },
  {
    Institution_code: "SA",
    Institution_id: "175",
    ProgrammeCode: "SA001",
    Programme: "Bachelor of Arts in Mass Communication",
    capacity: "70"
  },
  {
    Institution_code: "SA",
    Institution_id: "175",
    ProgrammeCode: "SA002",
    Programme: "Bachelor of Arts in Public Relations and Marketing",
    capacity: "170"
  },
  {
    Institution_code: "SA",
    Institution_id: "175",
    ProgrammeCode: "SA003",
    Programme: "Bachelor of Business Administration",
    capacity: "450"
  },
  {
    Institution_code: "SA",
    Institution_id: "175",
    ProgrammeCode: "SA004",
    Programme: "Bachelor of Arts in Economics",
    capacity: "250"
  },
  {
    Institution_code: "SA",
    Institution_id: "175",
    ProgrammeCode: "SA005",
    Programme: "Bachelor of Arts with Education",
    capacity: "1000"
  },
  {
    Institution_code: "SA",
    Institution_id: "175",
    ProgrammeCode: "SA008",
    Programme: "Bachelor of Arts in Sociology",
    capacity: "70"
  },
  {
    Institution_code: "SA",
    Institution_id: "175",
    ProgrammeCode: "SA009",
    Programme: "Bachelor of Philosophy with Education",
    capacity: "350"
  },
  {
    Institution_code: "SA",
    Institution_id: "175",
    ProgrammeCode: "SA011",
    Programme: "Bachelor of Science in Procurement and Supply Chain Management",
    capacity: "70"
  },
  {
    Institution_code: "SA",
    Institution_id: "175",
    ProgrammeCode: "SA012",
    Programme: "Bachelor of Science in Electrical Engineering",
    capacity: "80"
  },
  {
    Institution_code: "SA",
    Institution_id: "175",
    ProgrammeCode: "SA013",
    Programme: "Bachelor of Science in Civil Engineering",
    capacity: "50"
  },
  {
    Institution_code: "SA",
    Institution_id: "175",
    ProgrammeCode: "SA014",
    Programme: "Bachelor of Science in Tourism and Hospitality Management",
    capacity: "100"
  },
  {
    Institution_code: "SA",
    Institution_id: "175",
    ProgrammeCode: "SA015",
    Programme: "Bachelor of Laws",
    capacity: "70"
  },
  {
    Institution_code: "SA",
    Institution_id: "175",
    ProgrammeCode: "SA016",
    Programme: "Bachelor of Arts in Philosophy",
    capacity: "50"
  },
  {
    Institution_code: "SAA",
    Institution_id: "176",
    ProgrammeCode: "SAA01",
    Programme: "Bachelor of Arts with Education",
    capacity: "190"
  },
  {
    Institution_code: "SAA",
    Institution_id: "176",
    ProgrammeCode: "SAA02",
    Programme: "Bachelor of Science in Tourism",
    capacity: "160"
  },
  {
    Institution_code: "SAA",
    Institution_id: "176",
    ProgrammeCode: "SAA03",
    Programme: "Bachelor of Laws",
    capacity: "70"
  },
  {
    Institution_code: "SAM",
    Institution_id: "182",
    ProgrammeCode: "SAM01",
    Programme: "Bachelor of Arts with Education",
    capacity: "1000"
  },
  {
    Institution_code: "SAM",
    Institution_id: "182",
    ProgrammeCode: "SAM02",
    Programme: "Bachelor of Arts in Sociology",
    capacity: "200"
  },
  {
    Institution_code: "SAM",
    Institution_id: "182",
    ProgrammeCode: "SAM03",
    Programme: "Bachelor of Philosophy with Education",
    capacity: "50"
  },
  {
    Institution_code: "SAM",
    Institution_id: "182",
    ProgrammeCode: "SAM04",
    Programme: "Bachelor of Philosophy with Political Science",
    capacity: "100"
  },
  {
    Institution_code: "SAM",
    Institution_id: "182",
    ProgrammeCode: "SAM05",
    Programme: "Bachelor of Laws",
    capacity: "200"
  },
  {
    Institution_code: "SAM",
    Institution_id: "182",
    ProgrammeCode: "SAM06",
    Programme: "Bachelor of Business Administration",
    capacity: "200"
  },
  {
    Institution_code: "SAM",
    Institution_id: "182",
    ProgrammeCode: "SAM07",
    Programme: "Bachelor of Arts in Economics",
    capacity: "85"
  },
  {
    Institution_code: "SAM",
    Institution_id: "182",
    ProgrammeCode: "SAM08",
    Programme: "Bachelor of Science in Mathematics and Statistics",
    capacity: "85"
  },
  {
    Institution_code: "SAM",
    Institution_id: "182",
    ProgrammeCode: "SAM09",
    Programme: "Bachelor of Science with Education (Mathematics, Chemistry or Geography)",
    capacity: "100"
  },
  {
    Institution_code: "SF",
    Institution_id: "177",
    ProgrammeCode: "SF001",
    Programme: "Doctor of Medicine",
    capacity: "150"
  },
  {
    Institution_code: "SJ",
    Institution_id: "178",
    ProgrammeCode: "SJ001",
    Programme: "Bachelor of Arts with Education",
    capacity: "500"
  },
  {
    Institution_code: "SJ",
    Institution_id: "178",
    ProgrammeCode: "SJ002",
    Programme: "Bachelor of Science Education",
    capacity: "500"
  },
  {
    Institution_code: "SJ",
    Institution_id: "178",
    ProgrammeCode: "SJ004",
    Programme: "Bachelor of Business Administration",
    capacity: "70"
  },
  {
    Institution_code: "SJ",
    Institution_id: "178",
    ProgrammeCode: "SJ005",
    Programme: "Bachelor of Pharmacy",
    capacity: "80"
  },
  {
    Institution_code: "SJ",
    Institution_id: "178",
    ProgrammeCode: "SJ006",
    Programme: "Bachelor of Science in Nursing",
    capacity: "100"
  },
  {
    Institution_code: "SJ",
    Institution_id: "178",
    ProgrammeCode: "SJ013",
    Programme: "Bachelor of Commerce with Education",
    capacity: "100"
  },
  {
    Institution_code: "SJ",
    Institution_id: "178",
    ProgrammeCode: "SJ014",
    Programme: "Bachelor of Accounting and Finance",
    capacity: "100"
  },
  {
    Institution_code: "SJ",
    Institution_id: "178",
    ProgrammeCode: "SJ015",
    Programme: "Bachelor of Arts in Theology",
    capacity: "100"
  },
  {
    Institution_code: "SJ",
    Institution_id: "178",
    ProgrammeCode: "SJ016",
    Programme: "Bachelor of Science in Information Technology",
    capacity: "70"
  },
  {
    Institution_code: "SM",
    Institution_id: "181",
    ProgrammeCode: "SM003",
    Programme: "Bachelor of Accounting and Finance",
    capacity: "100"
  },
  {
    Institution_code: "SM",
    Institution_id: "181",
    ProgrammeCode: "SM005",
    Programme: "Bachelor of Arts with Education",
    capacity: "500"
  },
  {
    Institution_code: "SM",
    Institution_id: "181",
    ProgrammeCode: "SM008",
    Programme: "Bachelor of Arts in Community Development",
    capacity: "100"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU001",
    Programme: "Bachelor of Rural Development",
    capacity: "300"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU002",
    Programme: "Bachelor of Science in Agricultural Engineering",
    capacity: "65"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU003",
    Programme: "Bachelor of Science Agriculture General",
    capacity: "130"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU004",
    Programme: "Bachelor of Science in Agricultural Economics and Agribusiness",
    capacity: "250"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU005",
    Programme: "Bachelor of Science in Agronomy",
    capacity: "80"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU006",
    Programme: "Bachelor of Science in Animal Science",
    capacity: "200"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU007",
    Programme: "Bachelor of Science in Aquaculture",
    capacity: "200"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU008",
    Programme: "Bachelor of Science in Biotechnology and Laboratory Sciences",
    capacity: "100"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU009",
    Programme: "Bachelor of Science with Education",
    capacity: "460"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU014",
    Programme: "Bachelor of Science in Environmental Science and Management",
    capacity: "210"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU015",
    Programme: "Bachelor of Science in Food Science and Technology",
    capacity: "120"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU016",
    Programme: "Bachelor of Science in Forestry",
    capacity: "150"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU017",
    Programme: "Bachelor of Science in Horticulture",
    capacity: "140"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU018",
    Programme: "Bachelor of Science in Information Technology",
    capacity: "160"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU019",
    Programme: "Bachelor of Science in Range Management",
    capacity: "184"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU020",
    Programme: "Bachelor of Tourism Management",
    capacity: "150"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU021",
    Programme: "Bachelor of Veterinary Medicine",
    capacity: "120"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU022",
    Programme: "Bachelor of Science in Wildlife Management",
    capacity: "120"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU023",
    Programme: "Bachelor of Science in Irrigation & Water Resource Engineering",
    capacity: "65"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU024",
    Programme: "Bachelor of Science in Bio-Processing & Post- Harvest Engineering",
    capacity: "65"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU025",
    Programme: "Bachelor of Science with Education (Agricultural Sciences and Biology)",
    capacity: "200"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU026",
    Programme: "Bachelor of Science in Human Nutrition",
    capacity: "140"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU027",
    Programme: "Bachelor of Science in Family and Consumer Studies",
    capacity: "100"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU029",
    Programme: "Bachelor of Information and Records Management",
    capacity: "100"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU030",
    Programme: "Bachelor of Community Development",
    capacity: "300"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU031",
    Programme: "Bachelor of Agriculture Investment and Banking",
    capacity: "100"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU032",
    Programme: "Bachelor of Crop production and Management",
    capacity: "80"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU034",
    Programme: "Bachelor of Arts in Development Planning and Management",
    capacity: "100"
  },
  {
    Institution_code: "SU",
    Institution_id: "173",
    ProgrammeCode: "SU035",
    Programme: "Bachelor of Science in Wood Technologies and Value Addition",
    capacity: "40"
  },
  {
    Institution_code: "SUM",
    Institution_id: "127",
    ProgrammeCode: "SUM01",
    Programme: "Bachelor Education of Arts with",
    capacity: "400"
  },
  {
    Institution_code: "SUM",
    Institution_id: "127",
    ProgrammeCode: "SUM02",
    Programme: "Bachelor Education of Arts with",
    capacity: "180"
  },
  {
    Institution_code: "SUM",
    Institution_id: "127",
    ProgrammeCode: "SUM03",
    Programme: "Bachelor of Arts in Counseling Psychology",
    capacity: "70"
  },
  {
    Institution_code: "SUM",
    Institution_id: "127",
    ProgrammeCode: "SUM04",
    Programme: "Bachelor of Science in Information Technology",
    capacity: "100"
  },
  {
    Institution_code: "SUP",
    Institution_id: "174",
    ProgrammeCode: "SUP01",
    Programme: "Bachelor of Science in Bee Resources Management",
    capacity: "80"
  },
  {
    Institution_code: "SW",
    Institution_id: "150",
    ProgrammeCode: "SW001",
    Programme: "Bachelor Degree in Labour Relations and Public Management",
    capacity: "150"
  },
  {
    Institution_code: "SW",
    Institution_id: "150",
    ProgrammeCode: "SW003",
    Programme: "Bachelor Degree in Social Work",
    capacity: "150"
  },
  {
    Institution_code: "SW",
    Institution_id: "150",
    ProgrammeCode: "SW005",
    Programme: "Bachelor Degree in Human Resource Management",
    capacity: "150"
  },
  {
    Institution_code: "SW",
    Institution_id: "150",
    ProgrammeCode: "SW008",
    Programme: "Bachelor of Business Administration",
    capacity: "150"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ001",
    Programme: "Doctor of Medicine",
    capacity: "30"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ002",
    Programme: "Bachelor of Science with Education",
    capacity: "80"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ003",
    Programme: "Bachelor of IT Application & Management",
    capacity: "40"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ004",
    Programme: "Bachelor of Science in Environmental Health",
    capacity: "45"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ005",
    Programme: "Bachelor of Science in Computer Science",
    capacity: "30"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ006",
    Programme: "Bachelor of Arts with Education",
    capacity: "200"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ007",
    Programme: "Bachelor of Kiswahili with Education",
    capacity: "40"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ008",
    Programme: "Bachelor Degree in Accounting and Finance",
    capacity: "110"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ009",
    Programme: "Bachelor of Banking and Finance",
    capacity: "50"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ010",
    Programme: "Bachelor Degree in Procurement and Supply",
    capacity: "30"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ011",
    Programme: "Bachelor Degree in Information Technology with Accounting",
    capacity: "50"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ012",
    Programme: "Bachelor of Arts in History and Archaeology",
    capacity: "20"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ013",
    Programme: "Bachelor of Arts in Geography & Environmental Studies",
    capacity: "50"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ014",
    Programme: "Bachelor of Arts in Tourism Management and Marketing",
    capacity: "50"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ015",
    Programme: "Bachelor of Science in Nursing",
    capacity: "40"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ016",
    Programme: "Bachelor of Science in Agriculture General",
    capacity: "15"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ017",
    Programme: "Doctor of Dental Surgery",
    capacity: "8"
  },
  {
    Institution_code: "SZ",
    Institution_id: "180",
    ProgrammeCode: "SZ018",
    Programme: "Bachelor of Entrepreneurship and Innovation",
    capacity: "30"
  },
  {
    Institution_code: "TA",
    Institution_id: "183",
    ProgrammeCode: "TA001",
    Programme: "Bachelor Degree in Accounting",
    capacity: "500"
  },
  {
    Institution_code: "TA",
    Institution_id: "183",
    ProgrammeCode: "TA002",
    Programme: "Bachelor Degree in Procurement and Logistics Management",
    capacity: "500"
  },
  {
    Institution_code: "TA",
    Institution_id: "183",
    ProgrammeCode: "TA003",
    Programme: "Bachelor Degree in Human Resource Management",
    capacity: "400"
  },
  {
    Institution_code: "TA",
    Institution_id: "183",
    ProgrammeCode: "TA004",
    Programme: "Bachelor Degree in Business Administration",
    capacity: "400"
  },
  {
    Institution_code: "TA",
    Institution_id: "183",
    ProgrammeCode: "TA005",
    Programme: "Bachelor Degree in Public Sector Accounting and Finance",
    capacity: "300"
  },
  {
    Institution_code: "TA",
    Institution_id: "183",
    ProgrammeCode: "TA006",
    Programme: "Bachelor Degree in Marketing and Public Relations",
    capacity: "400"
  },
  {
    Institution_code: "TAM",
    Institution_id: "184",
    ProgrammeCode: "TAM01",
    Programme: "Bachelor Degree in Accounting",
    capacity: "300"
  },
  {
    Institution_code: "TAM",
    Institution_id: "184",
    ProgrammeCode: "TAM02",
    Programme: "Bachelor Degree in Procurement and Logistics Management",
    capacity: "300"
  },
  {
    Institution_code: "TAM",
    Institution_id: "184",
    ProgrammeCode: "TAM03",
    Programme: "Bachelor Degree in Marketing and Public Relations",
    capacity: "150"
  },
  {
    Institution_code: "TAM",
    Institution_id: "184",
    ProgrammeCode: "TAM04",
    Programme: "Bachelor Degree in Public Sector Accounting and Finance",
    capacity: "100"
  },
  {
    Institution_code: "TAMZ",
    Institution_id: "185",
    ProgrammeCode: "TAMZ1",
    Programme: "Bachelor Degree in Accounting",
    capacity: "100"
  },
  {
    Institution_code: "TAMZ",
    Institution_id: "185",
    ProgrammeCode: "TAMZ2",
    Programme: "Bachelor Degree in Procurement and Logistic Management",
    capacity: "100"
  },
  {
    Institution_code: "TAS",
    Institution_id: "186",
    ProgrammeCode: "TAS01",
    Programme: "Bachelor Degree in Accounting",
    capacity: "300"
  },
  {
    Institution_code: "TAS",
    Institution_id: "186",
    ProgrammeCode: "TAS02",
    Programme: "Bachelor Degree in Business Administration",
    capacity: "200"
  },
  {
    Institution_code: "TAS",
    Institution_id: "186",
    ProgrammeCode: "TAS03",
    Programme: "Bachelor Degree in Procurement and Logistic Management",
    capacity: "300"
  },
  {
    Institution_code: "TAS",
    Institution_id: "186",
    ProgrammeCode: "TAS04",
    Programme: "Bachelor Degree in Human Resource Management",
    capacity: "200"
  },
  {
    Institution_code: "TCD",
    Institution_id: "163",
    ProgrammeCode: "TCD01",
    Programme: "Bachelor of Arts in Governance and Development",
    capacity: "50"
  },
  {
    Institution_code: "TD",
    Institution_id: "192",
    ProgrammeCode: "TD001",
    Programme: "Bachelor of Arts in Mass Communication",
    capacity: "150"
  },
  {
    Institution_code: "TD",
    Institution_id: "192",
    ProgrammeCode: "TD002",
    Programme: "Bachelor of Laws",
    capacity: "120"
  },
  {
    Institution_code: "TD",
    Institution_id: "192",
    ProgrammeCode: "TD003",
    Programme: "Bachelor of Arts in Library and Information Studies",
    capacity: "70"
  },
  {
    Institution_code: "TD",
    Institution_id: "192",
    ProgrammeCode: "TD004",
    Programme: "Bachelor of Business Administration",
    capacity: "100"
  },
  {
    Institution_code: "TD",
    Institution_id: "192",
    ProgrammeCode: "TD005",
    Programme: "Bachelor of Information Management",
    capacity: "60"
  },
  {
    Institution_code: "TD",
    Institution_id: "192",
    ProgrammeCode: "TD006",
    Programme: "Bachelor of Arts with Education",
    capacity: "240"
  },
  {
    Institution_code: "TD",
    Institution_id: "192",
    ProgrammeCode: "TD007",
    Programme: "Bachelor of Human Resources Management",
    capacity: "100"
  },
  {
    Institution_code: "TD",
    Institution_id: "192",
    ProgrammeCode: "TD008",
    Programme: "Bachelor of Laws (Evening Session)",
    capacity: "120"
  },
  {
    Institution_code: "TK",
    Institution_id: "189",
    ProgrammeCode: "TK002",
    Programme: "Bachelor of Arts with Education",
    capacity: "700"
  },
  {
    Institution_code: "TK",
    Institution_id: "189",
    ProgrammeCode: "TK003",
    Programme: "Bachelor of Education Languages",
    capacity: "340"
  },
  {
    Institution_code: "TK",
    Institution_id: "189",
    ProgrammeCode: "TK010",
    Programme: "Bachelor of Divinity",
    capacity: "35"
  },
  {
    Institution_code: "TK",
    Institution_id: "189",
    ProgrammeCode: "TK016",
    Programme: "Bachelor of Science with Education",
    capacity: "340"
  },
  {
    Institution_code: "TM",
    Institution_id: "193",
    ProgrammeCode: "MK001",
    Programme: "Bachelor of Arts with Education",
    capacity: "600"
  },
  {
    Institution_code: "TM",
    Institution_id: "193",
    ProgrammeCode: "MK002",
    Programme: "Bachelor of Laws",
    capacity: "400"
  },
  {
    Institution_code: "TM",
    Institution_id: "193",
    ProgrammeCode: "MK005",
    Programme: "Bachelor of Divinity",
    capacity: "20"
  },
  {
    Institution_code: "TM",
    Institution_id: "193",
    ProgrammeCode: "MK007",
    Programme: "Bachelor of Education",
    capacity: "300"
  },
  {
    Institution_code: "TM",
    Institution_id: "193",
    ProgrammeCode: "MK008",
    Programme: "Bachelor of Counselling",
    capacity: "100"
  },
  {
    Institution_code: "TM",
    Institution_id: "193",
    ProgrammeCode: "MK011",
    Programme: "Bachelor of Education in Primary Education",
    capacity: "200"
  },
  {
    Institution_code: "TM",
    Institution_id: "193",
    ProgrammeCode: "MK012",
    Programme: "Bachelor of Education in Early Childhood Education",
    capacity: "200"
  },
  {
    Institution_code: "TM",
    Institution_id: "193",
    ProgrammeCode: "MK013",
    Programme: "Bachelor of Education in Mathematics",
    capacity: "100"
  },
  {
    Institution_code: "TPSD",
    Institution_id: "187",
    ProgrammeCode: "TPSD1",
    Programme: "Bachelor Degree in Records, Archives and Information Management",
    capacity: "50"
  },
  {
    Institution_code: "TPSD",
    Institution_id: "187",
    ProgrammeCode: "TPSD2",
    Programme: "Bachelor Degree in Secretarial Studies and Administration",
    capacity: "100"
  },
  {
    Institution_code: "UA",
    Institution_id: "204",
    ProgrammeCode: "UA001",
    Programme: "Bachelor of Arts with Education",
    capacity: "100"
  },
  {
    Institution_code: "UA",
    Institution_id: "204",
    ProgrammeCode: "UA002",
    Programme: "Bachelor of Business (Accounting)",
    capacity: "100"
  },
  {
    Institution_code: "UA",
    Institution_id: "204",
    ProgrammeCode: "UA007",
    Programme: "Bachelor of Arts in Theology",
    capacity: "55"
  },
  {
    Institution_code: "UA",
    Institution_id: "204",
    ProgrammeCode: "UA008",
    Programme: "Bachelor of Arts in Religious Studies",
    capacity: "55"
  },
  {
    Institution_code: "UAD",
    Institution_id: "194",
    ProgrammeCode: "UAD01",
    Programme: "Bachelor Degree in Information Technology",
    capacity: "80"
  },
  {
    Institution_code: "UAD",
    Institution_id: "194",
    ProgrammeCode: "UAD02",
    Programme: "Bachelor Degree in Hardware and Networking Technology",
    capacity: "80"
  },
  {
    Institution_code: "UAD",
    Institution_id: "194",
    ProgrammeCode: "UAD03",
    Programme: "Bachelor Degree in Information Security",
    capacity: "80"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD001",
    Programme: "Bachelor of Arts in Archaeology",
    capacity: "52"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD002",
    Programme: "Bachelor of Arts in Economics",
    capacity: "270"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD003",
    Programme: "Bachelor of Arts in Archaeology and History",
    capacity: "57"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD004",
    Programme: "Bachelor of Arts in Geography and Environmental Studies",
    capacity: "238"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD005",
    Programme: "Bachelor of Arts in Heritage Management",
    capacity: "95"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD006",
    Programme: "Bachelor of Arts in History",
    capacity: "100"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD007",
    Programme: "Bachelor of Arts in Language Studies",
    capacity: "100"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD008",
    Programme: "Bachelor of Arts in Literature",
    capacity: "95"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD009",
    Programme: "Bachelor of Arts in Political Science and Public Administration",
    capacity: "317"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD010",
    Programme: "Bachelor of Arts in Sociology",
    capacity: "198"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD011",
    Programme: "Bachelor of Arts in Statistics",
    capacity: "176"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD012",
    Programme: "Bachelor of Arts with Education",
    capacity: "1386"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD013",
    Programme: "Bachelor of Science in Chemical and Process Engineering",
    capacity: "60"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD014",
    Programme: "Bachelor of Science in Computer Engineering and Information Technology",
    capacity: "63"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD015",
    Programme: "Bachelor of Science in Electrical Engineering",
    capacity: "67"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD016",
    Programme: "Bachelor of Science in Mining Engineering",
    capacity: "40"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD017",
    Programme: "Bachelor of Science in Textile Engineering",
    capacity: "40"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD018",
    Programme: "Bachelor of Science in Textile Design and Technology",
    capacity: "35"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD019",
    Programme: "Bachelor of Science in Telecommunications Engineering",
    capacity: "67"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD021",
    Programme: "Bachelor of Science in Actuarial Sciences",
    capacity: "60"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD022",
    Programme: "Bachelor of Science in Applied Zoology",
    capacity: "50"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD023",
    Programme: "Bachelor of Arts in History and Political Science",
    capacity: "150"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD024",
    Programme: "Bachelor of Science in Botanical Sciences",
    capacity: "60"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD025",
    Programme: "Bachelor of Science in Chemistry",
    capacity: "60"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD026",
    Programme: "Bachelor of Science in Engineering Geology",
    capacity: "14"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD028",
    Programme: "Bachelor of Science in Geology",
    capacity: "23"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD029",
    Programme: "Bachelor of Science in Microbiology",
    capacity: "60"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD030",
    Programme: "Bachelor of Science in Molecular Biology and Biotechnology",
    capacity: "60"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD031",
    Programme: "Bachelor of Science in Wildlife Science and Conservation",
    capacity: "50"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD032",
    Programme: "Bachelor of Science with Education",
    capacity: "350"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD033",
    Programme: "Bachelor of Science with Geology",
    capacity: "33"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD034",
    Programme: "Bachelor of Science in Computer Science",
    capacity: "113"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD035",
    Programme: "Bachelor of Science in Electronic Science and Communication",
    capacity: "53"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD037",
    Programme: "Bachelor of Arts in Journalism",
    capacity: "45"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD038",
    Programme: "Bachelor of Arts in Mass Communication",
    capacity: "51"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD039",
    Programme: "Bachelor of Arts in Public Relations and Advertising",
    capacity: "59"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD040",
    Programme: "Bachelor of Arts in Kiswahili",
    capacity: "200"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD041",
    Programme: "Bachelor of Commerce in Accounting",
    capacity: "285"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD042",
    Programme: "Bachelor of Commerce in Banking and Financial Services",
    capacity: "114"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD043",
    Programme: "Bachelor of Commerce in Finance",
    capacity: "119"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD044",
    Programme: "Bachelor of Commerce in Human Resource Management",
    capacity: "92"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD045",
    Programme: "Bachelor of Commerce in Marketing",
    capacity: "114"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD046",
    Programme: "Bachelor of Commerce in Tourism Management",
    capacity: "69"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD047",
    Programme: "Bachelor of Education in Adult and Community Education",
    capacity: "100"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD048",
    Programme: "Bachelor of Education in Commerce",
    capacity: "80"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD049",
    Programme: "Bachelor of Education in Early Childhood Education",
    capacity: "80"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD050",
    Programme: "Bachelor of Education in Physical Education and Sports Sciences",
    capacity: "80"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD051",
    Programme: "Bachelor of Education in Psychology",
    capacity: "120"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD052",
    Programme: "Bachelor of Arts in Law Enforcement",
    capacity: "40"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD053",
    Programme: "Bachelor of Laws",
    capacity: "150"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD054",
    Programme: "Bachelor of Arts in Economics and Statistics",
    capacity: "168"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD055",
    Programme: "Bachelor of Science in Mechanical Engineering",
    capacity: "70"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD056",
    Programme: "Bachelor of Science in Industrial Engineering",
    capacity: "50"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD057",
    Programme: "Bachelor of Science in Metallurgy and Mineral Processing Engineering",
    capacity: "29"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD058",
    Programme: "Bachelor of Science in Civil Engineering",
    capacity: "213"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD059",
    Programme: "Bachelor of Science in Petroleum Geology",
    capacity: "15"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD060",
    Programme: "Bachelor of Science in Petroleum Engineering",
    capacity: "24"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD061",
    Programme: "Bachelor of Science in Petroleum Chemistry",
    capacity: "50"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD062",
    Programme: "Bachelor of Science in Meteorology",
    capacity: "50"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD063",
    Programme: "Bachelor of Architecture",
    capacity: "30"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD064",
    Programme: "Bachelor of Arts in Library Information Studies",
    capacity: "63"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD065",
    Programme: "Bachelor of Science in Aquatic Sciences and Fisheries",
    capacity: "65"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD066",
    Programme: "Bachelor of Social Work",
    capacity: "121"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD067",
    Programme: "Bachelor of Arts in Psychology",
    capacity: "52"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD068",
    Programme: "Bachelor of Arts in Anthropology",
    capacity: "79"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD069",
    Programme: "Bachelor of Arts in Philosophy and Ethics",
    capacity: "150"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD070",
    Programme: "Bachelor of Arts in Development Studies",
    capacity: "102"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD071",
    Programme: "Bachelor of Arts in Art and Design",
    capacity: "100"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD072",
    Programme: "Bachelor of Arts in Film and Television Arts",
    capacity: "70"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD073",
    Programme: "Bachelor of Arts in Music",
    capacity: "70"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD074",
    Programme: "Bachelor of Science in Food Science and Technology",
    capacity: "46"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD075",
    Programme: "Bachelor of Science in Bee Keeping Science and Technology",
    capacity: "60"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD076",
    Programme: "Bachelor of Science in Agricultural Engineering and Mechanisation",
    capacity: "57"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD077",
    Programme: "Bachelor of Science in Quantity Surveying",
    capacity: "50"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD078",
    Programme: "Bachelor of Science in Agricultural and Natural Resources Economics and Business",
    capacity: "150"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD079",
    Programme: "Bachelor of Science in Geomatics",
    capacity: "29"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD080",
    Programme: "Doctor of Medicine",
    capacity: "152"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD081",
    Programme: "Bachelor of Arts in Theatre Arts",
    capacity: "70"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD082",
    Programme: "Bachelor of Business Administration (Evening)",
    capacity: "192"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD083",
    Programme: "Bachelor of Science in Crop Science and Technology",
    capacity: "45"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD084",
    Programme: "Bachelor of Arts in Archaeology and Geography",
    capacity: "95"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD085",
    Programme: "Bachelor of Arts with Education (Chinese and English)",
    capacity: "120"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD086",
    Programme: "Bachelor of Science in Electronics Engineering",
    capacity: "57"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD087",
    Programme: "Bachelor of Science in Marine Sciences",
    capacity: "45"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD088",
    Programme: "Bachelor of Arts in Diplomatic and Military History",
    capacity: "75"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD089",
    Programme: "Bachelor of Science in Mathematics and Statistics",
    capacity: "60"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD090",
    Programme: "Bachelor of Science in Business Information Technology",
    capacity: "90"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD091",
    Programme: "Bachelor of Science in Geophysics",
    capacity: "10"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD092",
    Programme: "Bachelor of Science in Geology and Geothermal Resources",
    capacity: "10"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD093",
    Programme: "Bachelor of Arts in Communication Studies",
    capacity: "95"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD094",
    Programme: "Bachelor of Arts in History, Cultural Heritage Management and Tourism",
    capacity: "100"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD095",
    Programme: "Bachelor of Science in Physics and Chemistry",
    capacity: "50"
  },
  {
    Institution_code: "UD",
    Institution_id: "196",
    ProgrammeCode: "UD096",
    Programme: "Bachelor of Science in Applied Microbiology and Chemistry",
    capacity: "60"
  },
  {
    Institution_code: "UDD",
    Institution_id: "140",
    ProgrammeCode: "UDD01",
    Programme: "Bachelor of Education in Arts",
    capacity: "200"
  },
  {
    Institution_code: "UDD",
    Institution_id: "140",
    ProgrammeCode: "UDD02",
    Programme: "Bachelor of Education in Science",
    capacity: "200"
  },
  {
    Institution_code: "UDD",
    Institution_id: "140",
    ProgrammeCode: "UDD03",
    Programme: "Bachelor of Science with Education",
    capacity: "600"
  },
  {
    Institution_code: "UDD",
    Institution_id: "140",
    ProgrammeCode: "UDD04",
    Programme: "Bachelor of Arts with Education",
    capacity: "1450"
  },
  {
    Institution_code: "UDD",
    Institution_id: "140",
    ProgrammeCode: "UDD05",
    Programme: "Bachelor of Arts in Disaster Risk Management",
    capacity: "25"
  },
  {
    Institution_code: "UDM",
    Institution_id: "161",
    ProgrammeCode: "UDM01",
    Programme: "Bachelor of Education in Science",
    capacity: "650"
  },
  {
    Institution_code: "UDM",
    Institution_id: "161",
    ProgrammeCode: "UDM02",
    Programme: "Bachelor of Education in Arts",
    capacity: "650"
  },
  {
    Institution_code: "UDM",
    Institution_id: "161",
    ProgrammeCode: "UDM03",
    Programme: "Bachelor of Science with Education",
    capacity: "600"
  },
  {
    Institution_code: "UDM",
    Institution_id: "161",
    ProgrammeCode: "UDM04",
    Programme: "Bachelor of Arts with Education",
    capacity: "850"
  },
  {
    Institution_code: "UDM",
    Institution_id: "161",
    ProgrammeCode: "UDM05",
    Programme: "Bachelor of Science in Chemistry",
    capacity: "40"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI001",
    Programme: "Bachelor of Arts in Journalism",
    capacity: "150"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI002",
    Programme: "Bachelor of Laws",
    capacity: "300"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI003",
    Programme: "Bachelor of Arts in Cultural Anthropology & Tourism",
    capacity: "150"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI004",
    Programme: "Bachelor of Counselling Psychology",
    capacity: "150"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI005",
    Programme: "Bachelor of Education (Mathematics)",
    capacity: "100"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI006",
    Programme: "Bachelor of Arts in Community Development",
    capacity: "300"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI007",
    Programme: "Bachelor of Business Administration",
    capacity: "100"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI008",
    Programme: "Bachelor of Theology",
    capacity: "100"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI010",
    Programme: "Bachelor of Accounting and Finance",
    capacity: "150"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI011",
    Programme: "Bachelor of Science in Economics & Finance",
    capacity: "150"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI012",
    Programme: "Bachelor of Education (Arts)",
    capacity: "600"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI013",
    Programme: "Bachelor of Business in Marketing Management",
    capacity: "200"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI014",
    Programme: "Bachelor of Applied Marketing & Entrepreneurship",
    capacity: "200"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI015",
    Programme: "Bachelor of Human Resource Management",
    capacity: "200"
  },
  {
    Institution_code: "UI",
    Institution_id: "198",
    ProgrammeCode: "UI016",
    Programme: "Bachelor of Business in Procurement and Supply Chain Management",
    capacity: "200"
  },
  {
    Institution_code: "UN",
    Institution_id: "195",
    ProgrammeCode: "UN001",
    Programme: "Bachelor of Science in Computer Engineering and Information Technology",
    capacity: "50"
  },
  {
    Institution_code: "UN",
    Institution_id: "195",
    ProgrammeCode: "UN002",
    Programme: "Bachelor of Business Administration",
    capacity: "50"
  },
  {
    Institution_code: "WD",
    Institution_id: "199",
    ProgrammeCode: "WD001",
    Programme: "Bachelor Degree in Water Resources and Irrigation Engineering",
    capacity: "135"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU001",
    Programme: "Bachelor of Business Administration in Accounting & Finance",
    capacity: "100"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU002",
    Programme: "Bachelor of Procurement and Logistics Management",
    capacity: "60"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU003",
    Programme: "Bachelor of Business Administration in Marketing",
    capacity: "100"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU004",
    Programme: "Bachelor of Science in Business Information Technology",
    capacity: "60"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU005",
    Programme: "Bachelor of Arts in Economics",
    capacity: "75"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU006",
    Programme: "Bachelor in Islamic Banking & Finance",
    capacity: "75"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU007",
    Programme: "Bachelor of Arts in Languages",
    capacity: "100"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU008",
    Programme: "Bachelor of Arts in Public Administration",
    capacity: "50"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU012",
    Programme: "Bachelor of Science in Computer Engineering and Information Technology",
    capacity: "40"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU013",
    Programme: "Bachelor of Science in Telecommunications Engineering",
    capacity: "40"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU015",
    Programme: "Bachelor of Information Studies",
    capacity: "100"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU017",
    Programme: "Bachelor of International Relationsand Diplomacy",
    capacity: "100"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU018",
    Programme: "Bachelor of Science in Counseling Psychology",
    capacity: "50"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU019",
    Programme: "Bachelor of Mass Communication",
    capacity: "60"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU020",
    Programme: "Bachelor of Laws",
    capacity: "120"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU021",
    Programme: "Bachelor of Social work",
    capacity: "80"
  },
  {
    Institution_code: "ZU",
    Institution_id: "200",
    ProgrammeCode: "ZU022",
    Programme: "Bachelor of Science in Nursing",
    capacity: "30"
  }
]

export const institutions = [
  {
    id: "127",
    name: "Abdulrahman Al- Sumait University (SUMAIT)",
    description: "Abdulrahman Al- Sumait University (SUMAIT)",
    code: "SUM",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.sumait.ac.tz",
    region_id: "31",
    region_name: "Zanzibar",
    logo: "",
    ownership: "Private"
  },
  {
    id: "128",
    name: "Archbishop Mihayo University College of Tabora (AMUCTA)",
    description: "Archbishop Mihayo University College of Tabora (AMUCTA)",
    code: "AM",
    institution_type: "University College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "http://amucta.ac.tz/",
    region_id: "29",
    region_name: "Tabora",
    logo: "",
    ownership: "Private"
  },
  {
    id: "129",
    name: "Ardhi University (ARU)",
    description: "Ardhi University (ARU)",
    code: "AR",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://aru.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "130",
    name: "Arusha Technical College (ATC)",
    description: "Arusha Technical College (ATC)",
    code: "AT",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.atc.ac.tz/",
    region_id: "1",
    region_name: "Arusha",
    logo: "",
    ownership: "Public"
  },
  {
    id: "131",
    name: "Catholic University College of Mbeya (CUCoM)",
    description: "Catholic University College of Mbeya (CUCoM)",
    code: "CM",
    institution_type: "University College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.sautmbeya.ac.tz",
    region_id: "17",
    region_name: "Mbeya",
    logo: "",
    ownership: "Private"
  },
  {
    id: "132",
    name: "Catholic University of Health and Allied Sciences (CUHAS)",
    description: "Catholic University of Health and Allied Sciences (CUHAS)",
    code: "CU",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://bugando.ac.tz/",
    region_id: "21",
    region_name: "Mwanza",
    logo: "",
    ownership: "Private"
  },
  {
    id: "133",
    name: "Center for Foreign Relations (CFR)",
    description: "Center for Foreign Relations (CFR)",
    code: "CFR",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "http://cfr.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "134",
    name: "College of African Wildlife Management Mweka (CAWM)",
    description: "College of African Wildlife Management Mweka (CAWM)",
    code: "WM",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "mwekawildlife.ac.tz",
    region_id: "11",
    region_name: "Kilimanjaro",
    logo: "",
    ownership: "Public"
  },
  {
    id: "135",
    name: "College of Business Education (CBE) Dar es Salaam",
    description: "College of Business Education (CBE) Dar es Salaam",
    code: "CBD",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.cbe.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "136",
    name: "College of Business Education (CBE) Dodoma",
    description: "College of Business Education (CBE) Dodoma",
    code: "CBM",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.cbe.ac.tz/",
    region_id: "3",
    region_name: "Dodoma",
    logo: "",
    ownership: "Public"
  },
  {
    id: "137",
    name: "College of Business Education (CBE) Mwanza",
    description: "College of Business Education (CBE) Mwanza",
    code: "CBMZ",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://mwanza.cbe.ac.tz/",
    region_id: "21",
    region_name: "Mwanza",
    logo: "",
    ownership: "Public"
  },
  {
    id: "138",
    name: "Dar es Salaam Institute of Technology (DIT)",
    description: "Dar es Salaam Institute of Technology (DIT)",
    code: "DT",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.dit.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "139",
    name: "Dar es Salaam Maritime Institute (DMI)",
    description: "Dar es Salaam Maritime Institute (DMI)",
    code: "DMI",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "http://www.dmi.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "140",
    name: "Dar es Salaam University College of Education (DUCE)",
    description: "Dar es Salaam University College of Education (DUCE)",
    code: "UDD",
    institution_type: "University College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.udsm.ac.tz",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "141",
    name: "Eastern Africa Statistical Training Centre (EASTC)",
    description: "Eastern Africa Statistical Training Centre (EASTC)",
    code: "EA",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.eastc.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "142",
    name: "Eastern and Southern African Management Institute (ESAMI)",
    description: "Eastern and Southern African Management Institute (ESAMI)",
    code: "ESA",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://esami-africa.org/",
    region_id: "1",
    region_name: "Arusha",
    logo: "",
    ownership: "Public"
  },
  {
    id: "143",
    name: "Hubert Kairuki Memorial University (HKMU)",
    description: "Hubert Kairuki Memorial University (HKMU)",
    code: "HK",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "http://www.hkmu.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Private"
  },
  {
    id: "144",
    name: "Institute of Accountancy Arusha (IAA)",
    description: "Institute of Accountancy Arusha (IAA)",
    code: "IA",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://iaa.ac.tz/",
    region_id: "1",
    region_name: "Arusha",
    logo: "",
    ownership: "Public"
  },
  {
    id: "145",
    name: "Institute of Adult Education (IAE)",
    description: "Institute of Adult Education (IAE)",
    code: "AE",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://iae.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "146",
    name: "Institute of Finance Management (IFM)",
    description: "Institute of Finance Management (IFM)",
    code: "IF",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.ifm.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "147",
    name: "Institute of Finance Management (IFM)- Mwanza",
    description: "Institute of Finance Management (IFM)- Mwanza",
    code: "IFM",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.ifm.ac.tz/78-template-details/design-a-features/174-mwanza-campus",
    region_id: "21",
    region_name: "Mwanza",
    logo: "",
    ownership: "Public"
  },
  {
    id: "148",
    name: "Institute of Rural Development Planning (IRDP)",
    description: "Institute of Rural Development Planning (IRDP)",
    code: "RD",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.irdp.ac.tz/",
    region_id: "3",
    region_name: "Dodoma",
    logo: "",
    ownership: "Public"
  },
  {
    id: "149",
    name: "Institute of Rural Development Planning (IRDP)-Mwanza",
    description: "Institute of Rural Development Planning (IRDP)-Mwanza",
    code: "RDM",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.irdp.ac.tz/",
    region_id: "21",
    region_name: "Mwanza",
    logo: "",
    ownership: "Public"
  },
  {
    id: "150",
    name: "Institute of Social Work (ISW)",
    description: "Institute of Social Work (ISW)",
    code: "SW",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.isw.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "151",
    name: "Institute of Tax Administration (ITA)",
    description: "Institute of Tax Administration (ITA)",
    code: "IT",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.ita.ac.tz",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "152",
    name: "Jordan University College (JUCo)",
    description: "Jordan University College (JUCo)",
    code: "JC",
    institution_type: "University College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "http://www.juco.ac.tz/",
    region_id: "19",
    region_name: "Morogoro",
    logo: "",
    ownership: "Private"
  },
  {
    id: "153",
    name: "Kampala International University in Tanzania (KIUT)",
    description: "Kampala International University in Tanzania (KIUT)",
    code: "KUC",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://kiut.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Private"
  },
  {
    id: "154",
    name: "Karume Institute of Science and Technology (KIST)",
    description: "Karume Institute of Science and Technology (KIST)",
    code: "KIS",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://kist.ac.tz/",
    region_id: "31",
    region_name: "Zanzibar",
    logo: "",
    ownership: "Public"
  },
  {
    id: "155",
    name: "Kilimanjaro Christian Medical University College (KCMUCo)",
    description: "Kilimanjaro Christian Medical University College (KCMUCo)",
    code: "KC",
    institution_type: "University College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://kcmuco.ac.tz/",
    region_id: "11",
    region_name: "Kilimanjaro",
    logo: "",
    ownership: "Private"
  },
  {
    id: "156",
    name: "Local Government Training Institute (LGTI)",
    description: "Local Government Training Institute (LGTI)",
    code: "LGTI",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.lgti.ac.tz/",
    region_id: "3",
    region_name: "Dodoma",
    logo: "",
    ownership: "Public"
  },
  {
    id: "157",
    name: "Marian University College (MARUCo)",
    description: "Marian University College (MARUCo)",
    code: "MAR",
    institution_type: "University College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "http://maruco.ac.tz/",
    region_id: "32",
    region_name: "Bagamoyo",
    logo: "",
    ownership: "Private"
  },
  {
    id: "158",
    name: "Mbeya University College of Health and Allied Sciences (MUCHAS)",
    description: "Mbeya University College of Health and Allied Sciences (MUCHAS)",
    code: "MCH",
    institution_type: "University College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://udsm.ac.tz/web/index.php/colleges/mchas",
    region_id: "17",
    region_name: "Mbeya",
    logo: "",
    ownership: "Public"
  },
  {
    id: "159",
    name: "Mbeya University of Science and Technology (MUST)",
    description: "Mbeya University of Science and Technology (MUST)",
    code: "MB",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://must.ac.tz/",
    region_id: "17",
    region_name: "Mbeya",
    logo: "",
    ownership: "Public"
  },
  {
    id: "160",
    name: "Mbeya University of Science and Technology - Rukwa Campus College",
    description: "Mbeya University of Science and Technology Rukwa Campus College",
    code: "MBR",
    institution_type: "University Campus College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://must.ac.tz/",
    region_id: "24",
    region_name: "Rukwa",
    logo: "",
    ownership: "Public"
  },
  {
    id: "161",
    name: "Mkwawa University College of Education (MUCE)",
    description: "Mkwawa University College of Education (MUCE)",
    code: "UDM",
    institution_type: "University College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.muce.ac.tz/",
    region_id: "5",
    region_name: "Iringa",
    logo: "",
    ownership: "Public"
  },
  {
    id: "162",
    name: "Moshi Cooperative University (MoCU)",
    description: "Moshi Cooperative University (MoCU)",
    code: "MC",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://mocu.ac.tz/",
    region_id: "11",
    region_name: "Kilimanjaro",
    logo: "",
    ownership: "Public"
  },
  {
    id: "163",
    name: "MS Training Centre for Development Cooperation (MS-TCDC)",
    description: "MS Training Centre for Development Cooperation (MS- TCDC)",
    code: "TCD",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://mstcdc.or.tz/",
    region_id: "1",
    region_name: "Arusha",
    logo: "",
    ownership: "Private"
  },
  {
    id: "164",
    name: "Muhimbili University of Health and Allied Sciences (MUHAS)",
    description: "Muhimbili University of Health and Allied Sciences (MUHAS)",
    code: "MH",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.muhas.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "165",
    name: "Muslim University of Morogoro (MUM)",
    description: "Muslim University of Morogoro (MUM)",
    code: "MUM",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "http://www.mum.ac.tz/",
    region_id: "19",
    region_name: "Morogoro",
    logo: "",
    ownership: "Private"
  },
  {
    id: "166",
    name: "Mwenge Catholic University (MWECAU)",
    description: "Mwenge Catholic University (MWECAU)",
    code: "MW",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.mwecau.ac.tz/",
    region_id: "11",
    region_name: "Kilimanjaro",
    logo: "",
    ownership: "Private"
  },
  {
    id: "167",
    name: "Mzumbe University (MU)",
    description: "Mzumbe University (MU)",
    code: "MU",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://site.mzumbe.ac.tz/",
    region_id: "19",
    region_name: "Morogoro",
    logo: "",
    ownership: "Public"
  },
  {
    id: "168",
    name: "Mzumbe University Dar es Salaam Campus College (MUDCC)",
    description: "Mzumbe University Dar es Salaam Campus College (MUDCC)",
    code: "MDA",
    institution_type: "University Campus College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://dcc.mzumbe.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "169",
    name: "Mzumbe University Mbeya Campus College (MUMCo)",
    description: "Mzumbe University Mbeya Campus College (MUMCo)",
    code: "MMB",
    institution_type: "University Campus College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://mcc.mzumbe.ac.tz/",
    region_id: "17",
    region_name: "Mbeya",
    logo: "",
    ownership: "Public"
  },
  {
    id: "170",
    name: "National Institute of Transport (NIT)",
    description: "National Institute of Transport (NIT)",
    code: "NT",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://nit.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "171",
    name: "Open University of Tanzania (OUT)",
    description: "Open University of Tanzania (OUT)",
    code: "OU",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.out.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "172",
    name: "Ruaha Catholic University (RUCU)",
    description: "Ruaha Catholic University (RUCU)",
    code: "RU",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.rucu.ac.tz/",
    region_id: "5",
    region_name: "Iringa",
    logo: "",
    ownership: "Private"
  },
  {
    id: "174",
    name: "Sokoine University of Agriculture - Mizengo Pinda Campus College (SUAMPC)",
    description: "Sokoine University of Agriculture - Mizengo Pinda Campus College (SUAMPC)",
    code: "SUP",
    institution_type: "University Campus College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.mizengopinda.sua.ac.tz/",
    region_id: "9",
    region_name: "Katavi",
    logo: "",
    ownership: "Public"
  },
  {
    id: "173",
    name: "Sokoine University of Agriculture (SUA)",
    description: "Sokoine University of Agriculture (SUA)",
    code: "SU",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.sua.ac.tz/",
    region_id: "19",
    region_name: "Morogoro",
    logo: "",
    ownership: "Public"
  },
  {
    id: "175",
    name: "St. Augustine University of Tanzania (SAUT)",
    description: "St. Augustine University of Tanzania (SAUT)",
    code: "SA",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.saut.ac.tz/",
    region_id: "21",
    region_name: "Mwanza",
    logo: "",
    ownership: "Private"
  },
  {
    id: "176",
    name: "St. Augustine University of Tanzania (SAUT) -Arusha Centre",
    description: "St. Augustine University of Tanzania (SAUT) -Arusha Centre",
    code: "SAA",
    institution_type: "University Centre",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://sautdarcentre.ac.tz/",
    region_id: "1",
    region_name: "Arusha",
    logo: "",
    ownership: "Private"
  },
  {
    id: "177",
    name: "St. Francis University College of Health and Allied Sciences (SFUCHAS), ifakara",
    description: "St. Francis University College of Health and Allied Sciences (SFUCHAS)",
    code: "SF",
    institution_type: "University College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://sfuchas.ac.tz/",
    region_id: "33",
    region_name: "Ifakara",
    logo: "",
    ownership: "Private"
  },
  {
    id: "178",
    name: "St. John's University of Tanzania (SJUT)",
    description: "St. John's University of Tanzania (SJUT)",
    code: "SJ",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.sjut.ac.tz/",
    region_id: "3",
    region_name: "Dodoma",
    logo: "",
    ownership: "Private"
  },
  {
    id: "179",
    name: "St. Joseph University College of Engineering and Technology (SJUCET)",
    description: "St. Joseph University College of Engineering and Technology (SJUCET)",
    code: "JD",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.sjuit.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Private"
  },
  {
    id: "180",
    name: "State University of Zanzibar (SUZA)",
    description: "State University of Zanzibar (SUZA)",
    code: "SZ",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.suza.ac.tz/",
    region_id: "31",
    region_name: "Zanzibar",
    logo: "",
    ownership: "Public"
  },
  {
    id: "181",
    name: "Stefano Moshi Memorial University College (SMMUCo)",
    description: "Stefano Moshi Memorial University College (SMMUCo)",
    code: "SM",
    institution_type: "University College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "http://smmuco.ac.tz/",
    region_id: "34",
    region_name: "Moshi",
    logo: "",
    ownership: "Private"
  },
  {
    id: "182",
    name: "Stella Maris Mtwara University College (SteMMUCo)",
    description: "Stella Maris Mtwara University College (SteMMUCo)",
    code: "SAM",
    institution_type: "University College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://stemmuco.ac.tz/",
    region_id: "20",
    region_name: "Mtwara",
    logo: "",
    ownership: "Private"
  },
  {
    id: "183",
    name: "Tanzania Institute of Accountancy (TIA), Dar es Salaam",
    description: "Tanzania Institute of Accountancy (TIA), Dar es Salaam",
    code: "TA",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://tia.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "184",
    name: "Tanzania Institute of Accountancy (TIA), Mbeya",
    description: "Tanzania Institute of Accountancy (TIA), Mbeya",
    code: "TAM",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.tia.ac.tz/category/mwanza-campus/",
    region_id: "17",
    region_name: "Mbeya",
    logo: "",
    ownership: "Public"
  },
  {
    id: "185",
    name: "Tanzania Institute of Accountancy (TIA), Mwanza",
    description: "Tanzania Institute of Accountancy (TIA), Mwanza",
    code: "TAMZ",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://tia.ac.tz/mwanza/",
    region_id: "21",
    region_name: "Mwanza",
    logo: "",
    ownership: "Public"
  },
  {
    id: "186",
    name: "Tanzania Institute of Accountancy (TIA), Singida",
    description: "Tanzania Institute of Accountancy (TIA), Singida",
    code: "TAS",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://tia.ac.tz/singida/",
    region_id: "28",
    region_name: "Singida",
    logo: "",
    ownership: "Public"
  },
  {
    id: "187",
    name: "Tanzania Public Service College Dar es Salaam Campus (TPSD)",
    description: "Tanzania Public Service College Dar es Salaam Campus (TPSD)",
    code: "TPSD",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.tpsc.go.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "188",
    name: "Tengeru Institute of Community Development (TICD)",
    description: "Tengeru Institute of Community Development (TICD)",
    code: "CD",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://ticd.ac.tz",
    region_id: "1",
    region_name: "Arusha",
    logo: "",
    ownership: "Public"
  },
  {
    id: "189",
    name: "Teofilo Kisanji University (TEKU)",
    description: "Teofilo Kisanji University (TEKU)",
    code: "TK",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "http://teku.ac.tz/",
    region_id: "17",
    region_name: "Mbeya",
    logo: "",
    ownership: "Private"
  },
  {
    id: "190",
    name: "The Mwalimu Nyerere Memorial Academy (MNMA)",
    description: "The Mwalimu Nyerere Memorial Academy (MNMA)",
    code: "MN",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.mnma.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "191",
    name: "The Mwalimu Nyerere Memorial Academy (MNMA) - Zanzibar",
    description: "The Mwalimu Nyerere Memorial Academy (MNMA) - Zanzibar",
    code: "MNZ",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.mnma.ac.tz/",
    region_id: "31",
    region_name: "Zanzibar",
    logo: "",
    ownership: "Public"
  },
  {
    id: "192",
    name: "Tumaini University Dar es Salaam College (TUDARCo)",
    description: "Tumaini University Dar es Salaam College (TUDARCo)",
    code: "TD",
    institution_type: "University College",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "http://tudarco.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Private"
  },
  {
    id: "193",
    name: "Tumaini University Makumira (TUMA)",
    description: "Tumaini University Makumira (TUMA)",
    code: "TM",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://makumira.ac.tz",
    region_id: "1",
    region_name: "Arusha",
    logo: "",
    ownership: "Private"
  },
  {
    id: "194",
    name: "Unique Academy Dar es Salaam",
    description: "Unique Academy Dar es Salaam",
    code: "UAD",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.unique.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Private"
  },
  {
    id: "195",
    name: "United African University of Tanzania (UAUT)",
    description: "United African University of Tanzania (UAUT)",
    code: "UN",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.uaut.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Private"
  },
  {
    id: "196",
    name: "University of Dar es Salaam (UDSM)",
    description: "University of Dar es Salaam (UDSM)",
    code: "UD",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.udsm.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "197",
    name: "University of Dodoma (UDOM)",
    description: "University of Dodoma (UDOM)",
    code: "DM",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.udom.ac.tz/",
    region_id: "3",
    region_name: "Dodoma",
    logo: "",
    ownership: "Public"
  },
  {
    id: "198",
    name: "University of Iringa (UoI)",
    description: "University of Iringa (UoI)",
    code: "UI",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "https://www.uoi.ac.tz/",
    region_id: "5",
    region_name: "Iringa",
    logo: "",
    ownership: "Private"
  },
  {
    id: "199",
    name: "Water Institute (WI)",
    description: "Water Institute (WI)",
    code: "WD",
    institution_type: "Non-University",
    institution_type_description: "A campus is traditionally the land on which a college or university and related institutional buildings are situated. Usually a college campus includes libraries, lecture halls, residence halls, student centers or dining halls, and park-like settings.",
    website: "https://www.waterinstitute.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "200",
    name: "Zanzibar University (ZU)",
    description: "Zanzibar University (ZU)",
    code: "ZU",
    institution_type: "University",
    institution_type_description: "A university (Latin: universitas, 'a whole') is an institution of higher (or tertiary) education and research which awards academic degrees in various academic disciplines. Universities typically provide undergraduate education and postgraduate education.",
    website: "http://zanvarsity.ac.tz/",
    region_id: "31",
    region_name: "Zanzibar",
    logo: "",
    ownership: "Private"
  },
  {
    id: "201",
    name: "Institute of Accountancy Arusha (IAA) Dar es Salaam Campus",
    description: "Institute of Accountancy Arusha (IAA) Dar es Salaam Campus",
    code: "IAD",
    institution_type: "Non-University",
    institution_type_description: "",
    website: "https://iaa.ac.tz/dar-es-salaam-campus/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Public"
  },
  {
    id: "202",
    name: "Institute of Public Administration (IPA)",
    description: "Institute of Public Administration (IPA)",
    code: "IPA",
    institution_type: "Non-University",
    institution_type_description: "",
    website: "https://ipa.ac.tz/",
    region_id: "31",
    region_name: "Zanzibar",
    logo: "",
    ownership: "Public"
  },
  {
    id: "203",
    name: "St. Joseph University College of Health and Allied Sciences (SJCHAS)",
    description: "St. Joseph University College of Health and Allied Sciences (SJCHAS)",
    code: "JDH",
    institution_type: "University",
    institution_type_description: "",
    website: "https://sjuit.ac.tz/",
    region_id: "2",
    region_name: "Dar es Salaam",
    logo: "",
    ownership: "Private"
  },
  {
    id: "204",
    name: "University of Arusha (UoA)",
    description: "University of Arusha (UoA)",
    code: "UA",
    institution_type: "University",
    institution_type_description: "",
    website: "https://uoa.ac.tz/index.php",
    region_id: "1",
    region_name: "Arusha",
    logo: "",
    ownership: "Private"
  }

]

export const institutionsWithProgramme = institutions.map(i => ({ ...i, programme: programmes.find(p => parseInt(p.Institution_id) === parseInt(i.id))?.Programme }))
export const institutionsWithProgrammes = institutions.map(i => ({ ...i, programmes:programmes.filter(p => parseInt(p.Institution_id) === parseInt(i.id)),
  totalProgCount:programmes.filter(p => parseInt(p.Institution_id) === parseInt(i.id)).length}))

export const programmesWithInstitutions = [...programmes].map(p => (
    { ...p, Institution_name: institutions.find(i => (parseInt(i.id) === parseInt(p.Institution_id)))?.name }))

export const foundationProgrammes = [
  {
    // programId: '171_1',
    programId: '1',
    description: 'SCIENCE TRACK',
    hostId: 171,
    hostName: 'OPEN UNIVERSITY OF TANZANIA',
  },
  {
    // programId: '171_2',
    programId: '2',
    description: 'ART TRACK',
    hostId: 171,
    hostName: 'OPEN UNIVERSITY OF TANZANIA',
  },
  {
    // programId: '171_2',
    programId: '3',
    description: 'BUSINESS TRACK',
    hostId: 171,
    hostName: 'OPEN UNIVERSITY OF TANZANIA',
  }
]

export const foundationProgOpts = foundationProgrammes.map(fp => {
  return { label: fp.hostName + ' (' + fp.description + ')', value: fp.programId }
})

export const diplomaProgrammes = [
  {
    programId: 'REG/BMG/006_1',
    description: 'Ordinary Diploma in Accountancy',
    hostId: 'REG/BMG/006',
    hostName: 'COLLEGE OF BUSINESS EDUCATION - DODOMA',
  },
  {
    programId: 'REG/BMG/006_2',
    description: 'Ordinary Diploma in Business Administration',
    hostId: 'REG/BMG/006',
    hostName: 'COLLEGE OF BUSINESS EDUCATION - DODOMA',
  },
  {
    programId: 'REG/BMG/006_3',
    description: 'Ordinary Diploma in Human Resource Management',
    hostId: 'REG/BMG/006',
    hostName: 'COLLEGE OF BUSINESS EDUCATION - DODOMA',
  },
  {
    programId: 'REG/BMG/006_4',
    description: 'Ordinary Diploma in Computing and InformationTechnology',
    hostId: 'REG/BMG/006',
    hostName: 'COLLEGE OF BUSINESS EDUCATION - DODOMA',
  },
  {
    programId: 'REG/BMG/006_5',
    description: 'Ordinary Diploma in Marketing Management',
    hostId: 'REG/BMG/006',
    hostName: 'COLLEGE OF BUSINESS EDUCATION - DODOMA',
  },
  {
    programId: 'REG/EOS/001_1',
    description: 'Ordinary Diploma in Bio-medical EquipmentEngineering',
    hostId: 'REG/EOS/001',
    hostName: 'DAR ES SALAAM INSTITUTE OF TECHNOLOGY',
  },
  {
    programId: 'REG/EOS/001_2',
    description: 'Ordinary Diploma in Biotechnology',
    hostId: 'REG/EOS/001',
    hostName: 'DAR ES SALAAM INSTITUTE OF TECHNOLOGY',
  },
  {
    programId: 'REG/EOS/001_3',
    description: 'Ordinary Diploma in Civil Engineering',
    hostId: 'REG/EOS/001',
    hostName: 'DAR ES SALAAM INSTITUTE OF TECHNOLOGY',
  },
  {
    programId: 'REG/EOS/001_4',
    description: 'Ordinary Diploma in Communication SystemsTechnology',
    hostId: 'REG/EOS/001',
    hostName: 'DAR ES SALAAM INSTITUTE OF TECHNOLOGY',
  },
  {
    programId: 'REG/EOS/001_5',
    description: 'Ordinary Diploma in Computer Engineering',
    hostId: 'REG/EOS/001',
    hostName: 'DAR ES SALAAM INSTITUTE OF TECHNOLOGY',
  },
  {
    programId: 'REG/EOS/001_6',
    description: 'Ordinary Diploma in Electrical Engineering',
    hostId: 'REG/EOS/001',
    hostName: 'DAR ES SALAAM INSTITUTE OF TECHNOLOGY',
  },
  {
    programId: 'REG/EOS/008_1',
    description: 'Ordinary Diploma in Geomatics',
    hostId: 'REG/EOS/008',
    hostName: 'ARDHI INSTITUTE MOROGORO',
  },
  {
    programId: 'REG/EOS/008_2',
    description: 'Ordinary Diploma in Urban and Regional Planning',
    hostId: 'REG/EOS/008',
    hostName: 'ARDHI INSTITUTE MOROGORO',
  },
  {
    programId: 'REG/HAS/115_1',
    description: 'Ordinary Diploma in Nursing and Midwifery',
    hostId: 'REG/HAS/115',
    hostName: 'ARUSHA LUTHERAN MEDICAL TRAING CENTRE',
  },
  {
    programId: 'REG/EOS/010_1',
    description: 'Ordinary Diploma in Cartography',
    hostId: 'REG/EOS/010',
    hostName: 'ARDHI INSTITUTE - TABORA',
  },
  {
    programId: 'REG/EOS/010_2',
    description: 'Ordinary Diploma in Graphic Arts and Printing',
    hostId: 'REG/EOS/010',
    hostName: 'ARDHI INSTITUTE - TABORA',
  },
  {
    programId: 'REG/EOS/010_3',
    description: 'Ordinary Diploma in Land Management, Valuationand Registration',
    hostId: 'REG/EOS/010',
    hostName: 'ARDHI INSTITUTE - TABORA',
  },
  {
    programId: 'REG/BTP/047_18',
    description: 'Ordinary Diploma in Accountancy',
    hostId: 'REG/BTP/047',
    hostName: 'ARUSHA INSTITUTE OF BUSINESS STUDIES',
  },
  {
    programId: 'REG/BTP/047_19',
    description: 'Ordinary Diploma in Business Administration',
    hostId: 'REG/BTP/047',
    hostName: 'ARUSHA INSTITUTE OF BUSINESS STUDIES',
  },
]

export const diplomaProgOpts = diplomaProgrammes.map(fp => {
  return { label: fp.hostName + ' (' + fp.description + ')', value: fp.programId }
})
