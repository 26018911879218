// React imports
import { useState } from 'react'

// Routing imports
import { useNavigate } from 'react-router-dom'

// Style imports
import styles from './login.module.scss'

// UI Kit imports
import { InputMask } from 'primereact/inputmask'
import { Button } from 'primereact/button'


// Firebase Imports
import { auth } from '../../configs/firebase/firebase.utils'
import {InputText} from "primereact/inputtext";
import Footer from "../../components/footer/Footer";
import { RecaptchaVerifier,signInWithPhoneNumber, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const Login = () => {
  const { card, loginElem, homeLogo, phoneElem, isdCode, phoneInputEl } = styles
  const [recaptchaSuccess, setRecaptchaSuccess] = useState()
  const [loadingGetOtp, setLoadingGetOtp] = useState(false)
  const [loadingSubmitOtp, setLoadingSubmitOtp] = useState(false)
  const [values, setValues] = useState({ phoneNumber: '', otp: '', error: '' })
  const { phoneNumber, otp, error } = values
  const navigate = useNavigate()

  const handleChange = inputName => e => {
    let value = e.target.value
    setValues({ ...values, error: '', [inputName]: value })
  }

  const setUpReCaptcha = () => {
    if (window.recaptchaVerifier) return
    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: response => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        onPhoneNumberSubmit()
      },
    },auth)
  }

  const onPhoneNumberSubmit = async e => {
    e?.preventDefault()
    setLoadingGetOtp(true)
    await setUpReCaptcha()
    try {
      let pwd = phoneNumber.trim()?.replace(/-/g, '')?.replace(/ /g, '') // phoneNumber without dashes or hyphens
      let formattedPhoneNumber = pwd.startsWith(0) ? pwd.slice(1, pwd.length) : pwd.startsWith('+') ? pwd.slice(3, pwd.length) : pwd
      // const confirmationResult = await auth.signInWithPhoneNumber('+255' + formattedPhoneNumber, window.recaptchaVerifier)
      const phoneNo = '+255'+formattedPhoneNumber
      const confirmationResult = await signInWithPhoneNumber(auth,phoneNo.toString(), window.recaptchaVerifier)
      setValues({ error: '', phoneNumber: '', otp: '' })
      setRecaptchaSuccess(confirmationResult)
    } catch (error) {
      console.error(error)
      setValues({ ...values, error: error.message })
      setLoadingGetOtp(false)
    }
  }
  const loginByGoogleProvider = () =>{
    let provider = new GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' });
   return  signInWithPopup(auth,provider)
        .then(v=>{
      navigate('/program-results')
    }).catch(error=>{
      console.log(error)
    })
  }
  const verifyOtp = async e => {
    e?.preventDefault()
    setLoadingSubmitOtp(true)
    try {
      await recaptchaSuccess.confirm(otp?.toString())
      navigate('/program-results')
    } catch (error) {
      console.error(error)
      setValues({ ...values, error: error.message })
      setLoadingSubmitOtp(false)
    }
  }
  return (
    <div className={loginElem}>
      <div className={card}>
          <img className={homeLogo} src={'ar-logo-light2.png'} alt='logo' width='250' />
          <h1>Login</h1>
        {/*<div>*/}
        {/*  <p>Please login with your phone number to view the best results as per your customizations.</p>*/}
        {/*</div>*/}
        <div>
          <p className='error-msg'>{error}</p>
        </div>
        <div>
          <div id='recaptcha-container' style={{zIndex: '1000000'}}/>
          {recaptchaSuccess ? (
            <form onSubmit={verifyOtp} className={phoneElem}>
              <label htmlFor='phone'>Enter OTP sent on your mobile</label>
              {/*<InputNumber inputId='without-grouping' value={otp} onValueChange={handleChange('otp')} mode='decimal' useGrouping={false} />*/}
              <InputText value={otp} onChange={handleChange('otp')}/>
              <Button loading={loadingSubmitOtp} label='Submit OTP' icon='pi pi-check' className='p-button' />
            </form>
          ) : (
            <form onSubmit={onPhoneNumberSubmit} className={phoneElem}>
              <label htmlFor='phone'>Enter Your Phone Number</label>
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">+255</span>
                <InputMask  id='phone' mask='0999-999999' value={phoneNumber}
                            placeholder='768-013xxx' onChange={handleChange('phoneNumber')} required/>
              </div>

              {/*<span className={isdCode}>+255-</span>*/}
              <Button label='Get OTP' loading={loadingGetOtp} icon='pi pi-check' className='p-button mt-3' />
            </form>)}
          <div className='mt-3'>
            <h3>or Login using Google</h3>
            <Button label='Google' onClick={e =>loginByGoogleProvider()} className='mt-2'/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Login
