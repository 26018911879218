// React imports
import {useEffect, useState} from 'react'

// Routing imports
import { useNavigate } from 'react-router-dom'

// Context Imports
import { useContext } from 'react'
import { FormDoc } from '../../configs/contexts/formDocument'

// UI Kit imports
import { Button } from 'primereact/button'

// Component imports
import Layout from '../../components/layout/Layout'
import { Dialog } from 'primereact/dialog';

// Style imports
import styles from './qualified.module.scss'

const QualifiedProgrammes = () => {
  const { formDoc, setFormDoc } = useContext(FormDoc)

  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayBasic2, setDisplayBasic2] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [displayPosition, setDisplayPosition] = useState(false);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState('center');

  const { parentClass, content, buttons } = styles

  const navigate = useNavigate()

  useEffect(() => {
    setFormDoc({ ...formDoc, leadQualification: { ...formDoc.leadQualification, isVisited: true } })

  },[])

  const handleClick = name => e => {
    e?.preventDefault()
    // setFormDoc({ ...formDoc, leadQualification: { ...formDoc.leadQualification, data: { leadQualification: e.currentTarget.textContent?.toLowerCase() } } })
    setFormDoc({ ...formDoc, leadQualification: { ...formDoc.leadQualification, data: { leadQualification: name } } })
    navigate('/enter-marks')
  }
  const dialogFuncMap = {
    'displayBasic': setDisplayBasic,
    'displayBasic2': setDisplayBasic2,
    'displayModal': setDisplayModal,
    'displayMaximizable': setDisplayMaximizable,
    'displayPosition': setDisplayPosition,
    'displayResponsive': setDisplayResponsive
  }

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  }
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  }

  const renderFooter = (name) => {
    return (
        <div>
          {/*<Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />*/}
          <Button label="Close" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
        </div>
    );
  }
  return (
    <Layout hideProgress='false' parentClass={parentClass}>
      <div className={content}>
        <div>
          <h1>Select entry qualification...</h1>
          <div className={buttons}>
            <Button onClick={handleClick('foundation')} label='Foundation' className='p-button-raised p-button-lg' />
            {/*<Button onClick={handleClick('diploma')} label='Diploma' className='p-button-raised p-button-lg' />*/}
            <Button label="Diploma" className='p-button-raised p-button-lg' onClick={() => onClick('displayPosition','bottom')} />
            <Button onClick={handleClick('acsee')} label='ACSEE/Form6' className='p-button-raised p-button-lg' />
          </div>
        </div>
      </div>
      <Dialog header="Coming Soon" visible={displayPosition} position={position} style={{ width: '50vw' }} footer={renderFooter('displayPosition')} onHide={() => onHide('displayPosition')}>
        <p>This feature will be available soon</p>
      </Dialog>
    </Layout>
  )
}

export default QualifiedProgrammes
