import {auth} from "../configs/firebase/firebase.utils";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {useState} from "react";
import {InputText} from "primereact/inputtext";
import {useForm, Controller} from "react-hook-form";

export default function UserInfo(){
    const [onEdit,setOnEdit]= useState(false)
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const nameArr=auth.currentUser.displayName? auth.currentUser.displayName.split(' ') : ''
    const defaultValues = {
        fname:nameArr[0],
        lname:nameArr[1],
        email:auth.currentUser.email,
        phone:auth.currentUser.phoneNumber
    }
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const onSubmit = (data) => {
        setFormData(data);
        setShowMessage(true);
        reset();
    };
    const footer = <span>
    {/*<Button label="Edit" icon="pi pi-check" style={{marginRight: '.25em'}} onClick={e=> setOnEdit(true)}/>*/}
    {/*<Button label="Cancel" icon="pi pi-times" className="p-button-secondary"/>*/}
</span>;
    return (
        <div>
        {!onEdit? (
        <Card title='Your info' footer={footer} >
            <ul className='list-none'>
                <li><label>name:</label><strong>{auth.currentUser.displayName}</strong></li>
                <li><label>email:</label><strong>{auth.currentUser.email}</strong></li>
                <li><label>phone:</label><strong>{auth.currentUser.phoneNumber}</strong></li>
            </ul>
        </Card>):(
            <div className="card">
                <h3>Edit personal info</h3>
                <form>
                <div className="field grid">
                    <label htmlFor="firstname" className="col-12 mb-2 md:col-2 md:mb-0">Firstname</label>
                    <div className="col-12 md:col-10">
                        <Controller name="fname" control={control} rules={{ required: 'Name is required.' }} render={({ field, fieldState }) => (
                        <InputText id={field.fname} {...field} autoFocus className="inputfield w-full"/>
                        )} />
                    </div>
                </div>
                <div className="field grid">
                    <label htmlFor="lastname" className="col-12 mb-2 md:col-2 md:mb-0">Lastname</label>
                    <div className="col-12 md:col-10">
                        <Controller name="lname" control={control} rules={{ required: 'Name is required.' }} render={({ field, fieldState }) => (
                        <InputText id={field.lname} {...field} className="inputfield w-full"/>
                        )} />
                    </div>
                </div>
                <div className="field grid">
                    <label htmlFor="email" className="col-12 mb-2 md:col-2 md:mb-0">Email</label>
                    <div className="col-12 md:col-10">
                        <Controller name="email" control={control}
                                    rules={{ required: 'Email is required.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' }}}
                                    render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className="inputfield w-full"/>
                                    )} />

                    </div>
                </div>
                <div className="field grid">
                    <label htmlFor="phone" className="col-12 mb-2 md:col-2 md:mb-0">Phone</label>
                    <div className="col-12 md:col-10">
                        <InputText id="phone" placeholder='+255xxxxxxxxx' type="text" className="inputfield w-full"/>
                    </div>
                </div>
                    <Button type="submit" label="Submit" className="p-mt-2" />
                </form>
            </div>
        )}
        </div>
    )
}
