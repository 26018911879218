// Firebase Imports
import { auth } from '../../configs/firebase/firebase.utils'
import { useAuthState } from 'react-firebase-hooks/auth'

// UI Kit Imports
import { confirmPopup } from 'primereact/confirmpopup'

// Routing imports
import { useNavigate } from 'react-router-dom'

// Context imports
import { useContext } from 'react'
import { FormDoc, formDocument } from '../../configs/contexts/formDocument'

export default function SignOut(props) {
  const [user] = useAuthState(auth)
  const navigate = useNavigate()
  const { setFormDoc } = useContext(FormDoc)

  const onSignOut = event => {
    event.preventDefault()
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to Sign out?',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        window.recaptchaVerifier = null
        await auth.signOut()
        setFormDoc(formDocument)
        navigate.push('/')
        props.onSuccess && props.onSuccess()
      },
      reject: null,
    })
  }
  return (
    user && (
      // <div className={props.className} onClick={onSignOut} style={{ cursor: 'pointer', fontSize: '1.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className={props.className} onClick={onSignOut} >
        <i className='pi pi-power-off' style={{ fontSize: '2rem', marginLeft: '15px' }} />
      </div>
    )
  )
}
