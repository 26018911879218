// React imports
import React from 'react'

// UI kit imports
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import {auth} from "../../configs/firebase/firebase.utils";
import {Menu} from "primereact/menu";


export default class AllPrograms extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            results: props.results?.filter(r => r.instituteName),
            selectedProgs: [],
        }
        this.exportPDF = this.exportPDF.bind(this);

    }
    componentDidMount() {
        if(this.props.instFilter && this.props.instFilter !== 'all'){
            this.setState({results: this.props.results.filter((inst)=> inst.instituteName === this.props.instFilter)});
        }else{
            this.setState({results: this.props.results});
        }
    }
    instBodyTemplate = (title, field) => rowData => {
        return (
            <React.Fragment>
                <span className='p-column-title'>{title}</span>
                <span className='data'>{rowData[field] || 'Not Available for now'}</span>
            </React.Fragment>
        )
    }
    progBodyTemplate = (title, field) => rowData => {
        return (
            <React.Fragment>
                <span className='p-column-title'>{title}</span>
                <span className='data'>{rowData[field] || 'Not Available for now'}</span>
            </React.Fragment>
        )
    }
    chancesBodyTemplate = (title, field) => rowData => {
        return (
            <React.Fragment>
                <span className='p-column-title'>{title}</span>
                <span className='data'>{rowData[field] || 'Not Available for now'}</span>
            </React.Fragment>
        )
    }
    pointsBodyTemplate = (title, field) => rowData => {
        return (
            <React.Fragment>
                <span className='p-column-title'>{title}</span>
                <span className='data'>{rowData[field] || 'Not Available for now'}</span>
            </React.Fragment>
        )
    }
   exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new JsPDF(orientation, unit, size);

        doc.setFontSize(15);
        const title = "Likely Programmes for "+ auth.currentUser?.phoneNumber;
        const headers = [[ "#","Programme","Institution","Reason","Likelihood"]];
        let data
        if (this.state.selectedProgs.length === 0){
            data = this.state.results.map((r,index) => [ index+1, r.progName, r.instituteName, r.qualReason, r.chances]);
        }else {
            data = this.state.selectedProgs.map((r,index)=> [index+1, r.progName, r.instituteName, r.qualReason, r.chances]);
        }
        let content = {
            startY: 50,
            head: headers,
            body: data
        };
       const addFooters = doc => {
           const pageCount = doc.internal.getNumberOfPages()

           doc.setFont('helvetica', 'italic')
           doc.setFontSize(8)
           for (var i = 1; i <= pageCount; i++) {
               doc.setPage(i)
               doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width-40 , doc.internal.pageSize.height-40, {
                   align: 'center'
               })
               doc.text('from:' + window.location.host+ ' '+ new Date(), 40 , doc.internal.pageSize.height-35, {
                   align: 'left'
               })
           }
       }
        doc.text(title, marginLeft, 40);
        // doc.table(40,40,data,headers,)
        doc.autoTable(content);
        addFooters(doc)
        doc.save("Likely-Programmes.pdf")
    }
    // onFilterChange(e) {
    //     this.setState({ selectedProgs: e.value });
    // }

   render() {
       const items=[
           {label:'export to pdf',icon:'pi pi-file-pdf', command: this.exportPDF}
       ]
       return (
           <div >
               {/*<Card header={this.header} title={<h5 className='p-mb-3'>User: {auth.currentUser?.phoneNumber}</h5>}*/}
               {/*      subTitle={<span>total programmes: {this.state.results.length}</span>}/>*/}
                   {/*<h4 className='p-mb-3 redtext'>User: {auth.currentUser?.phoneNumber} </h4>*/}
               <div className='p-2 border-0 border-round bg-blue-50 flex justify-content-between mb-2'>
                   <div>
                       <h5 className='p-mb-3 redtext'>User: {auth.currentUser?.phoneNumber}</h5>
                       <span>total likely programmes: {this.state.results.length}</span>
                   </div>
                   <div className='hidden md:block'>
                       <Button  type='button' icon='pi pi-file-pdf' onClick={this.exportPDF} className='p-button-primary p-mr-2'
                               data-pr-tooltip='PDF' label='Export to PDF'/>
                   </div>
                   <div className='sm:hidden'>
                       <Menu model={items} popup ref={el => this.menu =el} id="popup_menu" />
                       <Button className='p-button-rounded p-button-text' icon="pi pi-ellipsis-v" onClick={(event) => this.menu.toggle(event)} aria-controls="popup_menu" aria-haspopup/>
                   </div>
               </div>
                   <DataTable
                       value={this.state.results}
                       sortMode="single"
                       sortField='#'
                       sortOrder={1}
                       onValueChange={sortedData => {this.setState({selectedProgs: sortedData})}}
                       className='p-datatable-sm'
                       filterDisplay="row"
                       responsiveLayout="scroll"
                       resizableColumns
                       columnResizeMode='fit'
                       showGridlines
                       stripedRows
                       paginator
                       paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                       currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
                       rows={50}
                       rowsPerPageOptions={[10, 20, 50, this.state.results?.length]}
                   >

                       <Column header="#" body={(data) =>{ return this.state.results.indexOf(data)+1}} headerStyle={{ width: '3em' }} sortable/>
                       <Column field='progName' header='Likely Programmes' headerStyle={{ width: '250px' }}
                               filter filterPlaceholder='Search by name' filterMatchMode='contains'
                               sortable/>
                       <Column field='instituteName' header='Institution' headerStyle={{ width: '250px' }}
                               filter filterPlaceholder='Search by name' filterMatchMode='contains'
                               sortable/>
                       <Column field='qualReason' header='Reason' headerStyle={{ width: '150px' }} sortable/>
                       <Column field='chances' header='Likelihood' headerStyle={{ width: '150px' }} sortable/>
                   </DataTable>
           </div>
       );
   }
}

