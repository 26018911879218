// Style imports
import styles from './footer.module.scss'
// Routing imports
import { useNavigate } from 'react-router-dom'

const Footer = props => {
  const { footerElem, footerLink } = styles
  const navigate = useNavigate()
  return (

    <div className={props.className + ' '}>
        {/*<div className={props.className + ' '}>*/}
        <div className={footerElem}>
            <div onClick={() => navigate('/')} className={footerLink}>
                Home
            </div>
            <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
            <div onClick={() => navigate('/faq')} className={footerLink}>
                FAQ
            </div>
            <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
            <div onClick={() => navigate('/contact-us')} className={footerLink}>
                Contact Us
            </div>
        </div>
        <div className='flex justify-content-center text-blue-400'>
            <p>Copyright@2021 AiGi Systems Company Ltd  </p>
        </div>

    </div>
  )
}

export default Footer
