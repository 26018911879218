// React imports
import {useState, useRef, useEffect} from 'react'

// UI kit imports
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Dropdown} from 'primereact/dropdown'
import {MultiSelect} from 'primereact/multiselect'
import {Button} from "primereact/button";

// data imports
import {institutionsWithProgramme} from '../../data'
import {Card} from "primereact/card";

const InstituteTable = () => {
    const [selectedType, setSelectedType] = useState(null)
    const [selectedOwnerShip, setSelectedOwnerShip] = useState(null)
    const [selectedRegion, setSelectedRegion] = useState(null)

    const dt = useRef(null)

    const instituteTypes = [...new Set(institutionsWithProgramme.map(i => i.institution_type))]
    const ownerShipTypes = [...new Set(institutionsWithProgramme.map(i => i.ownership))]
    const regions = [...new Set(institutionsWithProgramme.map(i => i.region_name))]

    const makeBodyTemplate = (title, field) => rowData => {
        return (
            <>
                <span className='p-column-title'>{title}</span>
                {field === 'website' ? (
                    rowData[field] ? (
                        <span className='data'>
              {
                  <a href={rowData[field]} target='_blank' rel="noreferrer">
                      {/*{rowData[field]}*/}
                      <Button className='p-button-sm'>Website</Button>
                  </a>
              }
            </span>
                    ) : (
                        <span className='data'>Not Available for now</span>
                    )
                ) : (
                    <span className='data'>{rowData[field] || 'Not Available for now'}</span>
                )}
            </>
        )
    }

    const onInstituteChange = e => {
        dt.current.filter(e.value, 'institution_type', 'equals')
        setSelectedType(e.value)
    }

    const onOwnershipChange = e => {
        dt.current.filter(e.value, 'ownership', 'equals')
        setSelectedOwnerShip(e.value)
    }

    const instituteFilter = <Dropdown value={selectedType} options={instituteTypes} onChange={onInstituteChange}
                                      placeholder='Select a type' className='p-column-filter' showClear/>

    const ownershipFilter = <Dropdown value={selectedOwnerShip} options={ownerShipTypes} onChange={onOwnershipChange}
                                      placeholder='Select a type' className='p-column-filter' showClear/>

    const onRegionChange = e => {
        dt.current.filter(e.value, 'region_name', 'in')
        setSelectedRegion(e.value)
    }

    const regionsItemTemplate = option => {
        return (
            <div className='p-multiselect-representative-option'>
                <span>{option}</span>
            </div>
        )
    }

    const regionFilter = <MultiSelect value={selectedRegion} options={regions} itemTemplate={regionsItemTemplate}
                                      onChange={onRegionChange} placeholder='All Regions' className='p-column-filter'/>;
    // useEffect(()=>{
    //     console.log(institutionsWithProgramme)
    // })
    return (
        <div className='datatable-responsive-demo'>
            <Card>
                <DataTable
                    value={institutionsWithProgramme}
                    header='List of Learning Institutes'
                    className='p-datatable-sm'
                    resizableColumns
                    columnResizeMode='fit'
                    showGridlines
                    autoLayout
                    sortField='name'
                    filterDisplay={"row"}
                    responsiveLayout="scroll"
                    ref={dt}
                    paginator
                    paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                    currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50, institutionsWithProgramme.length]}
                >
                    {/* <Column field='code' header='Code' body={makeBodyTemplate('Code', 'code')} filter filterPlaceholder='Code' filterMatchMode='contains'></Column> */}
                    <Column header="#" let-i="rowindex" body={(data) =>{ return institutionsWithProgramme.indexOf(data)+1}}/>
                    <Column field='name'
                            header='Name'
                            body={makeBodyTemplate('Name', 'name')}
                            filter filterPlaceholder='Search by name'
                            filterMatchMode='contains'
                            sortable/>
                    <Column field='region_name'
                            header='Region'
                            body={makeBodyTemplate('Region', 'region_name')}
                            filter filterElement={regionFilter}/>
                    <Column field='ownership'
                            header='Ownership'
                            body={makeBodyTemplate('Ownership', 'ownership')}
                            filter filterElement={ownershipFilter}/>
                    <Column field='institution_type'
                            header='Institute Type'
                            body={makeBodyTemplate('Institution', 'institution_type')}
                            filter filterElement={instituteFilter}/>
                    {/*<Column field='website' header='Website' body={makeBodyTemplate('Website', 'website')} filter filterPlaceholder='Search by website' filterMatchMode='contains'></Column>*/}
                    <Column field='website'
                            header='Website'
                            body={makeBodyTemplate('Website', 'website')}/>
                </DataTable>
            </Card>
        </div>
    )
}

export default InstituteTable
