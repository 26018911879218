// React imports
import {useState, useEffect} from 'react'

// UI Kit imports
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

// Data imports
import {possibleAGrades2014_2015, possibleGrades, possibleO_LevelGrades} from '../../data'

// Style imports
import styles from './gradeInput.module.scss'

const GradeInput = props => {
  const [possGrade,setPossGrade] = useState([])
  const [value, setValue] = useState(props.subject.grade || '')
  const { gradeInputElem, gradeBtn } = styles

  useEffect(()=>{
    if(props.gradeType === 'csee'){
      setPossGrade(possibleO_LevelGrades)
    }
    else if(props.isAcseeCompetedYear2014_2015 === true ){
      setPossGrade(possibleAGrades2014_2015)
      setValue('')
    }else{
      setPossGrade(possibleGrades)
      setValue('')
    }
  },[props.isAcseeCompetedYear2014_2015])

  const checkInput = e => {
    // const grades = possibleGrades.map(x => x.label.toLowerCase())
    const grades = possGrade.map(x => x.label.toLowerCase())
    if (!grades.includes(e.key.toLowerCase()) || value?.length > 0) {
      e.preventDefault()
    }
  }

  const changeGrade = type => e => {
    e?.preventDefault()
    // let currGrade = possibleGrades.map(x => x.label?.toLowerCase()).indexOf(value?.toLowerCase())
    let currGrade = possGrade.map(x => x.label?.toLowerCase()).indexOf(value?.toLowerCase())
    if (type === 'minus') {
      if ([0, -1].includes(currGrade)) currGrade = possGrade.length
      setValue(possGrade[currGrade - 1].label)
    } else {
      if (currGrade === possGrade.length - 1) currGrade = -1
      setValue(possGrade[currGrade + 1].label)
    }
  }

  const handleChange = e => {
    const val = e.target.value.toUpperCase()
    setValue(val)
  }

  useEffect(() => {
    props.onChange && props.onChange(value)

  }, [value])

  useEffect(() => {
    setValue(props.subject.grade)
  }, [props.subject])

  return (
    <div className={gradeInputElem}>
      {/*<Button onClick={changeGrade('minus')} icon='pi pi-minus' className='p-button-rounded p-button-danger' />*/}
      <Button onClick={changeGrade('plus')} icon='pi pi-minus' className='p-button-rounded p-button-danger' />
      <div className={gradeBtn}>
        <Button icon='pi pi-check-circle' className='p-button-rounded p-button-info p-button-outlined' label='Grade'>
          <InputText value={value || ''}
                     placeholder={possGrade.map(x => x.label.toUpperCase()).join('/ ')}
                     className='p-inputtext-sm'
                     onKeyPress={checkInput} onChange={handleChange} />
        </Button>
      </div>
      {/*<Button onClick={changeGrade('plus')} icon='pi pi-plus' className='p-button-rounded p-button-success' />*/}
      <Button onClick={changeGrade('minus')} icon='pi pi-plus' className='p-button-rounded p-button-success' />
    </div>
  )
}

export default GradeInput
