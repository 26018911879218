// Component imports
import InstituteTable from '../../components/customTable/InstituteTable'
import Layout from '../../components/layout/Layout'

const LearningInstitutions = () => {
  return (
    <Layout parentClass='learning-institutes' hideProgress>
     <InstituteTable/>
    </Layout>
  )
}

export default LearningInstitutions
