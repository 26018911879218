// React imports
import React, { useState, useEffect } from 'react'
// Component imports
import ResultInput from '../resultInput/ResultInput'
import { cseeSubjects, cseeOptionalSubs } from '../../data'
import { sortArrayOfObj } from '../../helpers/commonHelper'
import Dropdown from '../../components/dropdown/Dropdown'
// UI kit imports
import { RadioButton } from 'primereact/radiobutton'
import { ToggleButton } from 'primereact/togglebutton'
// Style imports
import styles from './cseeResults.module.scss'
// Context imports
import { useContext } from 'react'
import { FormDoc } from '../../configs/contexts/formDocument'

const CseeResults = () => {
  const { formDoc, setFormDoc } = useContext(FormDoc)
  const [stream, setStream] = useState(formDoc.marks.data.cseeTrack || 'Arts')
  const [optionalVisible, setOptionalVisible] = useState(false)
  const [optionalCseeSubs, setOptionalCseeSubs] = useState({ Subjects: formDoc.marks.data.csee.filter(s => s.Track === 'Optional') })
  const [cseeOptDropdown, setCseeOptDropdown] = useState(cseeOptionalSubs)

  const { parentClass, cseeResultH2, addOptHead, cseeResultH3, radios, radio, streamElem, optToggleElem } = styles

  const streamChange = newStream => {
    setStream(newStream.value)
    setFormDoc({ ...formDoc, marks: { ...formDoc.marks, data: { ...formDoc.marks.data, cseeTrack: newStream.value } } })
  }
  const handleOptSubChange = newSub => {
    const selectedSub = cseeSubjects.find(s => s.code === newSub)
    let optArr = [...optionalCseeSubs.Subjects]
    optArr.push(selectedSub)
    const newList = cseeOptDropdown.filter(s => s.value !== newSub)
    setCseeOptDropdown(newList)
    setOptionalCseeSubs({ Subjects: optArr })
  }
  const onDeleteClick = deletedSub => e => {
    e?.preventDefault()
    const newSub = optionalCseeSubs.Subjects.filter(s => s.code !== deletedSub)
    setOptionalCseeSubs({ Subjects: newSub })
    const selectedSub = cseeSubjects.find(s => s.code === deletedSub)
    let newList = [...cseeOptDropdown]
    newList.push({ label: selectedSub.Name, value: selectedSub.code })
    setCseeOptDropdown(newList)
  }

  useEffect(() => {
    let optSubs = [...formDoc.marks.data.csee].filter(x => x.Track !== 'Optional')
    setFormDoc({ ...formDoc, marks: { ...formDoc.marks, data: { ...formDoc.marks.data, csee: [...optSubs, ...optionalCseeSubs.Subjects] } } })
  }, [optionalCseeSubs])

  useEffect(() => {
    //debugger
    let compareStr = stream === 'Science' ? 'Natural Science' : stream === 'Business' ? 'business' : stream
    let subs = [...formDoc.marks.data.csee].filter(x => !(x.Track === 'Arts' || x.Track === 'business' || x.Track === 'Natural Science'))
    let streamSubs = [...subs, ...cseeSubjects.filter(s => s.Track === compareStr)]
    setFormDoc({ ...formDoc, marks: { ...formDoc.marks, data: { ...formDoc.marks.data, csee: [...streamSubs] } } })

  }, [stream])

  return (
    <div className={parentClass}>
      <h2 className={cseeResultH2}>Enter CSEE/Form4 Scores</h2>
      {/*<div className='flex align-items-center'>*/}
      {/*  <span className='mr-3'>Is your result year 2013</span><InputSwitch tooltip='switch on if you graduated year 2013' checked={is2013} onChange={(e)=> setIs2013(e.value)}/>*/}
      {/*</div>*/}
      <h3 className={cseeResultH3}>Enter Core Subject Scores</h3>
      <ResultInput resultType='csee' askCombination={{ Subjects: formDoc.marks.data.csee.filter(s => s.Track === 'core') }} />
      <div className={streamElem}>
        <h3 className={cseeResultH3}>Select Stream</h3>
        <div className={radios}>
          <div className={radio}>
            <RadioButton inputId='Science' name='stream' value='Science' onChange={streamChange} checked={stream === 'Science'} />
            <label htmlFor='Science'>Science</label>
          </div>
          <div className={radio}>
            <RadioButton inputId='Business' name='stream' value='Business' onChange={streamChange} checked={stream === 'Business'} />
            <label htmlFor='Business'>Business</label>
          </div>
          <div className={radio}>
            <RadioButton inputId='Arts' name='stream' value='Arts' onChange={streamChange} checked={stream === 'Arts'} />
            <label htmlFor='Arts'>Arts</label>
          </div>
        </div>
      </div>
      {stream === 'Science' && (
        <>
          <h3 className={cseeResultH3}>Enter Scores in Natural Science Subjects</h3>
          <ResultInput  resultType='csee' askCombination={{ Subjects: formDoc.marks.data.csee.filter(s => s.Track === 'Natural Science') }} />
        </>
      )}
      {stream === 'Business' && (
        <>
          <h3 className={cseeResultH3}>Enter Scores in Business Subjects</h3>
          <ResultInput resultType='csee' askCombination={{ Subjects: formDoc.marks.data.csee.filter(s => s.Track === 'business') }} />
        </>
      )}
      <div className={optToggleElem}>
        <ToggleButton
          checked={optionalVisible}
          onChange={e => setOptionalVisible(e.value)}
          onLabel='CSEE Additional Subjects'
          offLabel='Add Additional CSEE Subjects'
          onIcon='pi pi-book'
          offIcon='pi pi-book'
          style={{ width: 'fit-content' }}
        />
        {optionalVisible && (
          <div style={{ width: '100%' }}>
            {optionalCseeSubs.Subjects.length < 3 && (
              <>
                <h2 className={addOptHead}>Add CSEE Optional Subjects (Max 3)</h2>
                <Dropdown options={sortArrayOfObj(cseeOptDropdown, 'label')} onChange={handleOptSubChange} />
              </>
            )}
            {optionalCseeSubs.Subjects.length > 0 && <ResultInput resultType='csee' onDeleteClick={onDeleteClick} askCombination={optionalCseeSubs} />}
          </div>
        )}
      </div>
    </div>
  )
}

export default CseeResults
