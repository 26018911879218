//import fetch from 'isomorphic-fetch'

// import {programmes as progList, programmesWithInstitutions, subjects} from "../data";

// const baseApiUrl = 'https://qualificationapi.herokuapp.com/api'
import {auth, firestore} from "../configs/firebase/firebase.utils";
import moment from "moment";
import {collection, doc, query,updateDoc, where, setDoc, getDocs} from "firebase/firestore";


const baseApiUrl2 = 'https://py.aigi.co.tz'
const localHostAPI = 'http://localhost:3001/dpo/api/'
const dpoApiUrl2='https://secure.3gdirectpay.com/API/v6/'
const dpoApiPath = '/API/v6/'
const dpoProxy = 'http://ec2-3-15-71-192.us-east-2.compute.amazonaws.com:8086/api/dpo/'

export const getQualifiedPrograms = async markDetail => {
  // const response = await fetch(`${baseApiUrl}/get_qualified_programs`, {
  const response = await fetch(`${baseApiUrl2}/qp/`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Host':'aigi.co.tz'
    },
    body: JSON.stringify(markDetail),
  })
  return response.json();
}

export const createToken_dpo = async () => {
  const nameArr = auth.currentUser.displayName? auth.currentUser.displayName: 'mussa mchomvu'
  const price = 100.00;
  const date = moment().format('YYYY/MM/DD HH:MM')
  const phone = auth.currentUser.phoneNumber.slice(4)
  // const redirectUrl = window.location.href
  const redirectUrl = `https://ar.aigi.co.tz/program-results`
  const xml = `
<?xml version="1.0" encoding="utf-8"?>
<API3G>
  <CompanyToken>9F416C11-127B-4DE2-AC7F-D5710E4C5E0A</CompanyToken>
  <Request>createToken</Request>
  <Transaction>
    <PaymentAmount>300.00</PaymentAmount>
    <PaymentCurrency>TZS</PaymentCurrency>
    <RedirectURL>${redirectUrl}</RedirectURL>
    <BackURL>${redirectUrl}</BackURL>
    <CompanyRefUnique>0</CompanyRefUnique>
    <PTL>30</PTL>
    <PTLtype>minutes</PTLtype>
    <customerPhone>${phone}</customerPhone>
    <customerCountry>TZ</customerCountry>
    <DefaultPayment>MO</DefaultPayment>
    <DefaultPaymentCountry>Tanzania</DefaultPaymentCountry>
    <DefaultPaymentMNO>Tigo</DefaultPaymentMNO>
  </Transaction>
  <Services>
    <Service>
      <ServiceType>5525</ServiceType>
      <ServiceDescription>Qualify program results</ServiceDescription>
      <ServiceDate>${date}</ServiceDate>
    </Service>
  </Services>
</API3G>`
  const parser1 = new DOMParser();
  const xmlDoc1 = parser1.parseFromString(xml, 'application/xml');
   console.log(xmlDoc1)

  return await fetch(dpoProxy, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/xml; charset=UTF-8',
      'Cache-Control': 'no-cache'
    },
    body: xml,
  }).then(result => {
    return result.json()
  }).then(data => {
    return data.body
  }).catch(err => {
    console.log(err)
  })
}
export const verifyPayment = (transToken) => {
  let xml = `
<?xml version="1.0" encoding="utf-8"?>
<API3G>
  <CompanyToken>9F416C11-127B-4DE2-AC7F-D5710E4C5E0A</CompanyToken>
  <Request>verifyToken</Request>
  <TransactionToken>${transToken}</TransactionToken>
</API3G>`

  return fetch(dpoProxy, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/xml; charset=UTF-8',
     'Cache-Control': 'no-cache'
   },
   body: xml,
 }).then(result => {
   return result.json()
 }).then(value => {
    let paymentApproval
   try{
     const xmlParser = new DOMParser()
     const xmlDoc = xmlParser.parseFromString(value.body, 'text/xml');
     paymentApproval = xmlDoc.getElementsByTagName('TransactionApproval')[0].childNodes[0].nodeValue
   }catch (err){
     console.log(err)
   }

   return !!paymentApproval;
 });
}

// export async function requestLogSave(userId) {
//   const d = moment().format('YYYY-MM-DD')
//   console.log(d)
//   console.log(userId)
//   const apiRequestLogsRef = collection(firestore, "apiRequestLogs");
//   const q = query(apiRequestLogsRef, where('userID','==',userId),where('date','==',d))
//   const userLog =await getDocs(q)
//   // const increment = firebase.firestore.FieldValue.increment(1);
//  console.log(userLog)
//   if(userLog.docs.length>0){
//     userLog.forEach((doc) => {
//       // doc.data() is never undefined for query doc snapshots
//       console.log(doc.id, " => ", doc.data());
//       const logCount = doc.data().noOfRequest
//      return updateDoc(doc(apiRequestLogsRef, doc.id),{noOfRequest:logCount})
//     });
//   }else{
//     await setDoc(apiRequestLogsRef, {
//       userId: userId,
//       date: d,
//       isSuccess: true,
//       noOfRequest: 1
//     })
//   }
//
//
// }
