import React from "react";
import Layout from "../../components/layout/Layout";
import {Steps} from 'primereact/steps';
import './newEnterMarks.css'
import  './enterMarks.module.scss'
import {Card} from "primereact/card";
import {institutions} from "../../data";
import {sortArrayOfObj} from "../../helpers/commonHelper";
import Dropdown from "../../components/dropdown/Dropdown";

// import {Dropdown} from "primereact/dropdown";
export class NewEnterMarks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0
        }
        this.items = [
            {label: 'Institute'},
            {label: 'ACSEE/Form6 Score'},
            {label: 'CSEE/Form4 Score'},
        ]
        this.handleInstituteChange = this.handleInstituteChange.bind(this)
    }

    handleInstituteChange() {

    }

    render() {
        return (
            <Layout parentClass={parentClass}>
                <Card className='mt-5'>
                    <div className='d-flex flex-sm-column'>
                        <Steps model={this.items}
                               activeIndex={this.state.activeIndex}
                               onSelect={(e) => this.setState({activeIndex: e.index})} readOnly={false}>

                        </Steps>
                        {this.state.activeIndex === 0 && <Card>
                            <h4>Select Subject Institution</h4>
                            <Dropdown value={institutions}
                                // onChange={this.handleInstituteChange}
                                      options={sortArrayOfObj(institutions, 'name').map(v => {
                                          return {label: v.name, value: v.code}
                                      })}/>

                        </Card>}
                    </div>
                </Card>
            </Layout>
        )
    }
}
