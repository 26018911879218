// React imports
import { useMemo, useState, useEffect } from 'react'
import useLocalStorage from './customHooks/useLocalStorage'

// Routing imports
import {Route, Routes} from 'react-router-dom'
import * as RouteConfig from './configs/routes/newRoute'

// Style imports
import 'primereact/resources/themes/saga-blue/theme.css'
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css';

// Context imports
import { FormDoc, formDocument } from './configs/contexts/formDocument'
import Layout from "./components/layout/Layout";
import Home from "./pages/home/Home";
import LearningInstitutions from "./pages/learningInstitutions/LearningInstitutions";
import Preview from "./pages/preview/Preview";
import Login from "./pages/login/Login";
import NewLogin from "./pages/login/newLogin";
import Faq from "./pages/faq/Faq";
import ContactUs from "./pages/contactUs/ContactUs";
import {NewEnterMarks} from "./pages/enterMarks/newEnterMarks";
import EnterMarks from "./pages/enterMarks/EnterMarks";
import QualifiedProgrammes from "./pages/qualifiedProgrammes/QualifiedProgrammes";
import ProgramResults from "./pages/programResults/ProgramResults";
import Authenticate from "./helpers/authHelper";
import UserProfile from "./pages/userProfile/UserProfile";
import AdminLayout from "./pages/Admin/adminLayout";

function App() {
  const [formDocInLs, setFormDocInLs] = useLocalStorage('formDocInLs', null)
  // const [formDoc, setFormDoc] = useState(formDocInLs || formDocument)
  const [formDoc, setFormDoc] = useState( {...formDocument,...formDocInLs})

  const formDocProvider = useMemo(() => ({ formDoc, setFormDoc }), [formDoc, setFormDoc])
  useEffect(() => {
    setFormDocInLs(formDoc)
  }, [formDoc,setFormDocInLs])
  return (
    <FormDoc.Provider value={formDocProvider}>
      <div className='App'>
        <Routes >
            <Route index element={<Home/>} exact path='/'/>,
            <Route element={<LearningInstitutions/>} exact path='learning-institutions'/>,
            <Route element={<QualifiedProgrammes/>} exact path='qualified-programmes'/>,
            <Route element={<EnterMarks/>} exact path='enter-marks'/>,
            <Route element={<NewEnterMarks/>} path='new-enter-marks'/>,
            <Route element={<Preview/>} exact path='preview' />,
            <Route element={<Login/>} exact path='login' />,
            <Route element={<NewLogin/>} exact path='newlogin'/>,
            <Route element={<Faq/>} exact path='faq'/>,
            <Route element={<ContactUs/>} exact path='contact-us'/>
            <Route element={
                <Authenticate>
                    <ProgramResults />
                </Authenticate>
            } exact path='/program-results'/>
            <Route element={
                <Authenticate>
                    <UserProfile />
                </Authenticate>
            } exact path='/user-profile'/>
            <Route element={
                <Authenticate>
                    <AdminLayout/>
                </Authenticate>
            } exact path='/admin'/>
            <Route element={<Home/>} exact path='*'/>

          {/*{RouteConfig.Routes.map((item, index) => (*/}
          {/*  <Route key={index} {...item}/>*/}
          ))}
        </Routes>
      </div>
    </FormDoc.Provider>
  )
}

export default App



// server {
//   listen 80;
//   listen [::]:80;
//   root /home/ubuntu/app-deploy/build;
//   location / {
//     try_files $uri /index.html;
//   }
// }
