// React imports
import { useState } from 'react'

// UI kit imports
import { InputText } from 'primereact/inputtext'

const InputBox = props => {
  const [value, setValue] = useState(props.initValue || '')
  const handleChange = e => {
    setValue(e.currentTarget.value)
    props.onChange && props.onChange(e.currentTarget.value)
  }
    return <InputText onChange={handleChange} value={value} id={props.id} keyfilter={props.type} placeholder={props.placeholder} />
}

export default InputBox
