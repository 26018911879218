// Component imports
import Layout from '../../components/layout/Layout'

// Style imports
import styles from './faq.module.scss'

// UI kit imports
import { Accordion, AccordionTab } from 'primereact/accordion'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Button } from 'primereact/button'

// Routing imports
import { useNavigate } from 'react-router-dom'

// Firebase Imports
import { firestore } from '../../configs/firebase/firebase.utils'
import { collection,getDocs,query,orderBy } from "firebase/firestore";
import { useCollectionData } from 'react-firebase-hooks/firestore'

const Faq = () => {
  const { faqEl, accordions } = styles
  const navigate = useNavigate()

  const faqRef = collection(firestore,'faqs')
    // const querySnapshot = await getDocs(collection(db, "users"));
  const q = query(faqRef,orderBy('createdAt', 'desc'))

  const [faqs, loading, error] = useCollectionData(q, { idField: 'id' })

  if (loading) {
    return (
      <div className='loader-container'>
        <ProgressSpinner />
      </div>
    )
  }
  if (error) {
    return (
      <div className='loader-container'>
        <p>Error: {error}</p>
      </div>
    )
  }

  return (
    <Layout parentClass={faqEl} hideProgress hideFooter>
      <div className='mt-2 ml-2'>
        <Button onClick={() => navigate(-1)} label='Back' className='p-button-raised  p-button-sm' />
      </div>
      <h1>Frequently Asked Questions</h1>
      {faqs?.length > 0 ? (
        <Accordion className={accordions} multiple activeIndex={[0]}>
          {faqs.map((fq, key) => (
            <AccordionTab key={'AccordionTab' + key} header={fq.ques}>
              <p>{fq.ans}</p>
            </AccordionTab>
          ))}
        </Accordion>
      ) : (
        <h1>No questions found</h1>
      )}
    </Layout>
  )
}

export default Faq
