
import ResultInput from "../resultInput/ResultInput";
import {ToggleButton} from "primereact/togglebutton";
import React,{Fragment, useContext, useEffect, useState} from "react";
import Dropdown from "../dropdown/Dropdown";
import {sortArrayOfObj} from "../../helpers/commonHelper";
import {FormDoc} from "../../configs/contexts/formDocument";
import {acseeOptionalSubs, combinationDetails, subjectCombinations, subjects} from "../../data";
import styles from "../../pages/enterMarks/enterMarks.module.scss";
import {InputSwitch} from "primereact/inputswitch";


const AcseeResults= () => {
    const {optionalSubElem, optionalSubHead} = styles
    const {formDoc, setFormDoc} = useContext(FormDoc)
    const [optionalAcseeSubs, setOptionalAcseeSubs] = useState({Subjects: formDoc.marks.data.acsee.filter(s => s.description === 'option')})
    const [acseeOptDropdown, setAcseeOptDropdown] = useState(acseeOptionalSubs)
    const [acseeSubjects, setAcseeSubjects] = useState({Subjects: formDoc.marks.data.acsee.filter(s => s.description === 'core')})
    // const [acseeSubjects, setAcseeSubjects] = useState(null)
    const [optionalVisible, setOptionalVisible] = useState(false)
    const [isAcseeCompetedYear2014_2015s, setIsAcseeCompetedYear2014_2015s]= useState(formDoc.marks.data.isAcseeCompetedYear2014_2015)

useEffect(()=>{
    if(optionalAcseeSubs.Subjects.length>0){
        setOptionalVisible(true)
    }else{
        setOptionalVisible(false)
    }
    let combSubs = [...formDoc.marks.data.acsee.filter(x => x.description === 'core')]
    let optSubs = optionalAcseeSubs.Subjects.map(s=>{
        return {...s, description: 'option'}
    })
    // console.log(optSubs)
    setFormDoc({ ...formDoc, marks: { ...formDoc.marks, data: { ...formDoc.marks.data, acsee: [...combSubs,...optSubs] } } })
},[optionalAcseeSubs])
    // useEffect(()=>{
    //     // console.log()
    //             setAcseeSubjects({Subjects:formDoc.marks.data.acsee})
    //     console.log(acseeSubjects)
    // },[formDoc])

    const handleCombinationChange = newCombination => {
        setAcseeSubjects(combinationDetails?.find(c => c.Name.toLowerCase() === newCombination.toLowerCase()))
        setFormDoc({
            ...formDoc,
            marks: {
                ...formDoc.marks,
                data: {
                    ...formDoc.marks.data,
                    combination: newCombination?.toLowerCase(),
                    acsee: combinationDetails.find(c => c.Name.toLowerCase() === newCombination.toLowerCase())?.Subjects,
                },
            },
        })
    }
    const handleCompletedYearChange = e =>{
        e?.preventDefault()
       setIsAcseeCompetedYear2014_2015s(e.value)
        setFormDoc({
            ...formDoc,
            marks: {
                ...formDoc.marks,
                data: {
                    ...formDoc.marks.data,
                    isAcseeCompetedYear2014_2015: e.value,
                    // acsee: combinationDetails.find(c => c.Name.toLowerCase() === formDoc.marks.data.combination.toLowerCase())?.Subjects,
                },
            },
        })
    }
    const handleOptSubChange = newSub => {
        const selectedSub = subjects.find(s => s.code === newSub)
        let optArr = [...optionalAcseeSubs.Subjects]
        optArr.push(selectedSub)
        const newList = acseeOptDropdown.filter(s => s.value !== newSub)
        setAcseeOptDropdown(newList)
        setOptionalAcseeSubs({Subjects: optArr})
    }
    const onDeleteClick = deletedSub => e => {
        e?.preventDefault()
        const newSub = optionalAcseeSubs.Subjects.filter(s => s.code !== deletedSub)
        setOptionalAcseeSubs({Subjects: newSub})
        const selectedSub = subjects.find(s => s.code === deletedSub)
        let newList = [...acseeOptDropdown]
        newList.push({label: selectedSub.Name, value: selectedSub.code})
        setAcseeOptDropdown(newList)
    }

    return (
        <div className='flex flex-column justify-content-center'>
            <h1>Select Subject Combination</h1>
            <Dropdown
                defaultValue={subjectCombinations?.find(s => s.value?.toLowerCase() === formDoc.marks.data.combination?.toLowerCase())}
                onChange={handleCombinationChange}
                options={sortArrayOfObj(subjectCombinations, 'label')}
            />
            <h2 className='mt-5 text-4xl font-normal'>Enter ACSEE/Form6 Scores</h2>
            <div className='flex justify-content-center'>
                <span className='mr-3'>Completed ACSEE/Form6 Studies in 2014 and 2015</span>
                <InputSwitch tooltip='switch on if you graduated year 2014-2015' checked={isAcseeCompetedYear2014_2015s} onChange={(e)=> handleCompletedYearChange(e)}/>
            </div>
            {/*<ResultInput resultNameHead='Enter ACSEE Scores' askCombination={acseeSubjects}/>*/}
            <ResultInput  askCombination={acseeSubjects}/>
            <div className={optionalSubElem}>
                <ToggleButton
                    checked={optionalVisible}
                    onChange={e => setOptionalVisible(e.value)}
                    onLabel='ACSEE Additional Subjects'
                    offLabel='Add Additional ACSEE Subjects'
                    onIcon='pi pi-book'
                    offIcon='pi pi-book'
                    style={{width: 'fit-content'}}
                />
                {optionalVisible && (
                    <div style={{width: '100%'}}>
                        {optionalAcseeSubs.Subjects.length < 2 && (
                            <Fragment>
                                <h2 className={optionalSubHead}>Add ACSEE Optional Subjects (Max
                                    2)</h2>
                                <Dropdown options={sortArrayOfObj(acseeOptionalSubs, 'label')}
                                          onChange={handleOptSubChange}/>
                            </Fragment>
                        )}
                        {optionalAcseeSubs.Subjects.length > 0 &&
                        <ResultInput onDeleteClick={onDeleteClick}
                                     askCombination={optionalAcseeSubs}/>}
                    </div>
                )}
            </div>
        </div>
    )
}
export default AcseeResults
