// React imports
import {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'

// Component imports
import Layout from '../../components/layout/Layout'
import { TabView,TabPanel } from 'primereact/tabview';

// Context imports
import {FormDoc} from '../../configs/contexts/formDocument'
import {useContext} from 'react'

// UI Kit imports
import {ProgressSpinner} from 'primereact/progressspinner'
import {Button} from 'primereact/button'

// Data imports
import {
    gradeEquivalent,
    institutions,
    possibleAGrades2014_2015,
    programmes as progList,
    programmesWithInstitutions
} from '../../data'
import {getQualifiedPrograms, requestLogSave, verifyPayment} from '../../helpers/ajaxHelper'


// Firebase Imports
import {auth} from '../../configs/firebase/firebase.utils'

// Style imports
import styles from './programResults.module.scss'

import {InstitutesProgrammes} from "../../components/customTable/InstitutesProgrammes";
import AllPrograms  from "../../components/customTable/AllPrograms";
import PaymentProceedDialog from "../../components/paymentProceedDialog";
import {Toast} from "primereact/toast";


const ProgramResults = () => {
    const toast = useRef(null)
    const navigate = useNavigate()
    const [isRequestPaid,setIsRequestPaid] = useState(false)
    const [searchParams,setSearchParams] = useSearchParams()
    const {formDoc, setFormDoc} = useContext(FormDoc)
    const [activeIndex, setActiveIndex] = useState(0);
    const {parentClass} = styles

    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState()
// useEffect(()=>{
//     if(searchParams){
//         verifyPayment(searchParams.get('transId')).then(v=>{
//             setIsRequestPaid(!isRequestPaid)
//         })
//     }
// },[searchParams])
//     useEffect(()=>{
//         verifyPayment(formDoc.lastTransToken).then(v=>{
//             setIsRequestPaid(v)
//         })
//     })

    const fetchResults = async () => {
        let acseeMarks = formDoc.marks.data.acsee.filter(m => m.grade)
        let acseeMarkObj = {}
        // change1
        acseeMarks?.forEach(mark => {
                if (formDoc.marks.data.isAcseeCompetedYear2014_2015) {
                    // console.log(possibleAGrades2014_2015.map((v) => {return {v.label:v.value}}))
                    (acseeMarkObj[mark.ShortName] = possibleAGrades2014_2015.find(value => value.label=== mark.grade).value)
                } else {
                    (acseeMarkObj[mark.ShortName] = gradeEquivalent[mark.grade])
                }
            }
            )
        let cseeMarks = formDoc.marks.data.csee.filter(m => m.grade)
        let cseeMarkObj = {}
        // change2
        cseeMarks?.forEach(mark => (cseeMarkObj[mark.ShortName] = gradeEquivalent[mark.grade]))
        let diplomaObj = {
            gpa: parseFloat(formDoc.marks.data.diploma),
            programmeid: formDoc.marks.data.diplomaProgramme
        }
        let foundationObj = {
            gpa: parseFloat(formDoc.marks.data.foundation),
            programmeid: formDoc.marks.data.foundationProgramme
        }

        try {
            setLoading(true)
            const markDetail = {
                student: {
                    id: auth.currentUser?.uid,
                    lead_qualification: formDoc.leadQualification.data.leadQualification?.toLowerCase(),
                    inst_code: formDoc.preferInstitute.data.instCode
                },
                results: {
                    csee: cseeMarkObj,
                },
            }
            if (Object.values(acseeMarkObj).length > 0 && formDoc.leadQualification.data.leadQualification === 'acsee') {
                markDetail.results['acsee'] = acseeMarkObj
            }
            if (diplomaObj.gpa && formDoc.leadQualification.data.leadQualification === 'diploma') {
                markDetail.results['diploma'] = diplomaObj
            }
            if (foundationObj.gpa && formDoc.leadQualification.data.leadQualification === 'foundation') {
                markDetail.results['foundation'] = foundationObj
            }
            console.log(JSON.stringify(markDetail));
            // console.log(JSON.stringify(markDetail,undefined,2));
            let res =  await getQualifiedPrograms(markDetail)
            if (!res) {
                // console.error(res.message)
                // alert('some error occurred while loading results!' + JSON.stringify(res.message))
                alert('some error occurred while loading results!' + JSON.stringify(res.message))
            } else {
                setLoading(false)
                // let programmes = res.data?.programmes
                let programmes = res.results
                let resArr = []
                programmes?.map(prog => {
                    let resObj = {}
                    // if (typeof prog.subjects === 'string') {
                    //         prog.subjects = [prog.subjects]
                    // }
                    resObj.progName = progList.find(p => p.ProgrammeCode === prog.progcode)?.Programme
                    resObj.instituteName = programmesWithInstitutions.find(p => p.ProgrammeCode === prog.progcode)?.Institution_name
                    // resObj.instituteRegion = institutions.find(async inst => inst.name === await resObj.instituteName )?.instituteRegion
                    resObj.points = prog.points
                    // resObj.subjects = prog.subjects.map(sub => subjects.find(s => s.code === parseInt(sub))?.Name)?.join(', ')
                    resObj.qualReason= prog.qual_reason
                    resObj.chances = percentage(prog.chances)
                   return  resArr.push(resObj)
                })
                // console.log(resArr)
                return resArr
            }
        } catch (error) {
            console.error(error)
            alert('some error occurred while loading results!')
        }
    }
    function percentage(num){
        if(typeof num === "number"){
            const per = num * 100;
            return per.toFixed(0) +'%';
        }else{
            return 'invalid';
        }
    }
    const getPreferInstName2 = () => {
        const icode = formDoc.preferInstitute.data?.instCode
        if(icode==='all'){
            return 'all'
        }else{
            return institutions.find(i => i.code === icode)?.name
        }
    }
    useEffect( () => {
        async function fetchData() {
           // if(isRequestPaid){
               return  fetchResults()
           // }else{
           //     // const transId = searchParams.get('transId')
           //     // if(transId!== undefined){
           //     // return  verifyPayment(transId)
           //     // }
           //   return <PaymentProceedDialog/>
           // }
        }
        fetchData().then((res)=>{
            setResults(res)
            if(res.length>0){
                // toast.current.show({severity:'success', summary: 'Success Message', detail:'Your payment was successful proceeded', life: 3000});
                toast.current.show({severity:'success', summary: 'Success Message', detail:'Your result was successful processed', life: 3000});
                // requestLogSave(auth.currentUser.uid).then(r=>{
                //     console.log('counted')
                // })
                setFormDoc({...formDoc,results: {...formDoc.results, isVisited: true}})
            }else{
                toast.current.show({severity:'error', summary: 'Error Message', detail:'incorrect input, go back and refill the form \n or no internet', life: 3000});
            }
            // setFormDoc({...formDoc, results: {...formDoc.results, isVisited: true}})
        })

    }, [isRequestPaid]);
    return (
        <Layout hideProgress='true' parentClass={parentClass}>
            {/*<Button style={{marginTop: '2%', marginLeft: '2%'}} onClick={() => history.push('/preview')} label='Back'*/}
            {/*        className='p-button-raised p-button-sm'/>*/}
            <Toast ref={toast} />
            <Button onClick={() => navigate('/preview')} label='Back'
                    className='p-button-raised p-button-sm my-3 ml-3'/>
            {/*{ isRequestPaid? <div>*/}
                {loading ? (
                <div className='loader-container'>
                    <ProgressSpinner/>
                </div>
            ) : (
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Grouped Programmes">
                        <div className='res-table'>
                            {/*<ResultTable results={results}/>*/}
                            <InstitutesProgrammes instFilter={getPreferInstName2()} results={results}/>
                        </div>
                    </TabPanel>
                    <TabPanel header="Ungrouped Programmes">
                        <div className='res-table'>
                            {/*<AllPrograms instFilter='Ardhi University (ARU)' results={results}/>*/}
                            <AllPrograms instFilter={getPreferInstName2()} results={results}/>
                            {/*<NoProgrammesInstitute results={results?.filter(r => r.instituteName)}/>*/}
                        </div>
                    </TabPanel>
                </TabView>
                )}
        {/*</div>: <PaymentProceedDialog/>}*/}
        </Layout>
    )
}

export default ProgramResults
