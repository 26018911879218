import {useState} from "react";
import {Sidebar} from "primereact/sidebar";
import {Button} from "primereact/button";
import {Avatar} from "primereact/avatar";
import {Menu} from "primereact/menu";
import {Card} from "primereact/card";
import {possibleAGrades2014_2015, possibleGrades, possibleO_LevelGrades} from "../../data";
import ProgrammesList from "./ProgrammesList";
import SubjectList from "./SubjectList";
import './admin.css'

const AdminLayout = (props) => {
    const [visible, setVisible] = useState(true)
    const [currentIndex, setCurrentIndex] = useState(1)
    const menus = [
        {label: 'Grades', command: () => setCurrentIndex(1)},
        {label: 'Subjects', command:()=> setCurrentIndex(4)},
        {label: 'Institutes & Programs', command: () => setCurrentIndex(2)},
        {label: 'FAQs', command: () => setCurrentIndex(3)}
    ]
    const AlavelGrade = () => {
        return possibleGrades.map(v => {
            return (<div>
                <h3>{v.label}</h3>
                <h3>{v.value}</h3>
            </div>)
        })
    }
    return (
        <div>
            {/*<Sidebar visible={visible} onHide={() => setVisible(false)}>*/}
            {/*    <Menu model={menus}/>*/}
            {/*</Sidebar>*/}
            <div className='flex justify-content-between p-2 bg-blue-200 '>
                <Button className='ml-2' icon="pi pi-bars" onClick={(e) => setVisible(!visible)}/>
                <div>
                    <Avatar icon='pi pi-user' className='p-mr-2' size='large'
                            style={{cursor: 'pointer', backgroundColor: '#2196F3', color: '#ffffff'}} shape='circle'/>
                </div>
            </div>
            <div className='grid'>
                <aside hidden={visible} className='col-2 p-3 mt-2 bg-gray-50 w-max min-h-screen'>
                    <Menu className='adminMenu block' model={menus}/>
                </aside>
                {/*content*/}
                <div className='col'>

                    {currentIndex === 1 && <div className='flex p-2 justify-content-between flex-column sm:flex-row'>
                        <Card className='' title='ACSEE/Form6 Grades'>
                            {possibleGrades.map(v => {
                                return (<div className='flex justify-content-between' key={v.label}>
                                    <h3>{v.label}</h3>
                                    <h3>{v.value}</h3>
                                </div>)
                            })}
                        </Card>
                        <Card className='' title='ACSEE/Form6 Grades(2014-2015)'>
                            {possibleAGrades2014_2015.map(v => {
                                return (<div className='flex justify-content-between' key={v.label}>
                                    <h3>{v.label}</h3>
                                    <h3>{v.value}</h3>
                                </div>)
                            })}
                        </Card>
                        <Card className='' title='CSEE/Form4 Grades'>
                            {possibleO_LevelGrades.map(v => {
                                return (<div className='flex justify-content-between' key={v.label}>
                                    <h3>{v.label}</h3>
                                    <h3>{v.value}</h3>
                                </div>)
                            })}
                        </Card>
                    </div>}
                    {currentIndex === 2 && <div>
                        <ProgrammesList/>
                    </div>}
                    {currentIndex === 4 && <div>
                        <SubjectList/>
                    </div>}
                </div>
            </div>
            <footer className='bottom-0 '>
                <div className='flex justify-content-center'>
                    <span>Copy right by AiGi Systems Co Ltd From 2021</span>
                </div>
            </footer>
        </div>
    )
}
export default AdminLayout
