// Routing imports
import { useNavigate, Link } from 'react-router-dom'

// Style imports
import styles from './header.module.scss'

// Component imports
import ProgressBar from '../progressBar/ProgressBar'
import SignOut from '../signout/Signout'

// UI kit imports
import { Avatar } from 'primereact/avatar'

// Firebase Imports
import { auth } from '../../configs/firebase/firebase.utils'
import { useAuthState } from 'react-firebase-hooks/auth'

const Header = props => {
  const { headerElem, headerLogo, headerNav, container, progressElem, avatarContainer } = styles
  const [user] = useAuthState(auth)
  const navigate = useNavigate()
  return (
    <header className={headerElem}>
      <nav className={headerNav}>
        <div className={container}>
          <Link to='/'>
            <img className={headerLogo} src='ar-logo-light2.png' alt='logo' width='200' />
          </Link>
          {/*{!props.hideProgress && (*/}
          {/*  <div className={progressElem}>*/}
          {/*    <ProgressBar />*/}
          {/*  </div>*/}
          {/*)}*/}
          {user &&
            (props.showSignout ? (
              <SignOut className={props.signOutClass} />
            ) : (
              <div onClick={() => navigate('/user-profile')} className={avatarContainer + ' avatar-container'}>
                {/*<Avatar icon='pi pi-user' className='p-mr-2' size='large' style={{ cursor: 'pointer', backgroundColor: '#2196F3', color: '#ffffff' }} shape='circle' />*/}
                {user.photoURL?<Avatar  image={user.photoURL} size='large' shape='circle'/>:
                    <Avatar icon='pi pi-user' className='p-mr-2' size='small' style={{ cursor: 'pointer', backgroundColor: '#2196F3', color: '#ffffff' }} shape='circle' />
                }
              </div>
            ))}
        </div>
      </nav>
    </header>
  )
}

export default Header
