// React imports
import React from 'react'

// Component imports
import Header from '../header/Header'
import Footer from '../footer/Footer'

// Style imports
import styles from './layout.module.scss'
// import { Outlet } from "react-router-dom";
const Layout = props => {
  const { layoutElem,footerClass } = styles
  return (
    <div className={props.parentClass + ' ' + layoutElem}>
      <Header {...props} />
        {props.children}
        {!props.hideFooter && <Footer className={footerClass} />}
    </div>

  )
}

export default Layout
