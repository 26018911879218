// Component imports
import Layout from '../../components/layout/Layout'

// Style imports
import styles from './contactUs.module.scss'

// UI kit imports
import {Button} from 'primereact/button'

// Routing imports
import {useNavigate} from 'react-router-dom'

const ContactUs = () => {
    const {contactEl, details, map, content, contactInfo} = styles
    const navigate = useNavigate()

    return (
        <Layout parentClass={contactEl} hideProgress hideFooter>
            <div>
                <Button onClick={() => navigate(-1)} label='Back' className='p-button-raised p-button-lg'/>
            </div>
            <h1>Contact Us</h1>
            <h2> Want to say hello? Reach us at following details</h2>
            <div className={content}>
                <div className={details}>
                    <h3>About Admirec:</h3>
                    <p>Admission Recommender is a web-based system as a one stop for analyzing user’s qualifications
                        against admission criteria from all higher learning institutions.</p>
                    <div className={contactInfo}>
                        <div>
                            <h3>Address:</h3>
                            <address>
                                Goba, <br/>
                                Dar es Salaam, <br/>
                                Tanzania
                            </address>
                        </div>
                        <div>
                            <h3>Phone numbers:</h3>
                            {/*<a href='tel:+255768013224'>+255768013224</a>*/}
                            {/*<br/>*/}
                            <a href='tel:+255764333702'>+255782829990(Whatsapp)</a>
                            <br/>
                            <a href='tel:+255654171168'>+255654171168</a>
                            <br/>
                        </div>
                        <div>
                            <h3>Social Media:</h3>
                            <div>
                                <a href='mailto:'><i className='pi pi-envelope'/></a>
                                {/*<a href=''><i className='pi pi-facebook'/></a>*/}
                                {/*<a href='#'><i className='pi pi-twitter'/></a>*/}
                                {/*<a href='#'><i className='pi pi-youtube'/></a>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={map}>
                    <iframe
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31698.748536455543!2d39.132411851598036!3d-6.727868688717174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c5a7a3f3b7d8d%3A0xcf9b6780f73d2dbe!2sGoba%2C%20Dar%20es%20Salaam%2C%20Tanzania!5e0!3m2!1sen!2sin!4v1629162276540!5m2!1sen!2sin'
                        height='450'
                        style={{border: 0}}
                        title={'find us here!!'}
                        loading='lazy'
                    />
                </div>
            </div>
        </Layout>
    )
}

export default ContactUs
