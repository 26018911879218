// React imports
import {useEffect, useState} from 'react'

// Routing imports
import { useNavigate } from 'react-router-dom'

// Component imports
import Layout from '../../components/layout/Layout'

// Style imports
import styles from './preview.module.scss'

// Context imports
import { FormDoc } from '../../configs/contexts/formDocument'
import { useContext } from 'react'

// UI Kit imports
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'

// Data imports
import {subjectCombinations, foundationProgOpts, diplomaProgOpts, institutions} from '../../data'
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";

const Preview = () => {
  const { formDoc, setFormDoc } = useContext(FormDoc)
  const [visible,setVisible]=useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    setFormDoc({ ...formDoc, preview: { ...formDoc.preview, isVisited: true } })
  },[] )

  const { parentClass, container, content, row, buttons, headRow, markElemBox, markCol, markRow, progRowElem } = styles

  const handleNextBackClick = async e => {
    e?.preventDefault()
    const path = e.currentTarget.textContent === 'Back' ? '/enter-marks' : '/program-results'
    if(formDoc.lastTransToken === ''){
      navigate(path)
    }else if(path==='/program-results') {
      navigate(path)
    } else {
      setVisible(true)
    }

  }
  const accept = () => {
    setFormDoc({...formDoc,lastTransToken:''})
    const path = '/enter-marks'
    navigate(path)
  }

  const editIconClick = () => navigate('/enter-marks')
  const getPreferInstName = () => {
    const icode = formDoc.preferInstitute.data?.instCode
    if(icode==='all'){
      return 'all'
    }else{
      return institutions.find(i => i.code === icode)?.name
    }
  }

  return (
    <Layout parentClass={parentClass}>
      {/*<ConfirmDialog visible={visible} onHide={() => setVisible(false)} message="Your previous payment will be lost. Are you sure you want to proceed?"*/}
      {/*               header="Confirmation" icon="pi pi-exclamation-triangle" accept={accept}  />*/}
      <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message="Your previous result will be lost. Are you sure you want to proceed?"
                     header="Confirmation" icon="pi pi-exclamation-triangle" accept={accept}  />

      <div className={container}>
        <div className={content}>
          <div>
            <div className={headRow}>
              {formDoc.leadQualification.data.leadQualification && (
                <div className={row}>
                  <h1>Lead Qualification</h1>
                  <h2>{formDoc.leadQualification.data.leadQualification.toUpperCase()}</h2>
                  <i className='pi pi-pencil' onClick={() => navidate('/qualified-programmes')}/>
                </div>
              )}
              {formDoc.leadQualification.data.leadQualification === 'acsee' && (
                <>
                  <div className={row}>
                    <h1>Subject Combinations</h1>
                    <h2>{subjectCombinations.find(s => s.value.toLowerCase() === formDoc.marks.data.combination?.toLowerCase())?.label}</h2>
                    <i className='pi pi-pencil' onClick={editIconClick}/>
                  </div>
                </>
              )}
              {formDoc.leadQualification.data.leadQualification === 'diploma' && (
                <>
                  <div className={row}>
                    <h1>Diploma GPA</h1>
                    <h2>{formDoc.marks.data.diploma}</h2>
                    <i className='pi pi-pencil' onClick={editIconClick}/>
                  </div>
                </>
              )}
              {formDoc.leadQualification.data.leadQualification === 'foundation' && (
                <>
                  <div className={row}>
                    <h1>Foundation GPA</h1>
                    <h2>{formDoc.marks.data.foundation}</h2>
                    <i className='pi pi-pencil' onClick={editIconClick}/>
                  </div>
                </>
              )}
              <div className={row}>
                <h1>Target Institution:</h1>
                <h2>{getPreferInstName()}</h2>
              </div>
            </div>
            <Divider />
            {formDoc.leadQualification.data.leadQualification === 'foundation' && (
              <div className={row + ' ' + progRowElem}>
                <h1>Programme</h1>
                <h2>{foundationProgOpts.find(p => p.value.toLowerCase() === formDoc.marks.data.foundationProgramme?.toLowerCase())?.label}</h2>
                <i className='pi pi-pencil' onClick={editIconClick}/>
              </div>
            )}
            {formDoc.leadQualification.data.leadQualification === 'diploma' && (
              <div className={row + ' ' + progRowElem}>
                <h1>Programme</h1>
                <h2>{diplomaProgOpts.find(p => p.value.toLowerCase() === formDoc.marks.data.diplomaProgramme?.toLowerCase())?.label}</h2>
                <i className='pi pi-pencil' onClick={editIconClick}/>
              </div>
            )}
            <div className={markElemBox}>
              {formDoc.leadQualification.data.leadQualification !== 'diploma' &&
                formDoc.leadQualification.data.leadQualification !== 'foundation' &&
                formDoc.marks.data.acsee.filter(x => x.grade).length !== 0 && (
                  <Card>
                    <div className={markCol}>
                      <h2>ACSEE Scores</h2>
                      {formDoc.marks.data.acsee
                        .filter(x => x.grade)
                        .map((subject, index) => (
                          <div className={markRow} key={index + 'acsee'}>
                            <h3>{subject.Name}</h3>
                            <p>{subject.grade}</p>
                          </div>
                        ))}
                    </div>
                  </Card>
                )}
              {formDoc.marks.data.csee.filter(x => x.grade).length !== 0 && (
                <Card>
                  <div className={markCol}>
                    <h2>CSEE Scores</h2>
                    {formDoc.marks.data.csee
                      .filter(x => x.grade)
                      .map((subject, index) => (
                        <div className={markRow} key={index + 'acsee'}>
                          <h3>{subject.Name}</h3>
                          <p>{subject.grade}</p>
                        </div>
                      ))}
                  </div>
                </Card>
              )}
            </div>
          </div>
          <div className={buttons}>
            <Button onClick={handleNextBackClick} label='Back' className='p-button-raised p-button-lg' />
            <Button onClick={handleNextBackClick} label='Next' className='p-button-raised p-button-lg' />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Preview
