// Firebase Imports
import { auth } from '../configs/firebase/firebase.utils'
import { useAuthState } from 'react-firebase-hooks/auth'

// React Imports
import { useNavigate } from 'react-router-dom'

// UI Kit imports
import { ProgressSpinner } from 'primereact/progressspinner';

const Authenticate = props => {
  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()
  if (loading) {
    return (
      <div className='loader-container'>
        <ProgressSpinner />
      </div>
    )
  }
  if (error) {
    return (
      <div className='loader-container'>
        <p>Error: {error}</p>
      </div>
    )
  }
  if (user) {
    if (props.pathOnSuccess) {
      navigate(props.pathOnSuccess)
    } else {
      if (props.successCallback) {
        props.successCallback(user)
      }
      return props.children
    }
  } else {
    if (props.renderChildOnFail) {
      return props.children
    }else {
      navigate(props.pathOnFail || '/login')
    }
  }
  return <div/>
}

export default Authenticate
