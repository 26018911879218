import {Card} from "primereact/card";
import styles from './login.module.scss'
import './newlogin.css'
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

const NewLogin = () =>{
 const {loginCard} = styles

    return (
        <div className='flex justify-content-center'>
            <Card className='loginCard' title='SignIn'>
                <div>
                    <div>
                        <h4 align='center'>Using phone</h4>
                        <br/>
                        <div className="field">
                            <label htmlFor="firstname1">Enter Your Phone Number</label>
                            <InputText id="firstname1" type="text" className="inputfield w-full"/>
                        </div>
                        <Button className='p-button-sm' label='Get OTP' icon='pi pi-check' />
                    </div>
                </div>
            </Card>
        </div>
    )
}
export default NewLogin
