// React imports
import { useNavigate } from 'react-router-dom'

// Style imports
import styles from './home.module.scss'

// UI Kit imports
import { Button } from 'primereact/button'

//Component imports
import Footer from '../../components/footer/Footer'
import Header from "../../components/header/Header";
import {Avatar} from "primereact/avatar";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../configs/firebase/firebase.utils";

const Home = () => {
  const { homePage, card, homeLogo, buttons, footerClass } = styles
  const [user] = useAuthState(auth)
  const navigate = useNavigate()

  const handleClick = name => e => {
    e?.preventDefault()
    const path = name === 'li' ? '/learning-institutions' : '/qualified-programmes'
    navigate(path)
  }
  return (
      <div>
        {/*<div className='flex justify-content-end p-3'>*/}
        {/*  <div>*/}
        {/*    {user? (*/}
        {/*        <div onClick={() => navigate('/user-profile')} >*/}
        {/*          {user.photoURL?<Avatar  image={user.photoURL} size='large' shape='circle'/>:*/}
        {/*          <Avatar icon='pi pi-user' className='p-mr-2' size='small' style={{ cursor: 'pointer', backgroundColor: '#2196F3', color: '#ffffff' }} shape='circle' />*/}
        {/*          }*/}
        {/*        </div>):*/}
        {/*        <Button className='p-button-outlined' label='Login' onClick={() => navigate('/login')}/>*/}
        {/*    }*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<Header hideProgress/>*/}

    <div className={homePage}>
      <div className={card}>
        <img className={homeLogo} src={'ar-logo-light2.png'} alt='logo' width='250'  />
        <div style={{ textAlign: 'center' }}>
          <h2>
            Just 4 <span>Simple</span> Steps
          </h2>
          <ol>
            <li>Select target Institution(s)</li>
            <li>Select entry qualification</li>
            <li>Enter and preview scores</li>
            <li>Check my likely programmes</li>
          </ol>
        </div>

        <div className={buttons}>
          <Button label='View higher learning institutions' onClick={handleClick('li')} icon='pi pi-check' className='p-button' />
          {/*<Button label='Check likely to qualify programmes' onClick={handleClick('qp')} icon='pi pi-check' className='p-button' />*/}
          <Button label='Get Start' onClick={handleClick('qp')} icon='pi pi-arrow-right' className='p-button' />
        </div>
      </div>
      <Footer className={footerClass} />
    </div>
      </div>
  )
}

export default Home
