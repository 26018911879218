import {useState} from "react";
import {acseeSubject, combinationDetails, cseeSubjects} from "../../data";
import { TabView, TabPanel } from 'primereact/tabview';
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";

const SubjectList =()=>{
    const [a_subjects,setA_subject] = useState(acseeSubject)
    const [o_subjects,setO_sujects] = useState(cseeSubjects)
    const [activeIndex,setActiveIndex]= useState(0)
    const [a_combination,setA_combination] =useState(combinationDetails)

 const combinationSubjectsTemplete = (rowData) => {
   return rowData.Subjects.map(d=>{
       return (<div>{d.Name}</div>)
       }
   )
 }
    return (
        <div className='mt-2'>

        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header="A-LEVEL Subject">
                <h3>total No of A-level Sujects: {a_subjects.length}</h3>
                <DataTable value={a_subjects}
                           header='list of A-level/ACSEE subjects'
                           className='p-datatable-sm'
                           // columnResizeMode='fit'
                           // showGridlines
                           autoLayout
                >
                    {/*<Column header="#" body={(p) =>{ return a_subjects.indexOf(p)+1}}/>*/}
                    <Column field="code" header="Code" sortable
                    />
                    <Column field="Name" header="Name"
                            sortable
                    />
                    <Column field="ShortName" header="Short Name"
                            sortable
                    />
                </DataTable>
            </TabPanel>
            <TabPanel header="O-LEVEL Subjects">
                <h3>total No of O-level Sujects: {o_subjects.length}</h3>
                <DataTable value={o_subjects}
                           header='list of O-level/CSEE subjects'
                           className='p-datatable-sm'
                           // columnResizeMode='fit'
                           // showGridlines
                           autoLayout
                >
                    {/*<Column header="#" body={(p) =>{ return o_subjects.indexOf(p)+1}}/>*/}
                    <Column field="code" header="Code" sortable
                    />
                    <Column field="Name" header="Name"
                            sortable
                    />
                    <Column field="ShortName" header="Short Name"
                            sortable
                    />
                </DataTable>
            </TabPanel>
            <TabPanel header="A-Level Combination">
                <h3>total No of O-level Sujects: {o_subjects.length}</h3>
                <DataTable value={a_combination}
                           header='list of ACSEE Combination'
                           className='p-datatable-sm'
                           autoLayout
                >
                    <Column header="#" body={(p) =>{ return a_combination.indexOf(p)+1}}/>
                    <Column field="Name" header="Name" sortable
                    />
                    <Column field="Category" header="Category"
                            sortable
                    />
                    <Column field="Subjects" header="Subjects" body={combinationSubjectsTemplete}
                            sortable
                    />
                </DataTable>
            </TabPanel>
        </TabView>
        </div>
    )
}
export default SubjectList
