import { createContext } from 'react'
import { cseeSubjects } from '../../data'

export const FormDoc = createContext(null)

export const formDocument = {
  lastTransToken:'',
  preferInstitute:{
    label: 'Select prefer Institute',
    data: {instCode: 'all'},
    isVisited: false
  },
  leadQualification: {
    label: 'Select Lead Qualification',
    data: { leadQualification: '' },
    isVisited: false,
  },
  marks: {
    label: 'Enter Marks',
    data: {
      combination: '',
      isAcseeCompetedYear2014_2015: false,
      cseeTrack: '',
      acsee: [],
      csee: cseeSubjects.filter(s => s.Track === 'core'),
      diploma: '',
      foundation: '',
      foundationProgramme: '',
      diplomaProgramme: '',
    },
    isVisited: false,
  },
  preview: {
    label: 'Preview Your Entries',
    isVisited: false,
  },
  results: {
    label: 'Get Results',
    data: {},
    isVisited: false,
  },
}
