import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const config = {
  apiKey: 'AIzaSyAAmArzkf78hRpnCCPTUKYjm8J-aFc0izc',
  authDomain: 'admirec-b2be3.firebaseapp.com',
  // authDomain: 'admirec.web.app',
  projectId: 'admirec-b2be3',
  storageBucket: 'admirec-b2be3.appspot.com',
  messagingSenderId: '729311741258',
  appId: '1:729311741258:web:e4437cf3c12bff5e992db3',
  measurementId: 'G-EY0L0Z1Z2Q',
}

// Initialize Firebase
const app = initializeApp(config,'admirec')

export const auth = getAuth(app)

export const firestore = getFirestore(app)

// export default firebase

