// React imports
import {useEffect} from 'react'

// Context Imports
import {useContext} from 'react'
import {FormDoc} from '../../configs/contexts/formDocument'

// Routing imports
import { useNavigate} from 'react-router-dom'

// UI Kit imports
import {Button} from 'primereact/button'

// Component imports
import Layout from '../../components/layout/Layout'
import Dropdown from '../../components/dropdown/Dropdown'
import InputBox from '../../components/input/InputBox'
import CseeResults from '../../components/cseeResults/CseeResults'
import {sortArrayOfObj} from '../../helpers/commonHelper'
import AcseeResults from '../../components/acseeResults/AcseeResults'

// Style imports
import styles from './enterMarks.module.scss'

// Data imports
import {
    foundationProgOpts,
    diplomaProgOpts, institutions
} from '../../data'

const EnterMarks = () => {
    const {parentClass, container, buttons, content, contentDiv} = styles
    const {formDoc, setFormDoc} = useContext(FormDoc)
    const navigate = useNavigate()

    useEffect(() => {
        formDoc.marks.isVisited = true
        setFormDoc({...formDoc})
    },[] )


    const handleNextBackClick = e => {
        e?.preventDefault()
        const path = e.currentTarget.textContent === 'Back' ? '/qualified-programmes' : '/preview'
        navigate(path)
    }

    const handleFoundationProgChange = newCombination => {
        setFormDoc({
            ...formDoc,
            marks: {
                ...formDoc.marks,
                data: {
                    ...formDoc.marks.data,
                    foundationProgramme: newCombination?.toLowerCase(),
                },
            },
        })
    }
    const handleDiplomaProgChange = newCombination => {
        setFormDoc({
            ...formDoc,
            marks: {
                ...formDoc.marks,
                data: {
                    ...formDoc.marks.data,
                    diplomaProgramme: newCombination?.toLowerCase(),
                },
            },
        })
    }

    const handleInputChange = inputName => inputValue => {
        setFormDoc({...formDoc, marks: {...formDoc.marks, data: {...formDoc.marks.data, [inputName]: inputValue}}})
    }

const instlist = () => {
const i = sortArrayOfObj(institutions, 'name').map(v => {
    return {label: v.name, value: v.code}
  })
  return [{label:'All', value:'all'}, ...i]
}

    const handleInstituteChange =  instcode => {
        setFormDoc({
            ...formDoc,
            preferInstitute:{
                ...formDoc.preferInstitute,
                data:{instCode: instcode}
            }
        })
    }
    return (
    <Layout parentClass={parentClass}>
        <div className={container}>
            <div className={content}>
                <div className='mt-4' style={{width: '60vw'}}>
                    <h1>Select Target Institution</h1>
                    <Dropdown defaultValue={instlist()?.find(i => i.value === formDoc.preferInstitute.data.instCode)}
                              onChange={handleInstituteChange}
                              options={instlist()}/>
                </div>
                {formDoc.leadQualification.data.leadQualification?.toLowerCase() === 'acsee' ? (
                    <div className={contentDiv}>
                                <AcseeResults/>
                                <CseeResults/>
                    </div>
                ) : formDoc.leadQualification.data.leadQualification?.toLowerCase() === 'diploma' ? (
                    <div>
                        <h1>Enter Diploma Scores</h1>
                        <InputBox initValue={formDoc.marks.data.diploma} onChange={handleInputChange('diploma')}
                                  type='num' placeholder='Enter Diploma GPA' id='diploma-marks'/>
                        <h2>Select Diploma Programme</h2>
                        <Dropdown
                            defaultValue={diplomaProgOpts?.find(f => f.value?.toLowerCase() === formDoc.marks.data.diplomaProgramme?.toLowerCase())}
                            onChange={handleDiplomaProgChange}
                            options={sortArrayOfObj(diplomaProgOpts, 'label')}
                        />
                        <CseeResults/>
                    </div>
                ) : (
                    <div>
                        <h1>Enter Foundation Scores</h1>
                        <h2>Enter Foundation GPA</h2>
                        <InputBox initValue={formDoc.marks.data.foundation}
                                  onChange={handleInputChange('foundation')} type='num'
                                  placeholder='Enter Foundation GPA' id='foundation-marks'/>
                        <h2>Select Foundation Programme</h2>
                        <Dropdown
                            defaultValue={foundationProgOpts?.find(f => f.value?.toLowerCase() === formDoc.marks.data.foundationProgramme?.toLowerCase())}
                            onChange={handleFoundationProgChange}
                            options={sortArrayOfObj(foundationProgOpts, 'label')}
                        />
                        <CseeResults/>
                    </div>
                )}
                <div className={buttons}>
                    <Button onClick={handleNextBackClick} label='Back' className='p-button-raised p-button-lg'/>
                    <Button
                        onClick={handleNextBackClick}
                        label='Next'
                        className='p-button-raised p-button-lg'
                        disabled={
                            formDoc.leadQualification.data.leadQualification?.toLowerCase() === 'acsee'
                                ? formDoc.marks.data.acsee.filter(s => s.type !== 'acsee').filter(s => s.grade).length < 4
                                : formDoc.leadQualification.data.leadQualification?.toLowerCase() === 'diploma'
                                    ? !(formDoc.marks.data.diploma && formDoc.marks.data.diplomaProgramme)
                                    : !(formDoc.marks.data.foundation && formDoc.marks.data.foundationProgramme)
                        }
                    />
                </div>
            </div>
        </div>
    </Layout>
)
}

export default EnterMarks
