import {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {institutionsWithProgrammes} from "../../data";
import {Card} from "primereact/card";
import './admin.css'


const ProgrammesList =(props)=>{
    const [instituteProgrammes,setInstituteProgrammes] = useState([])
    const [expandedRows,setExpandedRows]  = useState(null)

    const totalProgrammes = () => {
       let programmes = []
       institutionsWithProgrammes.forEach(value => value.programmes.forEach(v => programmes.push(v)))
        return programmes.length
    }
    useEffect(()=>{
        setInstituteProgrammes(institutionsWithProgrammes)
    })
    const programmesListTemp =(data)=>{
        console.log(data)
       return ( <div>
            <h5>Avalilable Programmmes in {data.name}</h5>
            <DataTable value={data.programmes}
                       columnResizeMode='fit'
                       showGridlines
                       autoLayout
            >
                <Column header="#" body={(p) =>{ return data.programmes.indexOf(p)+1}}/>
                <Column field="ProgrammeCode" header="Code" sortable
                />
                <Column field="Programme" header="Name"
                        sortable
                />
            </DataTable>
        </div>)
    }
    return (
        <div>
            <div className='flex p-3 justify-content-between'>
                <div className='border-0 shadow-2 border-round p-3 flex flex-column align-items-center'>
                    <h3>Total number Institutes</h3>
                    <h1>{instituteProgrammes.length}</h1></div>
                <div className='border-0 shadow-2 border-round p-3 flex flex-column align-items-center'>
                    <h3>Total number of programs</h3>
                    <h1>{totalProgrammes()}</h1></div>
            </div>
            <DataTable
            value={instituteProgrammes}
            header='List of Institutions With their programmes'
            expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={programmesListTemp} dataKey='id'
            className='p-datatable-sm p-3'
            showGridlines
            autoLayout
            paginator
            paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
            rows={10}
            rowsPerPageOptions={[10, 20, 50, instituteProgrammes.length]}
            >
                <Column header="#" let-i="rowindex"
                        body={(data) =>{ return institutionsWithProgrammes.indexOf(data)+1}}/>
                <Column expander style={{ width: '3em' }} />
                <Column field='name'
                        header='Name'
                        sortable/>
                <Column field='region_name'
                        header='Region'
                        sortable
                       />
                <Column field='ownership'
                        header='Ownership'
                        sortable
                       />
                <Column field='institution_type'
                        header='Institute Type'
                        sortable
                        />
                <Column field='totalProgCount'
                        header='No Of Programmes'
                        sortable
                />
            </DataTable>
        </div>
    )
}
export default ProgrammesList
