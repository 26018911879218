// Component imports
import Layout from '../../components/layout/Layout'

// Style imports
import styles from './userProfile.module.scss'

// Routing imports
import { useNavigate } from 'react-router-dom'

// UI Kit imports
import { Button } from 'primereact/button'
import { auth } from '../../configs/firebase/firebase.utils'
import UserInfo from "../../components/userInfo";

const UserProfile = () => {
  const { parentClass, signOutClass, container, details, detail } = styles
  const navigate = useNavigate()
  return (
    <Layout parentClass={parentClass} hideProgress showSignout signOutClass={signOutClass}>
      <div className={container}>
        <div>
          <Button onClick={() => navigate(-1)} label='Back' className='p-button-raised p-button-sm' />
        </div>
        <div className={details}>
          <UserInfo/>
          {/*{auth.currentUser.displayName &&*/}
          {/*    <div className='grid'>*/}
          {/*      <div className='col-3'><strong>Name:</strong></div>*/}
          {/*      <div className='col-4'>{auth.currentUser.displayName}</div>*/}
          {/*    </div>*/}
          {/*}*/}
          {/*{auth.currentUser.phoneNumber &&*/}
          {/*  <div className='grid'>*/}
          {/*    <div className='col-3'><strong>Phone Number:</strong></div>*/}
          {/*    <div className='col-4'>{auth.currentUser?.phoneNumber}</div>*/}
          {/*  </div>*/}
          {/*}*/}
          {/*{auth.currentUser.email&&(*/}
          {/*    <div className='grid'>*/}
          {/*      <div className='col-3'><strong>Email:</strong></div>*/}
          {/*      <div className='col-4 '>{auth.currentUser.email}</div>*/}
          {/*    </div>*/}
          {/*)}*/}
        </div>
      </div>
    </Layout>
  )
}

export default UserProfile
