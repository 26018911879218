import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import {auth} from "../../configs/firebase/firebase.utils";

export class InstitutesProgrammes extends React.Component {

    constructor(props) {
        super(props);
        this.isComponentMounted= true;
        this.state = {
            results: [],
            expandedRows: null,
            totalProgramme: 0
        };
        this.groupedByInstitute = this.groupedByInstitute.bind(this);
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.searchBodyTemplate = this.searchBodyTemplate.bind(this);
        this.getTotalProgrammes = this.getTotalProgrammes.bind(this);
    }

    componentDidMount() {
        // this.isComponentMounted = true;
        if(this.props.instFilter === 'all'){
            setTimeout(()=> {
            if(this.isComponentMounted){
                    this.setState({results: this.groupedByInstitute()})
            }
            },1000)
        }else{
            setTimeout(()=> {
                    if(this.isComponentMounted){
                        this.setState({results: this.groupedByInstitute().filter((inst)=> inst.instname === this.props.instFilter)})
            }}, 1000)
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
    }
    componentWillUnmount() {
        this.isComponentMounted=false
    }

   groupedByInstitute(){
        let newIns = []
       let results2 = this.props.results?.filter(r => r.instituteName);
        results2?.map(v => {
            return newIns.push(v.instituteName);
        })
        let instWithNoDuplicates2 = Array.from(new Set(newIns));
        let listOfInst = [];
        instWithNoDuplicates2.forEach(v=>{
            let inst = {}
            inst.instname = v;
            inst.progCount = results2.filter(pr => pr.instituteName === v).length
            inst.progs = results2.filter(pr => pr.instituteName === v)
            listOfInst.push(inst);
        })

        return listOfInst;
    }
  getTotalProgrammes(){
        return  this.state.results.reduce((prev,curr)=> prev+curr.progCount,0)
  }

    searchBodyTemplate() {
        return <Button icon="pi pi-search" />;
    }

   getIndex(instname){
        return  this.groupedByInstitute().map(list => list.instname).indexOf(instname);
   }

    rowExpansionTemplate(data) {
        return (
            <div>
                <h5>Likely Programmmes in {data.instname}</h5>
                <DataTable value={data.progs}
                           columnResizeMode='fit'
                           showGridlines
                           responsiveLayout="scroll"
                           autoLayout
                >
                    <Column header="#" body={(p) =>{ return data.progs.indexOf(p)+1}} sortable/>
                    <Column field="progName" header="Programme" sortable
                            headerStyle={{ width: '50%' }}
                    />
                    <Column field="qualReason" header="Reason"
                            sortable
                            headerStyle={{ width: '20%' }}
                    />
                    <Column field="chances" header="Likelihood" sortable
                            headerStyle={{ width: '20%' }}
                    />
                </DataTable>
            </div>
        );
    }

    render() {
        // const header = (
        //     <div className="table-header-container">
        //         <Button icon="pi pi-plus" label="Expand All" onClick={this.expandAll} className="p-mr-2" />
        //         <Button icon="pi pi-minus" label="Collapse All" onClick={this.collapseAll} />
        //     </div>
        // );

        return (
            // <div className="datatable-rowexpansion-demo">
                <div>
                    {/*<Card header={this.header} title={<h5 className='p-mb-3 redtext'>User: {auth.currentUser?.phoneNumber}</h5>}*/}
                    {/*      subTitle={<span>total programmes: {this.getTotalProgrammes()}</span>}/>*/}
                    <div className='p-2 border-0 border-round bg-blue-50'>
                        <h5 className='p-mb-3 redtext'>User: {auth.currentUser?.phoneNumber}</h5>
                        <span>total likely programmes: {this.getTotalProgrammes()}</span>
                    </div>
                    <DataTable value={this.state.results} expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                               rowExpansionTemplate={this.rowExpansionTemplate} dataKey="instname"
                               className='p-datatable-sm pt-2'
                               filterDisplay="row"
                               // resizableColumns
                               columnResizeMode='fit'
                               showGridlines
                               responsiveLayout="scroll"
                               autoLayout
                               paginator
                               paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                               currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
                               rows={10}
                               // rowsPerPageOptions={[10, 20, 50,this.groupedByInstitute(this.props.results?.filter(r => r.instituteName))?.length]}
                    >

                        <Column expander
                                style={{ width: '3em' }}
                        />
                        <Column field="instname" header="Institution"
                                // className='flex-grow-1 flex align-items-center justify-content-center'
                                // headerStyle={{ width: '70%' }}
                                style={{ minWidth: '17rem' }}
                                filter filterPlaceholder="Search by " filterMatchMode={'contains'}  sortable />
                        <Column field="progCount" header="Num of Likely Programmes"
                                headerStyle={{ width: '20%' }}
                                sortable />
                    </DataTable>

            </div>
        );
    }
}
