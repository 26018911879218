// Context imports
import {useContext} from 'react'
import { FormDoc } from '../../configs/contexts/formDocument'

// Component imports
import GradeInput from '../gradeInput/GradeInput'

// Style imports
import  './resultInput.module.scss'
import styles from "../resultInput/resultInput.module.scss";


const ResultInput = props => {
  const { askCombination, resultType } = props
  const { resultInputElem, subjInpPair, resultNameHeadElem } = styles

  // const { resultInputElem, subjInpPair, resultNameHeadElem } = styles

  const { formDoc, setFormDoc } = useContext(FormDoc)

  const saveMarks = subject => grade => {
    if (resultType === 'csee') {
      let subs = JSON.parse(JSON.stringify(formDoc.marks.data.csee))
      let currObj = subs.find(s => s.code === subject)
      // let currObj = subs.find(s => s.ShortName === subject)
      if (currObj) currObj.grade = grade
      setFormDoc({ ...formDoc, marks: { ...formDoc.marks, data: { ...formDoc.marks.data, csee: subs } } })
    } else {
      let subWithGrades = JSON.parse(JSON.stringify(formDoc.marks.data.acsee))
      let currSubs = subWithGrades?.length ? JSON.parse(JSON.stringify(subWithGrades)) : askCombination?.Subjects
      let currObj = currSubs?.find(x => x.code === subject)
      // let currObj = currSubs?.find(x => x.ShortName === subject)
      if (currObj) currObj.grade = grade
      setFormDoc({ ...formDoc, marks: { ...formDoc.marks, data: { ...formDoc.marks.data, acsee: currSubs } } })
    }
  }

  return (
    <div className={resultInputElem}>
      {props.resultNameHead && <h2 className={resultNameHeadElem}>{props.resultNameHead}</h2>}
      {askCombination?.Subjects?.map((subject, index) => (
        <div className={subjInpPair} key={index}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {props.onDeleteClick && (
              <span onClick={props.onDeleteClick(subject.code)}>
                 {/*<span onClick={props.onDeleteClick(subject.ShortName)}>*/}
                <i className='pi pi-trash' style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }} />
              </span>
            )}
            <h2>{subject?.Name}</h2>
          </div>
          <GradeInput isAcseeCompetedYear2014_2015={formDoc.marks.data.isAcseeCompetedYear2014_2015} gradeType={props.resultType} subject={subject} onChange={saveMarks(subject.code)} />
          {/*<GradeInput subject={subject} onChange={saveMarks(subject.ShortName)} />*/}
        </div>
      ))}
    </div>
  )
}

export default ResultInput
